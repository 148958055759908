import mixpanel from 'mixpanel-browser';


const handleAnalytics = (event?: any, payload?: any, secret?: string) => {

    let id = Boolean(sessionStorage.getItem("analyticsId") && sessionStorage.getItem("analyticsId") !== "null") ? sessionStorage.getItem("analyticsId") : '';
    mixpanel.init(secret || '', { debug: false, ignore_dnt: true });
    mixpanel.people.set("$name", `anonymous-${id}`);
    if (payload) {
        if (Array.isArray(payload)) {
            for (let item of payload) {
                if (typeof item === 'object' && Boolean(item.programAbbreviation)) {
                    let newPayload: any = {};
                    newPayload[`${item.programAbbreviation}IsEligible`] = item.isEligible;
                    newPayload[`${item.programAbbreviation}Amount`] = item.amount.substring(item.amount.indexOf('$'), item.amount.indexOf('!'));
                    mixpanel.people.set(newPayload);
                } else {
                    mixpanel.people.set(item);
                }
            }
        } else {
            mixpanel.people.set(payload);
        }
    }
    let result = event && mixpanel.track(event.eventName, payload);

    return result;
}

export default handleAnalytics;
