import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Logo from './Logo/Logo';
import LanguageSelector from './LanguageSelector/LanguageSelector';
import { LinkText } from '../Library'; // Assuming you have a LinkText component
import classes from './Header.module.scss'; // Assuming you have your CSS modules

type Props = {
    headerConfig: undefined | {
        languageSettings?: {
            default?: string,
            languages?: Array<object>
        } | null,
        logo?: undefined | object | null,
        linkSet?: any,
        // Add more properties if needed
    } | null,
    changeLanguage?: any,
    language: string
};
const headerStyle = {
    height: '65px',
    borderRadius: '3px',
    margin: 0,
    backgroundColor: 'var(--background-color1)',
    boxShadow: '0px 5px 5px var(--shadow-color)',
    width: '100%'
};

const menuStyle = {
    height: '65px',
    width: '100%',
    borderRadius: '3px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
};

const linkSeparatorStyle = {
    color: 'var(--control-color)',
    padding: '0px 15px',
    fontSize: '20px',
    justifyContent: 'space-between'
};

const linkDisplayStyle = {
    height: '65px',
    fontFamily: 'var(--font-family-bold)',
    display: 'flex',
    alignItems: 'center',
    marginLeft: '20px',
    fontSize: '12pt',
    fontWeight: 'bold',
    color: 'var(--text-color)',
    width: '50%'
};

const Header: React.FC<Props> = ({ headerConfig, changeLanguage, language }) => {
    const [languageSettings, setLanguageSettings] = useState({});
    const [logo, setLogo] = useState({});
    const [loading, setLoading] = useState(true);
    const [linkSet, setLinkSet] = useState([]);

    useEffect(() => {
        if (headerConfig && headerConfig.languageSettings && headerConfig.logo) {
            setLanguageSettings(headerConfig.languageSettings);
            setLogo(headerConfig.logo);
            setLinkSet(headerConfig.linkSet || []); // Ensure linkSet is initialized as an empty array if not provided
            setLoading(false);
        }
    }, [headerConfig]);

    const renderLinkSet = () => {
        const totalLinks = linkSet.length;
        return (
            <div style={linkDisplayStyle} className={classes.linksWrapper}>
                {linkSet.map((link, index) => (
                    <React.Fragment key={index}>
                        {index > 0 && (
                            <span
                                className={`${classes.separator} ${totalLinks > 2 && index === 1 ? classes.hideSeparator : ''}`}
                                style={linkSeparatorStyle}
                            >
                                |
                            </span>
                        )}
                        <span className={classes.linkSpan}>
                            <LinkText link={link} language={language} />
                        </span>
                    </React.Fragment>
                ))}
            </div>
        );
    };

    return (
        <header role="banner" style={headerStyle} id="header">
            {!loading &&
                <div style={menuStyle}>
                    {logo &&
                        <Logo
                            logo={logo}
                            language={language}
                        />
                    }
                    {renderLinkSet()}
                    {languageSettings && (
                        <LanguageSelector
                            language={language}
                            languageSettings={languageSettings}
                            onChange={changeLanguage}
                        />
                    )}
                </div>
            }
        </header>
    );
};

Header.propTypes = {
    headerConfig: PropTypes.shape({
        languageSettings: PropTypes.shape({
            default: PropTypes.string,
            languages: PropTypes.arrayOf(PropTypes.object)
        }),
        logo: PropTypes.object,
        linkSet: PropTypes.array // Adjust the PropTypes according to the structure of your linkSet
        // Add more PropTypes if needed
    }),
    changeLanguage: PropTypes.func,
    language: PropTypes.string.isRequired
};

export default Header;
