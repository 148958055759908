import React from 'react';
import PropTypes from 'prop-types';
import { merge, isNumber } from 'lodash';
import { Icon, Text } from '..';

type Props = {
    name: string,
    columnCount?: number,
    style?: object,
    language: string,
    items: any[]
};

const iconStyle = {
    width: '18pt'
}

const listItemStyle = {
    marginRight: '10px'
}

const passedStyle = {
    display: 'inline-block',
    minWidth: '160px',
    marginTop: '0',
    marginRight: '40px',
    marginBottom: '40px'
}

const ItemList = ({ name, items, columnCount, style, language }: Props) => {
    const columns = isNumber(columnCount) ? (`repeat(${columnCount}, ${100 / columnCount}%)`) : 'repeat(2, 50%)';
    const listStyle = {
        display: 'grid',
        gridTemplateColumns: columns,
        paddingTop: '15px'
    }

    return (
        <React.Fragment>
            <div className="itemList" id={name} style={merge({}, style, listStyle)}>
                {items.map((item) =>
                    <div key={Math.random()} style={{ display: 'flex' }}>{item.icon && <span style={merge({ color: 'var(' + item.icon.color + ')' }, listItemStyle)}>
                        <Icon
                            name={item.icon.name}
                            type={item.icon.type}
                            style={iconStyle}
                        />
                    </span>}<Text text={item.text} language={language} passedStyle={passedStyle} /></div>
                )}
            </div>
        </React.Fragment>
    )
};

ItemList.propTypes = {
    name: PropTypes.string,
    style: PropTypes.object,
    columnCount: PropTypes.number,
    items: PropTypes.array
};

export default ItemList;
