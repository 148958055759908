import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  useLocation,
} from "react-router-dom";
import initalizeIconLibrary from "./utils/helpers/fontAwesomeLibrary";
import GlobalContext from "./utils/wrappers/ContextEngine";
import "./App.css";
import Solution from "./components/SolutionConfig/SolutionConfig";
// import loadFathom from './utils/helpers/handleFathom';

const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

const App: React.FC = () => {
  initalizeIconLibrary();
  //loadFathom();
  const location = window.location.href;
  let redirectTo = location.includes('help.alluma.net') ? '/CaQuickscreener' : '/TnQuickscreener';

  return (
    <div className="App">
      <GlobalContext.Provider>
        <Router>
          <ScrollToTop />
          <Switch>
            <Route path="/:solution">
              <Solution />
            </Route>
            <Route
              exact
              path="/"
              render={() => <Redirect to={redirectTo} />}
            />
          </Switch>
        </Router>
      </GlobalContext.Provider>
    </div>
  );
};

export default App;
