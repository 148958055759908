import { useContext, useEffect, useState } from "react";
import { WizardStateContext } from "../../components/Library";

const handlePayload = (condition: any, payload?: any, stateObject?: any) => {
    let tempPayload = JSON.stringify(payload);
    let replace = new RegExp(condition.sourceValue, 'g');
    tempPayload = tempPayload.replace(replace, stateObject);
    tempPayload = JSON.parse(tempPayload);
    return tempPayload;
};

const handleLogicalOperators = (conditionalArray: any[]) => {
    let tempConditionalRender = true;
    if (conditionalArray[1] === 'AND') {
        tempConditionalRender = Boolean(conditionalArray[0] && conditionalArray[2]);
    } else if (conditionalArray[1] === 'OR') {
        tempConditionalRender = Boolean(conditionalArray[0] || conditionalArray[2]);
    } else {
        //default to AND behavior
        tempConditionalRender = Boolean(conditionalArray[0] && conditionalArray[2]);
    }
    conditionalArray.splice(0, 3, tempConditionalRender);
    if (conditionalArray.length === 1) {
        return conditionalArray[0];
    } else {
        handleLogicalOperators(conditionalArray);
    }
}

const handleConditionals = (conditionals: any, WizardState: any, payload?: any) => {

    let conditionalRender = true;
    let conditionalRenderArray = [];
    let tempPayload: any = null;
    let setState: any[] = [];
    if (conditionals?.length > 0) {
        for (let condition of conditionals) {
            let stateObject: any = WizardState.WizardState.get(condition.sourceName) || localStorage.getItem(condition.sourceName);
            switch (condition.operator) {
                case "eq":
                    conditionalRender = stateObject === condition.sourceValue;
                    conditionalRenderArray.push(conditionalRender);
                    break;
                case "inc":
                    conditionalRender = stateObject?.includes(condition.sourceValue);
                    conditionalRenderArray.push(conditionalRender);
                    break;
                case "nt":
                    conditionalRender = stateObject !== condition.sourceValue;
                    conditionalRenderArray.push(conditionalRender);
                    break;
                case "set":
                    tempPayload = handlePayload(condition, payload, stateObject);
                    break;
                case "setState":
                    if (!WizardState.WizardState.get(condition.sourceName)) {
                        setState.push(condition.sourceName, condition.sourceValue);
                    }
                    break;
                default:
                    break;
            }
            if (condition.logicalOperator) {
                conditionalRenderArray.push(condition.logicalOperator);
            }
        }
    }
    if (conditionalRenderArray.length > 1) {
        conditionalRender = handleLogicalOperators(conditionalRenderArray);
    }
    return { conditionalRender, tempPayload, setState };
};

const useConditionals = (conditionals: any, payload?: any) => {
    const [render, setRender] = useState(true);
    const [newPayload, setNewPayload] = useState<any>(null);
    const wizardState = useContext(WizardStateContext.Context);
    const setWizardValue = wizardState.WizardActions && wizardState.WizardActions.get("setWizardStateValue");

    useEffect(() => {
        let result = handleConditionals(conditionals, wizardState, payload)
        setRender(result.conditionalRender);
        setNewPayload(result.tempPayload);
        if (result.setState.length > 0) {
            setWizardValue(result.setState[0], result.setState[1]);
        }
    }, [conditionals, wizardState, wizardState.WizardState, payload, setWizardValue]);

    return { render, newPayload };
};

export { useConditionals, handleConditionals };
