import { WizardLabel, FieldMessage, Help } from "../";
import { handleText, staticContent } from "../../../utils/helpers";
import classes from "./DropDownList.module.scss";

type Props = {
  name: string;
  label: any;
  options: Array<object>;
  handleOnChange?: any;
  language: string;
  style?: object;
  optionStyle?: object;
  isRequired?: boolean;
  errorMessage?: any;
  value: any;
  helpInfo?: any;
};

const BaseDropdown = ({
  name,
  label,
  options,
  language,
  style,
  optionStyle,
  isRequired = false,
  errorMessage,
  value,
  handleOnChange,
  helpInfo,
}: Props) => {
  return (
    <div className={classes.dropdown}>
      <WizardLabel
        name={name}
        required={isRequired}
        requiredLabel={handleText(staticContent.required, language)}
        label={label}
        language={language}
      />
      <select
        name={name}
        id={name}
        defaultValue={value}
        onChange={handleOnChange}
        style={style}
      >
        {options &&
          options.map((option: any, index: any) => {
            return (
              <option
                key={option.value + index}
                style={optionStyle}
                value={option.value}
              >
                {handleText(option.label, language)}
              </option>
            );
          })}
      </select>
      {helpInfo && <Help helpInfo={helpInfo} language={language} id={name} />}
      {errorMessage && (
        <FieldMessage
          messageType="error"
          messageText={errorMessage}
          language={language}
        />
      )}
    </div>
  );
};

export default BaseDropdown;
