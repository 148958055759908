import { useState, useEffect, useContext } from "react";
import { Route, Switch, Redirect } from "react-router";
import { useRouteMatch } from "react-router-dom";
import { handleText } from "../../../utils/helpers";
import WizardStep from "./WizardStep";
import WizardResults from './WizardResults';
import WizardStateContext from "./WizardStateProvider";
import GlobalContext from "../../../utils/wrappers/ContextEngine";

type Props = {
  wizardConfig: any | undefined;
  language: string;
};

const Wizard = ({ wizardConfig, language }: Props) => {
  const { url } = useRouteMatch();
  const [routes, setRoutes] = useState<any>([]);
  const [firstStep, setFirstStep] = useState<string>("");
  const wizardState = useContext(WizardStateContext.Context);
  const [wizardActions] = useState<any>(wizardState.WizardActions);
  const setWizardValue = wizardActions.get("setWizardStateValue");
  const globalContextState = useContext(GlobalContext.Context);
  const [globalActions] = useState<any>(globalContextState.ContextActions);
  const setGlobalValue = globalActions && globalActions.get("setContextStateValue");

  useEffect(() => {
    const mapWizardSteps = (steps: any, wizardTitle: string) => {
      let newRoutes = [];
      let filteredSteps = steps.filter((step: any) => step.type === 'WizardStep');
      let resultSteps = steps.filter((step: any) => step.type !== 'WizardStep');
      const totalWizardSteps = filteredSteps.length;
      setWizardValue && setWizardValue("totalWizardSteps", totalWizardSteps);
      const stepRoutePaths: any = {};
      for (const [index, container] of filteredSteps.entries()) {
        if (index === 0) setFirstStep(container.name);
        stepRoutePaths[index + 1] = `${url}/${index + 1}`;
        let route = (
          <Route path={`/:solution/:wizard/${container.name}`} key={container.name}>
            <WizardStep
              wizardStepConfig={container}
              language={language}
              totalWizardSteps={totalWizardSteps}
              stepNumber={index + 1}
              wizardTitle={wizardTitle}
            />
          </Route>
        );
        newRoutes.push(route);
      }
      for (let resultStep of resultSteps) {
        let resultRoute = (
          <Route path={`/:solution/:wizard/${resultStep.name}`} key={resultStep.name}>
            <WizardResults wizardResultsConfig={resultStep} language={language} />
          </Route>
        );
        newRoutes.push(resultRoute);
      }
      setRoutes(newRoutes);
      setWizardValue && setWizardValue("stepRoutePaths", stepRoutePaths);
    };
    const wizardTitle = handleText(wizardConfig.title, language);
    setWizardValue && setWizardValue("wizardConfig", wizardConfig);
    mapWizardSteps(wizardConfig.containers, wizardTitle);
  }, [wizardConfig, language, setWizardValue, url]);

  useEffect(() => {
    let metaFooter = wizardConfig.metaFooter || { branding: true, base: true };
    setGlobalValue && setGlobalValue("metaFooter", metaFooter);
  }, [wizardConfig, setGlobalValue]);

  const handleSubmit = (event: any) => {
    event.preventDefault();
  }

  return (
    <main id={wizardConfig.name} role="main">
      <form id={'wizardForm' + wizardConfig.name} onSubmit={handleSubmit}>
        <Switch>
          {routes}
          {firstStep ? (
            <Route
              path="/:solution/:wizard/"
              render={() => <Redirect to={`${url}/${firstStep}`} />}
            />
          ) : null}
        </Switch>
      </form>
    </main>
  );
};

export default Wizard;
