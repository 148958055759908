import React, { useState, useEffect } from "react";
import { LinkText, SocialLink } from "../Library";
import { handleText } from "../../utils/helpers";
import Logo from "./Logo/Logo";
import classes from "./Footer.module.scss";

type Props = {
  baseConfig:
  | undefined
  | {
    name?: string;
    logo?: object | null;
    powerBy?: object | null;
    social?: object | null;
    linkSet?: any;
  }
  | null;
  language: string;
};

const linkSeparatorStyle = {
  color: "var(--control-color)",
  padding: "0px 15px",
  fontSize: "20px",
};

interface LogoSet {
  logoSet?: Array<object>;
}

const BaseFooter: React.FC<Props> = ({ baseConfig, language }) => {
  const [logo, setLogo] = useState({});
  const [linkSet, setLinkSet] = useState([]);
  const [poweredBy, setPoweredBy] = useState<any>({});
  const [social, setSocial] = useState<LogoSet>({});
  const [footerId] = useState(baseConfig && baseConfig.name ? baseConfig.name : "FooterBase");

  useEffect(() => {
    if (
      baseConfig &&
      baseConfig.linkSet &&
      baseConfig.logo &&
      baseConfig.social &&
      baseConfig.powerBy
    ) {
      setLogo(baseConfig.logo);
      setLinkSet(baseConfig.linkSet);
      setSocial(baseConfig.social);
      setPoweredBy(baseConfig.powerBy);
    }
  }, [baseConfig]);

  const renderSocialLinks = () => {
    return (
      <div
        className={classes.socialLinksFooter + " footerBottom"}
        id="socialLinksFooter"
      >
        {social &&
          social.logoSet &&
          social.logoSet.map((link, index) => {
            return (
              <span key={index}>
                <SocialLink link={link} language={language}></SocialLink>
              </span>
            );
          })}
      </div>
    );
  };

  const renderLinkSet = () => {
    const totalLinks = (linkSet && linkSet.length) || 0;
    return (
      <div
        id="baseFooterLinks"
        className={`${classes.linksWrapper} ${
          totalLinks > 2 ? classes.threeLinks : null
          }`}
      >
        {linkSet &&
          linkSet.map((link, index) => {
            const linkId = `footerBaseLink_${index}`;
            return (
              <React.Fragment key={index}>
                {index ? (
                  <span id={`separator_${index}`}
                    className={`${
                      totalLinks > 2 && index === 1
                        ? classes.hideSeparator
                        : null
                      }`}
                    style={linkSeparatorStyle}
                  >
                    |
                  </span>
                ) : (
                    ""
                  )}
                <span className={classes.linkSpan}>
                  <LinkText
                    link={link}
                    linkId={linkId}
                    language={language}
                  ></LinkText>
                </span>
              </React.Fragment>
            );
          })}
      </div>
    );
  };

  return (
    <div id={footerId} className={classes.top + " footerTop"}>
      <div id="baseFooterLogo" className={classes.footerlogo}>
        {logo && <Logo id={"baseLogo"} logo={logo} language={language} />}
      </div>
      <div id="poweredBy" className={`${classes.marginTop7} ${classes.poweredBy}`}>
        {handleText(poweredBy, language)}
      </div>
      {renderSocialLinks()}
      {renderLinkSet()}
    </div>
  );
};

export default BaseFooter;
