import React from 'react';
import PropTypes from 'prop-types';
import { merge } from 'lodash';
import { Icon } from '../';
import classes from "./Button.module.scss";

const pointerCursor = {
    cursor: 'pointer'
};

const secondaryStyle = {
    color: 'var(--primary-color)',
    backgroundColor: 'var(--background-color1)'
};

const primaryStyle = {
    color: 'var(--background-color1)',
    backgroundColor: 'var(--primary-color)',
    marginRight: '0px'
};

const iconMarginLeft = {
    marginLeft: '5px',
};

const iconMarginRight = {
    marginRight: '5px',
};

const PreStyles: any = {
    BackBtn: {
        fontSize: "10pt"
    },
    CancelBtn: {
        fontSize: "10pt",
    },
    ContinueBtn: {
        fontSize: "10pt"
    }
}

type IconProps = {
    name: string,
    type?: string,
    color?: string,
    size?: string,
    position?: string
};

type Props = {
    primary?: boolean,
    label: string,
    name?: string,
    icon?: IconProps,
    onClick?: any,
    style?: any,
    disabled?: boolean,
    loading?: boolean,
    dark?: boolean,
    tabIndex?: number,
    width?: number | string,
    widthUnit?: string
};

const Button = ({ primary, onClick, style, disabled, loading, icon, label, name, tabIndex, dark = false, ...props }: Props) => {
    return (
        <React.Fragment>
            <button
                type="button"
                {...props}
                id={name + 'Btn'}
                disabled={loading || disabled}
                aria-busy={loading}
                aria-disabled={disabled}
                onClick={onClick}
                tabIndex={tabIndex}
                className={classes.baseStyle + ' ' + (primary ? 'primary' : classes.secondary)}
                style={merge({}, primary ? primaryStyle : secondaryStyle, style, props.width && props.widthUnit && { width: props.width + props.widthUnit })}>

                {loading ? <Icon name={(dark) ? 'spinner-dark' : 'spinner'} /> : (
                    icon ? (icon.position === "right" ? <><label style={pointerCursor}>{label}</label> <Icon name={icon.name} size={icon.size ? icon.size : 'sm'} type={icon.type} style={merge({}, iconMarginLeft, PreStyles[name + "Btn"], icon)} /></>
                        : <><Icon name={icon.name} size={icon.size ? icon.size : 'sm'} type={icon.type} style={merge({}, iconMarginRight, PreStyles[name + "Btn"], icon)} /> <label style={pointerCursor}>{label}</label></>
                    ) : <label style={pointerCursor}>{label}</label>)
                }
            </button>
        </React.Fragment>
    )
};

Button.propTypes = {
    primary: PropTypes.bool,
    onClick: PropTypes.func,
    style: PropTypes.object,
    disabled: PropTypes.bool,
    loading: PropTypes.bool,
    icon: PropTypes.any,
    label: PropTypes.any,
    dark: PropTypes.bool
};

export default Button;
