import { useContext, useEffect, useState } from 'react';
import { RenderElement } from "../";
import { api, handleAnalytics } from '../../../utils/helpers';
import queryString from 'query-string';
import { WizardStateContext } from '../Wizard';
import classes from "./AddressTemplate.module.scss";

type Props = {
    reference?: string;
    container?: any;
    name?: string;
    language?: string;
    payload?: any;
};

let wizardState: any;
let setWizardValue: any;
const userType: string = window.location.pathname.indexOf('/current/') > -1 ? 'current' : 'primary';

const replacePlaceholders = (optionDescription: any, outputElement: any) => {
    if (optionDescription) {
        optionDescription = optionDescription.replace('<address1>', outputElement.address1);
        optionDescription = optionDescription.replace('<address2>', outputElement.address2 ? outputElement.address2 : '');
        optionDescription = optionDescription.replace('<city>', outputElement.city);
        optionDescription = optionDescription.replace('<state>', outputElement.state);
        optionDescription = optionDescription.replace('<zip>', outputElement.zipCode);
    }
    return optionDescription;
}

const processElementForOptionDisplay = (element: any, output: any) => {
    const outputElement = output.find((e: any) => e.id === element.name);
    const isHomeAddress = getWizardState(`applicants['${userType}'].addressess['home'].residence`);
    const isMailAsHomeAddress = getWizardState(`applicants['${userType}'].addressess['mailing'].isHome`);
    if (element.type === 'OptionList' && element.typeSuggestion === 'Radio') {
        if (!outputElement
            || ((!outputElement || outputElement.id !== element.name) && (!isHomeAddress || isHomeAddress !== 'USResidentialAddress'))
            || ((!outputElement || outputElement.id !== element.name) && (!isMailAsHomeAddress || isMailAsHomeAddress === 'Yes'))) {
            element = null;
        } else {
            element.options = element.options.map((e: any) => {
                let outputElementAddress = outputElement;
                if (e.value === 'No' && outputElement.results.length > 0) {
                    outputElementAddress = outputElement.results[0];
                    Object.assign(outputElementAddress, { address1: outputElementAddress.delivery_line_1 });
                    Object.assign(outputElementAddress, { address2: outputElementAddress.delivery_line_2 });
                    e.warnings = outputElementAddress.warnings;
                    e.optionLine = true;
                } else if (e.value === 'Yes' && outputElement.results.length > 0) {
                    e.optionInfo = {};
                }
                e.optionDescription = replacePlaceholders(e.optionDescription, outputElementAddress);
                return e;
            });
            if (outputElement.results.length === 0) {
                element.options = element.options.filter((e: any) => e.value !== 'No');
            }
            const addressCategory = outputElement && outputElement.id === 'HomeAddressSelectOptionList' ? 'home' :
                (outputElement && outputElement.id === 'MailAddressSelectOptionList' ? 'mailing' : null);
            setWizardValue(`applicants['${userType}'].addressess['${addressCategory}'].original`, 'Yes');
        }
    } else if (
        ((!isHomeAddress || isHomeAddress !== 'USResidentialAddress') && ['HomeAddressHeaderText', 'HomeHeaderLine'].includes(element.name))
        || ((!isMailAsHomeAddress || isMailAsHomeAddress === 'Yes') && ['MailingAddressHeaderText', 'MailingHeaderLine'].includes(element.name))) {
        element = null;
    }
    return element;
};

const renderInnerElements = (container: any, output: any, language: any) => {
    return (
        <div id={container.name + '_AddressTemplate'} className={classes.addressTemplate}>
            {container && container.elements.map((element: any) => {
                element = processElementForOptionDisplay(element, output);
                if (element) {
                    return (
                        <RenderElement
                            key={element.name}
                            element={element}
                            language={language}
                            container={container}
                        />
                    );
                } else {
                    return <></>
                }
            })}
        </div>
    );
};

const getPayloadObject = (id: string, addressType: string) => {
    return {
        "id": id,
        "address1": getWizardState(`applicants['${userType}'].addressess['${addressType}'].street1`),
        "address2": getWizardState(`applicants['${userType}'].addressess['${addressType}'].street2`),
        "county": getWizardState(`applicants['${userType}'].addressess['${addressType}'].county`),
        "city": getWizardState(`applicants['${userType}'].addressess['${addressType}'].city`),
        "state": getWizardState(`applicants['${userType}'].addressess['${addressType}'].state`),
        "zipCode": getWizardState(`applicants['${userType}'].addressess['${addressType}'].zip`),
        "maxCandidates": 2
    };
};

const preparePayload = () => {
    const payloads = [];
    const residentialAddress = getWizardState(`applicants['${userType}'].addressess['home'].residence`);
    if (residentialAddress === 'USResidentialAddress') {
        payloads.push(getPayloadObject('HomeAddressSelectOptionList', 'home'));
    }

    const isMailingAsHomeAddress = getWizardState(`applicants['${userType}'].addressess['mailing'].isHome`) || true;
    if (isMailingAsHomeAddress === 'No') {
        payloads.push(getPayloadObject('MailAddressSelectOptionList', 'mailing'));
    }

    return payloads;
};

const getWizardState = (key: string) => {
    return wizardState.WizardState.get(key);
};

const AddressTemplate = ({ reference, container, payload, language }: Props) => {
    wizardState = useContext(WizardStateContext.Context);
    setWizardValue = wizardState.WizardActions && wizardState.WizardActions.get("setWizardStateValue");
    const parsed = queryString.parse(window.location.search);
    const [payloads] = useState(preparePayload());
    const [refContainer] = useState(container.containers.find((c: any) => c.name === reference) || null);
    const [template, setTemplate] = useState<any>(null);
    const [processedApis, setProcessedApis] = useState<any>([]);
    const [apiOutput, setApiOutput] = useState<any>(null);
    const [loading, setLoading] = useState(false);
    const [outputRetrived, setOutputRetrived] = useState(false);
    const analytics = handleAnalytics;

    useEffect(() => {
        if (apiOutput) {
            return
        }
        if (Boolean(payload)) {
            // Empty
        } else {
            if (container.apis?.length > 0 && !apiOutput) {
                let apis = container.apis;
                let tempApis = [];
                for (let tempApi of apis) {
                    let tempProcessedApi = {
                        url: tempApi.url,
                        base: {
                            headers: () => ({
                                "Content-Type": "application/json",
                                "Ocp-Apim-Subscription-Key": tempApi.key,
                            })
                        },
                        method: tempApi.method
                    };
                    tempApis.push(tempProcessedApi);
                }
                setProcessedApis(tempApis);
            }
        }
    }, [container, apiOutput, language, payload]);

    useEffect(() => {
        const handleApis = async () => {
            for (let processedApi of processedApis) {
                if (!apiOutput) {
                    let variables = payloads;
                    let request = { url: processedApi.url, variables: variables, base: processedApi.base };
                    setLoading(true);
                    let postApi = await api.post(request);
                    let output = [];
                    output.push(postApi);
                    setApiOutput(output);
                }
            }
        }

        if (!apiOutput) {
            handleApis();
        } else {
            if (outputRetrived) {
                return;
            }
            let tempPrograms: any = [];
            setWizardValue(`addressAPIOutput`, JSON.stringify(apiOutput));
            for (let output of apiOutput) {
                if (output && output !== '') {
                    tempPrograms.push(renderInnerElements(refContainer, output, language));
                } else {
                    tempPrograms.push('No address selected for suggestions.')
                }
            }
            setTemplate(tempPrograms);
            setOutputRetrived(true);
        }
    }, [parsed, apiOutput, analytics, language, refContainer, outputRetrived, payloads, processedApis]);

    return (
        <>
            {
                refContainer && loading && template
            }
        </>
    );
};

export default AddressTemplate;