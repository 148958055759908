import classes from "./Text.module.scss";
import { handleText } from "../../../utils/helpers";
import { merge } from 'lodash';
import { useConditionals } from "../../../utils/helpers";

type Props = {
    name?: string,
    text: CardTitleInterface,
    language: string,
    passedStyle?: object,
    conditionals?: any,
    props?: {
        color?: string
    }
};

interface CardTitleInterface {
    type: string,
    content: [{
        language: string,
        text: string
    }]
};

/**
 * Apply properties to the Text element
 * @param props properties for Text element
 */
const style = (props: any) => {
    return {
        color: `var(${props.color})` || "var(--p-font-color)"
    }
}

const Text = ({ name, text, language, passedStyle, conditionals, ...props }: Props) => {
    const handleConditionals = useConditionals(conditionals);

    return (
        <>
            {
                handleConditionals.render &&
                <p id={name} className={classes.pText}
                    style={merge({}, style(props), passedStyle)}>
                    {handleText(text, language)}
                </p>
            }
        </>
    )
}

export default Text;
