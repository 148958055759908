import { useState, useContext } from "react";
import { useParams, useHistory } from "react-router-dom";
import classes from "./Navigation.module.scss";
import { handleText, useFormValidation, handleAnalytics, api } from "../../../utils/helpers";
import GlobalContext from '../../../utils/wrappers/ContextEngine';
import { Button, Modal, WizardStateContext, useStepConfigUpdater, useStepConfigState } from '../';

/**
 * Property type for the component
 */
type Props = {
  id?: string,
  elements: any;
  language: string;
  type?: string;
  skipValidation?: boolean;
};

/**
 * Property type for action
 */
type action = {
  type?: string,
  name?: string,
  link?: {
    type?: string,
    url?: string,
    openIn?: string
  },
  apiDetails?: {
    method: string,
    url: string,
    headers?: {
      key: string,
      value: string
    }[],
    onSuccess?: any,
    onError?: any,
  }
}

/**
 * Property Type for Icon
 */
type IconProps = {
  name: string,
  type?: string,
  color?: string,
  size?: string
};

/**
 * Pre-defined custom functions to call on Context type actions
 */

/**
 * Goto the previous visited page (from browser history)
 */

/**
 * Prepare properties for Icon
 * @param icon properties for icon
 */
const getIcon = (icon: IconProps) => {
  const iconObj = icon ? Object.assign({}, icon, { size: 'xs' }) : undefined;
  return iconObj;
};

const Navigation = ({ id, elements, language, type, skipValidation }: Props) => {
  const globalContextState = useContext<any>(GlobalContext.Context);
  const wizardContextState = useContext<any>(WizardStateContext.Context);
  const [useModal, setUseModal] = useState<any>(null);
  const [modalOpen, setModalOpen] = useState(true);
  const { solution }: any = useParams();
  const history = useHistory();
  const validForm = useFormValidation();
  const stepConfigState = useStepConfigState();
  const setStepConfig = useStepConfigUpdater();
  const analytics = handleAnalytics;
  const clearAnalyticsId = globalContextState?.ContextActions.get("ClearId");
  const setLoadingState = globalContextState?.ContextActions.get("SetLoadingState");
  const setWizardValue =
    wizardContextState.WizardActions &&
    wizardContextState.WizardActions.get("setWizardStateValue");
  const analyticsSecret = globalContextState?.Configuration?.analyticsTools?.find((at: any) => at.name === "Mixpanel")?.secret;

  const setAddresses = (addressAPIOutput: any) => {
    const userType: string = window.location.pathname.indexOf('/current/') > -1 ? 'current' : 'primary';
    addressAPIOutput = JSON.parse(addressAPIOutput);
    addressAPIOutput = addressAPIOutput.length > 0 ? addressAPIOutput[0] : addressAPIOutput;
    addressAPIOutput && addressAPIOutput.forEach((address: any) => {
      const addressType = address.id === 'HomeAddressSelectOptionList' ? 'home' : (address.id === 'MailAddressSelectOptionList' ? 'mailing' : null);
      const isOriginalSelected = wizardContextState.WizardState.get(`applicants['${userType}'].addressess['${addressType}'].original`);
      if (isOriginalSelected === 'No' && address.results && address.results.length > 0) {
        const addressResult = address.results[0];
        if (setWizardValue) {
          const street = addressResult.delivery_line_1;
          let street1 = street;
          let street2 = '';
          if (street.lastIndexOf(',') > -1) {
            street1 = street.substr(0, street.lastIndexOf(','));
            street2 = street.substr(street.lastIndexOf(',') + 2);
          }
          setWizardValue(`applicants['${userType}'].addressess['${addressType}'].street1`, street1);
          setWizardValue(`applicants['${userType}'].addressess['${addressType}'].street2`, street2);
          setWizardValue(`applicants['${userType}'].addressess['${addressType}'].city`, addressResult.city);
          setWizardValue(`applicants['${userType}'].addressess['${addressType}'].state`, addressResult.state);
          setWizardValue(`applicants['${userType}'].addressess['${addressType}'].zip`, addressResult.zipCode);
          setWizardValue(`addressAPIOutput`, null);
        }
      }
    });
  };

  /**
 * Initiate and call to the OnClick event for a button
 * @param action required for navigation button
 */
  const handleAction = async (action: action, givenAnalytics?: any) => {
    /** Call pre-defined custom functions */
    analytics(givenAnalytics && givenAnalytics, null, analyticsSecret);
    switch (action.type) {
      case "AddressSelection":
        const addressAPIOutput = wizardContextState.WizardState.get("addressAPIOutput");
        if (addressAPIOutput) {
          setAddresses(addressAPIOutput);
        }
        break;
      case "Context":
        const globalAction = globalContextState.ContextActions && globalContextState.ContextActions.get(action.name);
        globalAction();
        break;
      case "Reference":
        const currentStep = wizardContextState.WizardState.get("currentStep");
        const wizardStepConfig = wizardContextState.WizardState.get("wizardConfig").containers[currentStep - 1];
        const refContainer = wizardStepConfig.containers[0].containers.find((e: any) => e.type === 'NavSection');
        const newModal = <Modal reference={action.name} container={refContainer} name={action.name} language={language} open={true} setOpen={setModalOpen} />;
        setUseModal(newModal);
        setModalOpen(true);
        break;
      case "Wizard":
        const checkActions = ['Continue', 'Submit', 'SendResults', 'EnEStepResponse'];
        let checkName: any = action.name;
        if (checkActions.includes(checkName)) {
          if (!validForm()) {
            return false;
          }
        }
        if (action.name === 'Cancel') {
          const currentStep = wizardContextState.WizardState.get("currentStep");
          const currentStepConfigElements = stepConfigState[currentStep];
          for (const elementName in currentStepConfigElements) {
            let element = currentStepConfigElements[elementName];
            element.error = null;
          }
          setStepConfig(currentStep, currentStepConfigElements);
        }
        const wizardAction = wizardContextState.WizardActions && wizardContextState.WizardActions.get(action.name);
        if (action.apiDetails) {
          let headers: any = {};
          if (action.apiDetails?.headers) {
            for (let eachHeader of action.apiDetails.headers) {
              headers[eachHeader.key] = eachHeader.value;
            }
          }
          let definedApi = {
            name: action.name,
            method: action.apiDetails?.method,
            url: action.apiDetails?.url,
            base: {
              headers: () => (
                {
                  "Content-Type": "application/json",
                  ...headers
                })
            },
          };
          await wizardAction(definedApi);
        } else {
          await wizardAction();
        }
        if (action.apiDetails?.onSuccess) {
          for (let newAction of action.apiDetails?.onSuccess.actions) {
            handleAction(newAction);
          }
        }
        break;
      case "Link":
        if (action.link?.type === "Absolute") {
          if (!validForm() && !Boolean(skipValidation)) {
            return false;
          }
          window.open(action.link.url, action.link.openIn || "_self");
        } else if (action.link?.type === "Relative") {
          /** Open in same tab */
          const checkActions = ['SearchContactLinkNav'];
          const startActions = ['StartWizard', 'StartAppSearch'];
          let checkName: any = action.name;
          if (startActions.includes(checkName)) {
            localStorage.clear();
            clearAnalyticsId();
          }
          if (checkActions.includes(checkName)) {
            if (!validForm()) {
              return false;
            }
          }
          history.push(`/${solution}/${action.link.url}`);
        }
        break;
      case "Api":
        let headers: any = {};
        if (action.apiDetails?.headers) {
          for (let eachHeader of action.apiDetails.headers) {
            headers[eachHeader.key] = eachHeader.value;
          }
        }
        let definedApi = {
          name: action.name,
          method: action.apiDetails?.method,
          url: action.apiDetails?.url,
          base: {
            headers: () => (
              {
                "Content-Type": "application/json",
                ...headers
              })
          },
        };
        switch (action.name) {
          case "StepResponse":
            let cleanData = wizardContextState.WizardActions.get("CreateCleanData");
            let variables = cleanData();
            variables.solutionId = solution;
            let request = { url: definedApi.url || '', variables: variables, base: definedApi.base };
            setLoadingState(true);
            try {
              await api.post(request);
              setLoadingState(false);
            } catch {
              if (action.apiDetails?.onError) {
                for (let newAction of action.apiDetails?.onError.actions) {
                  handleAction(newAction);
                }
              }
            }
            if (action.apiDetails?.onSuccess) {
              for (let newAction of action.apiDetails?.onSuccess.actions) {
                handleAction(newAction);
              }
            }
            break;
          default:
            break;
        }
        break;
      default:
        break;
    }
  };

  const OnClick = (action?: any, actions?: any, analytics?: any) => {
    if (action) {
      handleAction(action, analytics);
    }
    if (actions) {
      for (let eachAction of actions) {
        handleAction(eachAction, analytics);
      }
    }
  }

  return <div role="navigation" aria-label={id} className={classes.navigation + " navigation " + (type ? classes[`${type}Navigation`] : "")}><div className={classes.innerNavigation}>
    {elements.map((button: any) => {
      return (
        <span key={button.name} className={classes[`position-${button.position}`]}>
          {button.type === "Button" &&
            button.action?.link?.type === "Absolute" ?
            <a href={button.action.link.url} target="_blank" rel="noreferrer">
              <Button
                primary={(button.subType === 'Primary')}
                icon={getIcon(button.icon)}
                label={handleText(button.text, language)}
                name={button.name.replace(/\s+/g, '')}
                {...button} />
            </a>
            :
            <Button
              primary={(button.subType === 'Primary')}
              onClick={() => OnClick(button.action && button.action, button.actions && button.actions, button.analytics && button.analytics)}
              icon={getIcon(button.icon)}
              label={handleText(button.text, language)}
              name={button.name.replace(/\s+/g, '')}
              {...button} />
          }
        </span>
      );
    })}
    {
      modalOpen && useModal
    }
  </div></div>
};

export default Navigation;
