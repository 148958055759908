import React from 'react';
import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import { merge } from 'lodash';
import { Icon } from '../';
import { handleText } from '../../../utils/helpers';
import './Image.css';

type Props = {
    src?: any,
    text?: any,
    name?: string,
    style?: any,
    loading?: boolean,
    language?: any,
    props: any
};

const centerStyle = {
    textAlign: 'center'
}

const Image = ({ src, text, style, language, loading, name, props }: Props) => {
    const [height, setHeight] = useState<any>(null);
    const [width, setWidth] = useState<any>(null);
    const [alt, setAlt] = useState<any>(null);

    useEffect(() => {
        if (props.height) {
            setHeight(props.height + props.heightUnit);
        }
        if (props.width) {
            setWidth(props.width + props.widthUnit);
        }
    }, [props]);

    useEffect(() => {
        if (text) {
            setAlt(handleText(text, language));
        }
    }, [text, language]);

    return (
        <React.Fragment>
            {loading ? <Icon name={'spinner'} /> : (
                <figure style={merge({}, props.position === 'center' && centerStyle, style)}><img src={src} height={height && height} width={width && width} id={name + 'Img'} alt={alt}></img></figure>
            )
            }
        </React.Fragment>
    )
};

Image.propTypes = {
    src: PropTypes.any,
    text: PropTypes.any,
    name: PropTypes.string,
    style: PropTypes.any,
    loading: PropTypes.bool
};

export default Image;
