import classes from "./Help.module.scss";
import { Popover, ArrowContainer } from "../Popover/Popover";
import { handleText, staticContent } from "../../../utils/helpers";
import { useState } from "react";
import { FormattedText, Icon } from "..";

type Props = {
  id: string;
  helpInfo: any;
  language: string;
};

const Help = ({ helpInfo, language, id }: Props) => {
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);

  const handleClick = () => {
    setIsPopoverOpen(!isPopoverOpen);
  };

  const helpIcon = () => {
    return (
      <span
        id={`HelpIcon_${id}`}
        className={classes.helpLink}
        title="Help"
        role="button"
        onKeyPress={handleClick}
        onClick={handleClick}
        tabIndex={0}
      >
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
          <path
            className={classes.helpLinkBg}
            d="M256 0C114.6 0 0 114.6 0 256s114.6 256 256 256 256-114.6 256-256S397.4 0 256 0zm-1.6 424c-21.1 0-38.2-16.9-38.2-37.8s17.1-37.8 38.2-37.8 38.2 16.9 38.2 37.8c-.1 20.9-17.1 37.8-38.2 37.8zm99.1-207.9c-2.2 7.4-5.2 13.8-8.8 19.2-3.7 5.4-7.9 10.2-12.6 14.4-4.7 4.2-9.4 8.4-14.1 12.4-5.6 4.7-10.7 9.2-15.5 13.5-4.7 4.4-8.8 9.2-12.4 14.6-3.5 5.4-6.3 11.7-8.2 19-1.9 7.3-2.9 16.2-2.9 26.6h-47.7c0-12.8.7-23.7 2-32.5 1.3-8.9 3.5-16.7 6.4-23.4 2.9-6.7 6.5-12.6 10.8-17.7 4.3-5.1 9.3-10.1 15.2-15.1 4.7-4.1 9.2-7.9 13.5-11.4 4.3-3.5 8.1-7.4 11.5-11.6 3.4-4.2 6-9 7.9-14.2 1.9-5.2 2.9-11.5 2.9-18.8 0-8.7-1.5-16.2-4.6-22.5-3.1-6.3-6.8-11.3-11.3-15.1-4.4-3.8-9.1-6.6-14.1-8.3-5-1.7-9.4-2.6-13.2-2.6-18.3 0-31.7 5.9-40.4 17.7-8.7 11.8-13 27.6-13 47.4h-50.3c0-16 2.4-30.6 7.3-43.7 4.9-13.1 11.9-24.3 21.2-33.6 9.3-9.3 20.5-16.5 33.6-21.6 13.1-5.1 27.7-7.6 43.9-7.6 13.5 0 26.1 2 37.7 5.9 11.6 3.9 21.8 9.6 30.5 17s15.5 16.7 20.5 27.7c5 11.1 7.5 23.6 7.5 37.6 0 10.5-1.1 19.3-3.3 26.7z"
            fillRule="evenodd"
            clipRule="evenodd"
          />
        </svg>
      </span>
    )
  }

  const timesStyle = { marginBottom: '-2px' };

  const PopoverContent = () => {
    return (
      <div className={classes.helpPopover}>
        <div id="helpTitle" className={classes.helpTitle}>
          {handleText(helpInfo.title, language)}
        </div>
        <div id="helpText" className={classes.helpText}>
          <FormattedText
            text={helpInfo.text}
            language={language}
          ></FormattedText>
        </div>
        <hr id="helpSeparator" className={classes.helpSeparator} />
        <div
          id="helpActions"
          className={classes.helpCancel}
          onClick={() => setIsPopoverOpen(!isPopoverOpen)}
        >
          {handleText(staticContent.close, language)}{" "}
          <Icon name={"times"} type="light" size="1x" style={timesStyle} />
        </div>
      </div>
    )
  }
  return (
    <>
      <Popover
        align={"start"}
        isOpen={isPopoverOpen}
        onClickOutside={() => setIsPopoverOpen(false)}
        padding={30}
        positions={["right", "bottom"]}
        content={({ position, childRect, popoverRect }) => (
          <ArrowContainer
            position={position}
            childRect={childRect}
            popoverRect={popoverRect}
            arrowColor={"var(--primary-color)"}
            arrowSize={10}
            arrowStyle={{ opacity: 1 }}
            className="popover-arrow-container"
            arrowClassName="popover-arrow"
          >
            <PopoverContent />
          </ArrowContainer>
        )}
      >
        {helpIcon()}
      </Popover>
    </>
  );
};

export default Help;
