import React from "react";

const ConfigStateContext = React.createContext<any>({});
const ConfigUpdaterContext = React.createContext<any>(null);

const StepsConfigProvider = ({ children }: any) => {
  const [stepConfig, setStepConfig] = React.useState({});
  return (
    <ConfigStateContext.Provider value={stepConfig}>
      <ConfigUpdaterContext.Provider value={setStepConfig}>
        {children}
      </ConfigUpdaterContext.Provider>
    </ConfigStateContext.Provider>
  );
};

function useStepConfigState() {
  const stepConfigState = React.useContext(ConfigStateContext);
  if (typeof stepConfigState === "undefined") {
    throw new Error(
      "stepConfigState must be used within a StepsConfigProvider"
    );
  }
  return stepConfigState;
}

function useStepConfigUpdater() {
  const setStepConfig = React.useContext(ConfigUpdaterContext);
  if (typeof setStepConfig === "undefined") {
    throw new Error(
      "useStepConfigUpdater must be used within a StepsConfigProvider"
    );
  }
  const updateStepConfig = React.useCallback(
    (stepNumber, value) => {
      setStepConfig((config: any) => {
        const newConfig = Object.assign({}, config);
        newConfig[stepNumber] = value;
        return newConfig;
      });
    },
    [setStepConfig]
  );
  return updateStepConfig;
}

function useStepElementConfigUpdater() {
  const setStepConfig = React.useContext(ConfigUpdaterContext);
  if (typeof setStepConfig === "undefined") {
    throw new Error(
      "useStepElementConfigUpdater must be used within a StepsConfigProvider"
    );
  }

  const updateStepElementConfig = React.useCallback(
    (stepNumber: string | number, elementName: string | number, value: any) => {
      setStepConfig((config: any) => {
        const newConfig = Object.assign({}, config);
        if (stepNumber in newConfig && elementName in newConfig[stepNumber]) {
          newConfig[stepNumber][elementName] = value;
        }
        return newConfig;
      });
    },
    [setStepConfig]
  );
  return updateStepElementConfig;
}

export {
  StepsConfigProvider,
  useStepConfigState,
  useStepConfigUpdater,
  useStepElementConfigUpdater,
};
