import { useState, useEffect } from "react";
import classes from "./Initials.module.scss";

type Props = {
    name: string
};

const getInitials = (name: any) => {
    // Get first and last word initials except get first two in case there is only one word.
    // Examples: firstName => FI, "firstName LastName" => "FL" and "firstName middleName LastName" => "FL"
    const initialChars = name ? name.match(/(^\S\S?|\b\S)?/g).join("").match(/(^\S|\S$)?/g).join("").toUpperCase() : '';
    return initialChars;
};

const Initials = ({ name }: Props) => {
    const [initials, setInitials] = useState([]);

    useEffect(() => {
        setInitials(getInitials(name));
    }, [name]);

    return (
        <span id="initials" className={classes.initials}>
            <span className={classes.letters}>{initials}</span>
        </span>
    );
};

export default Initials;
