import { useState } from 'react';
import { RenderElement } from "../";
import { useWindowWidth } from "../../../utils/helpers";

type Props = {
    reference?: string;
    container?: any;
    name?: string;
    language?: string;
};

const getColumns = (columnCount: any, isMobile: any) => {
    let style = {
        display: 'grid',
        gridTemplateColumns: isMobile ? '100%' : `repeat(${columnCount}, ${100 / columnCount}%)`,
        maxWidth: '745px'
    }
    return style;
};

const renderInnerContainers = (containers: any, language: any) => {
    let innerContainers = containers.map((container: any) => (
        <div>
            {container.elements.map((element: any) => {
                return (
                    <RenderElement
                        key={element.name}
                        element={element}
                        language={language}
                    />
                );
            })}
        </div>
    ))
    return innerContainers;
};

const ColumnLayout = ({ reference, container, name, language }: Props) => {
    const [refContainer] = useState(container.containers.find((c: any) => c.name === reference) || null);
    const isMobile = useWindowWidth() <= 575;
    return (
        <>
            {
                refContainer && (
                    <div id={name} style={getColumns(refContainer.columnCount, isMobile)} className={'columnLayout'}>
                        {renderInnerContainers(refContainer.containers, language)}
                    </div>
                )}
        </>
    );
};

export default ColumnLayout;