import React, { useState, useEffect } from "react";
import { LinkText } from "../Library";
import Logo from "./Logo/Logo";
import classes from "./Footer.module.scss";
import { Line } from "../Library";

type Props = {
  brandingConfig:
  | undefined
  | {
    name?: string,
    logo?: object | null;
    linkSet?: any;
  }
  | null;
  language: string;
};

const lineStyles = {
  lineContainer: {
    maxWidth: "955px"
  },
  line: {
    marginTop: "40px",
    marginBottom: "17px"
  }
}

const logoStyles = {
  height: "80px",
  width: "80%"
}

const BrandingFooter: React.FC<Props> = ({ brandingConfig, language }) => {
  const [logo, setLogo] = useState({});
  const [linkSet, setLinkSet] = useState([]);
  const [footerId] = useState(brandingConfig && brandingConfig.name ? brandingConfig.name : "FooterBranding");

  useEffect(() => {
    if (
      brandingConfig &&
      brandingConfig.linkSet &&
      brandingConfig.logo
    ) {
      setLogo(brandingConfig.logo);
      setLinkSet(brandingConfig.linkSet);
    }
  }, [brandingConfig]);

  const renderLinkSet = () => {
    return (
      <div
        id="brandingFooterLinks"
        className={`${classes.brandingLinksWrapper}`}
      >
        {linkSet &&
          linkSet.map((link, index) => {
            const linkId = `footerBrandingLink_${index}`;
            return (
              <React.Fragment key={index}>
                <span className={classes.brandingLinkSpan}>
                  <LinkText
                    link={link}
                    linkId={linkId}
                    language={language}
                  ></LinkText>
                </span>
              </React.Fragment>
            );
          })}
      </div>
    );
  };

  return (
    <div id={footerId} className={classes.top + " footerTop"}>
      <div className={classes.brandingDivs + " " + classes.brandingLogo + " brandingDivs brandingLogoDiv"}>
        {logo && <Logo id={"brandingLogo"} styles={logoStyles} logo={logo} language={language} />}
      </div>
      <div className={classes.brandingDivs + " " + classes.brandingLinks + " brandingDivs"}>{renderLinkSet()}</div>
      <div className={classes.footerDivider}><Line lineStyles={lineStyles} name={"dividerLine"} /></div>
    </div>
  );
};

export default BrandingFooter;
