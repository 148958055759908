import Icon from '../Icon/Icon';

const backgroundStyle = {
    backgroundColor: '#FFF',
    opacity: '0.5',
    position: 'fixed' as any,
    top: '0',
    left: '0',
    width: '100%',
    height: '100%',
    zIndex: '9998' as any
};

const spinnerStyle = {
    position: 'fixed',
    top: '30%',
    left: '50%',
    marginTop: '-15px',
    marginLeft: '-15px'
};

const Loader = () =>
    <div style={backgroundStyle}>
        <Icon name='spinner' type="regular" size='2x' style={spinnerStyle} />
    </div>;

export default Loader;