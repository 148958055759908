import { merge } from 'lodash';
import { useState } from 'react';
import { RenderElement } from "../";
import Icon from '../Icon/Icon';
import Navigation from '../Navigation/Navigation';
import classes from "./Modal.module.scss";

type Props = {
    reference?: string;
    container?: any;
    name?: string;
    language: string;
    open: boolean;
    setOpen: any;
};

const closeNavigationElements: any = ['modalScreen', 'GoBackBtn', 'IDoNotWanttoContinueBtn'];

const modalStyle = {
    position: 'absolute' as any,
    width: '475px',
    minHeight: '250px',
    maxHeight: 'calc(100% - 250px)',
    top: '0',
    left: '0',
    bottom: '0',
    right: '0',
    margin: 'auto',
    backgroundColor: 'var(--background-color1)',
    zIndex: '1' as any,
    padding: '30px',
    border: '5px solid var(--primary-color)',
    borderRadius: '5px',
    overflowY: 'auto' as any
};

const modalScreenStyle = {
    position: 'fixed' as any,
    width: '100vw',
    height: '100vh',
    top: 0,
    left: 0,
    backgroundColor: 'var(--line-color-background)'
};

const closeBtn: any = {
    fontSize: "15pt"
};

const closeBtnContainer = {
    cursor: 'pointer',
};

const closeIcon = {
    type: "Regular",
    name: "times",
    position: "left"
};

const renderInnerElements = (container: any, language: any) => {
    return (
        <div className={classes.modalContent}>
            {container && container.elements.map((element: any) => {
                return (
                    <RenderElement
                        key={element.name}
                        element={element}
                        language={language}
                        container={container}
                    />
                );
            })}
        </div>
    );
};

const renderNavigation = (element: any, language: any) => {
    return (
        <>
            {element && (
                <Navigation
                    id={element.name}
                    key={element.name}
                    elements={element.elements}
                    language={language}
                    type={"inline"}
                />
            )}
        </>
    );
}

const Modal = ({ reference, container, name, language, open, setOpen }: Props) => {
    const [refContainer] = useState(container.containers.find((c: any) => c.name === reference) || null);
    const [refContainerBody] = useState(refContainer ? refContainer.containers.find((c: any) => c.type === 'Section') : null);
    const [refContainerNavigation] = useState(refContainer ? refContainer.containers.find((c: any) => c.type === 'NavSection') : null);

    const closeModal = (target: any, forceClose: boolean = false) => {
        if ((target && closeNavigationElements.includes(target.getAttribute('id'))) || forceClose) {
            setOpen(!open);
        }
    }

    return (
        <>
            {
                refContainer && open && (
                    <div id={'modalScreen'} onClick={(eve) => closeModal(eve.target)} style={modalScreenStyle}>
                        <div id={name} className={'Modal'} style={modalStyle}>
                            <div id="modalClose" className={classes.modalClose}>
                                <span style={closeBtnContainer} onClick={() => closeModal(null, true)}>
                                    <Icon
                                        name={closeIcon.name}
                                        size={'lg'}
                                        type={closeIcon.type}
                                        style={merge({}, closeBtn, closeIcon)}
                                    />
                                </span>
                            </div>
                            {renderInnerElements(refContainerBody, language)}
                            {renderNavigation(refContainerNavigation, language)}
                        </div>
                    </div>
                )}
        </>
    );
};

export default Modal;