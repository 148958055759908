import classes from "./Text.module.scss";
import { handleText } from "../../../utils/helpers";
import ReactMarkdown from "react-markdown";
import { merge } from 'lodash';
import './Textclasses.css';
import Initials from "../Initials/Initials";

type Props = {
    name?: string,
    text: CardTitleInterface,
    language: string,
    passedStyle?: any,
    givenClass?: any,
    display?: any,
    payload?: any
};

interface CardTitleInterface {
    type: string,
    content: [{
        language: string,
        text: string
    }]
};

const style = (props: any) => {
    return {
        color: `var(${props.color})` || "var(--p-font-color)",
        fontSize: `${props.fontSize + props.fontSizeUnit || 'var(--p-font-size)'}`,
        display: `${props.display || 'block'}`
    }
}

const FormattedText = ({ name, text, language, passedStyle, givenClass, payload, ...props }: Props) => {
    let hText = handleText(text, language);
    let showInitial = false;
    if (hText.indexOf('<initials>') > -1) {
        hText = hText.replace('<initials>', '').trim();
        if (hText) {
            showInitial = true;
        }
    }
    if (payload && typeof payload === 'string') {
        hText = payload;
    }
    return (
        <span id={name} style={merge({}, style(props), passedStyle)}>
            {showInitial ? <Initials name={hText} /> : ''}
            <ReactMarkdown className={classes.pText + " " + givenClass + " " + (showInitial ? classes.inlineDisplay : '')}>{hText}</ReactMarkdown>
        </span>
    )
}

export default FormattedText;
