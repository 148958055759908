import { useEffect, useState, useContext } from "react";
import { api, useSetHint, useConditionals, formatCurrency, isRequiredField } from "../../../utils/helpers";
import {
  WizardStateContext,
  useStepConfigState,
  useStepElementConfigUpdater,
} from "../";
import BaseInput from "./BaseInput";
import { conformToMask } from 'text-mask-core';
import * as _ from 'lodash';
import { isNull } from "lodash";

type Props = {
  autoFocus?: boolean;
  sets: any;
  name: string;
  label: any;
  placeHolder: any;
  validations?: any;
  conditionals?: any;
  language: string;
  error?: any | null;
  hint?: any;
  type?: string;
  helpInfo?: any;
};

// TODO: Add provision of {"mask": <mask pattern>} for "_t = InputText" wherever it is required and remove below mappings from UI
const masks = {
  PHONE: ['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/],
  SSN: [/\d/, /\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/],
  DOB: [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]
};

const inputsHavingMasks = ['PhoneInput', 'PhoneInput2', 'HHMemberSSN', 'NewPersonSSN', 'DOBInput', 'HHPersonDOBInputText'];

const getMask = (name: string) => {
  let mask = null;
  switch (name) {
    case 'PhoneInput':
    case 'PhoneInput2':
      mask = masks.PHONE;
      break;
    case 'HHMemberSSN':
    case 'NewPersonSSN':
      mask = masks.SSN;
      break;
    case 'DOBInput':
    case 'HHPersonDOBInputText':
      mask = masks.DOB;
      break;
  }
  return mask;
};

const Input = ({
  label,
  autoFocus,
  sets,
  name,
  placeHolder,
  language,
  validations,
  error,
  hint,
  conditionals,
  type = "InputText",
  helpInfo,
}: Props) => {
  const [errorMessage, setErrorMessage] = useState(error);
  const [isRequired, setIsRequired] = useState({ value: false, message: null });
  const [maxLength, setMaxLength] = useState({ value: "", message: null });
  const [urlValidation, setUrlValidation] = useState<any>(null)
  const [isFocus, setIsFocus] = useState(false);
  const wizardState = useContext(WizardStateContext.Context);
  const [value, setValue] = useState(wizardState.WizardState.get(sets.name) || "");
  const setWizardValue = wizardState.WizardActions && wizardState.WizardActions.get("setWizardStateValue");
  const addHint = useSetHint();
  const setStepElementConfig = useStepElementConfigUpdater();
  const stepConfig = useStepConfigState();
  const currentStep = wizardState.WizardState && wizardState.WizardState.get("currentStep");
  const handleConditionals = useConditionals(conditionals);
  const MoneyInputText = "MoneyInputText";
  const configValid = Boolean(stepConfig) && !_.isEmpty(stepConfig);

  useEffect(() => {
    if (value && !handleConditionals.render) {
      setValue(null);
      setWizardValue && setWizardValue(sets.name, null);
    }
    if (isNull(value) && handleConditionals.render) {
      setValue("");
      setWizardValue && setWizardValue(sets.name, "");
    }
  }, [handleConditionals, setWizardValue, sets.name, value]);

  useEffect(() => {
    setErrorMessage(value === "" ? error : null);
  }, [error, value]);

  useEffect(() => {
    setIsRequired(isRequiredField(validations));
    const setValidations = (validationItems: any) => {
      for (let validation of validationItems) {
        switch (validation.type) {
          case "MaxLength":
            setMaxLength({
              value: validation.value,
              message: validation.onError,
            });
            break;
          case "Url": {
            const passedUrl = validation.url;
            let baseUrl = passedUrl.substring(0, passedUrl.indexOf("{"));
            let endUrl = passedUrl.substring(passedUrl.indexOf("}") + 1, passedUrl.length);
            setUrlValidation({ baseUrl: baseUrl, endUrl: endUrl, sets: validation.onSuccess.sets, onError: validation.onError, validation: validation, loading: false });
            break;
          }
        }
      }
    };
    setValidations(validations);
  }, [validations]);

  useEffect(() => {
    if (hint) {
      hint['hintFor'] = name;
      addHint(hint);
    }
  }, [hint, addHint, name])

  const setIsValidInputConfig = (isValid: boolean) => {
    if (configValid) {
      const element = stepConfig[currentStep][name];
      element.isValidInput = isValid;
      setStepElementConfig(currentStep, name, element);
    }
  }

  const setElementConfig = (elementName: string | number, field: string | number, elementValue: any) => {
    const element = stepConfig[currentStep][elementName];
    element[field] = elementValue;
    setStepElementConfig(currentStep, elementName, element);
    if (["OptionList", "Radio"].includes(element.type) && elementValue.length === 1) {
      setWizardValue && setWizardValue(element.sets.name, elementValue[0].value);
    }
  }

  const checkMask = (inputValue: any) => {
    let mask = getMask(name);
    let newValue = conformToMask(inputValue, mask, { guide: false });
    newValue = newValue.conformedValue;
    setValue(newValue);
    setWizardValue && setWizardValue(sets.name, newValue);
  }

  const checkValidation = (inputValue: any) => {
    setErrorMessage(null);
    let errorBool = false;
    if (isRequired.value && inputValue === "") {
      updateElementConfigData([]);
      setErrorMessage(isRequired.message);
      errorBool = true;
    }
    for (let validation of validations) {
      switch (validation.type) {
        case "Regex":
          const regexMatch = new RegExp(validation.value);
          if (!regexMatch.test(inputValue)) {
            errorBool = true;
            setErrorMessage(validation.onError);
            return;
          }
          break;
        default:
          break;
      }
    }
    return !errorBool;
  }

  const handleUrl = async (newValue: any) => {
    let ready = false;
    if (urlValidation && urlValidation.baseUrl) {
      const conditions = urlValidation.validation.trigger.conditions;
      if (conditions && conditions.length) {
        for (let condition of conditions) {
          switch (condition.type) {
            case "onMatchRegex":
              const regexMatch = new RegExp(condition.value);
              if (regexMatch.test(newValue)) {
                ready = true;
              }
              break;
            default:
              break;
          }
        }
      } else {
        ready = true;
      }
      if (ready && !Boolean(errorMessage)) {
        const validationUrl = urlValidation.baseUrl + newValue + urlValidation.endUrl;
        setUrlValidation({ ...urlValidation, loading: true });
        let base = validationUrl.includes('https') ? {
          headers: () => ({
            "Content-Type": "application/json",
            "Ocp-Apim-Subscription-Key": process.env.REACT_APP_API_KEY,
          })
        } : null;
        const data = await api.get({ url: validationUrl, base: base });
        setUrlValidation({ ...urlValidation, loading: false });
        if (data) {
          setIsValidInputConfig(true);
          updateElementConfigData(data);
          if (urlValidation.onError?.sets?.name) {
            setWizardValue && setWizardValue(urlValidation.onError?.sets?.name, null);
          }
        } else {
          setIsValidInputConfig(false);
          updateElementConfigData([]);
          if (urlValidation.onError?.sets?.name) {
            setWizardValue && setWizardValue(urlValidation.onError?.sets?.name, urlValidation.onError?.sets?.value);
          }
        }
      } else {
        updateElementConfigData([]);
        if (urlValidation.onError?.sets?.name) {
          setWizardValue && setWizardValue(urlValidation.onError?.sets?.name, null);
        }
      }
    }
  }

  const updateElementConfigData = (data: any) => {
    if (urlValidation?.sets?.state === "WizardLevelState") {
      setElementConfig(urlValidation.sets?.name, urlValidation.sets?.field, data);
    }
  }

  const handleBlur = (event: any) => {
    let isValid = true;
    setErrorMessage(null);
    const blurValidations = validations.filter((validation: any) => validation.trigger.event === "onBlur");
    for (let validation of blurValidations) {
      switch (validation.type) {
        case "Required":
          if (validation.value?.toLowerCase() === "true" && event.target.value === "") {
            isValid = false;
            setErrorMessage(validation.onError);
          }
          break;
        case "MinLength":
          if (event.target.value.length !== parseInt(validation.value, 10)) {
            isValid = false;
            setErrorMessage(validation.onError);
          }
          break;
        case "MaxLength":
          if (event.target.value.length > parseInt(validation.value, 10) && type !== MoneyInputText) {
            isValid = false;
            setErrorMessage(validation.onError);
          }
          break;
        case "Regex":
          const regexMatch = new RegExp(validation.value);
          if (!regexMatch.test(event.target.value)) {
            isValid = false;
            setErrorMessage(validation.onError);
          }
          break;
        default:
          break;
      }
    }

    if (type === MoneyInputText) {
      const currencyFormatOptions = {
        inputElement: event.target,
        integerLimit: +maxLength.value,
        isBlur: true
      }
      const newValue = formatCurrency(currencyFormatOptions)
      setValue(newValue);
      setWizardValue && setWizardValue(sets.name, newValue);
    }
    if (urlValidation && urlValidation.baseUrl) {
      handleUrl(event.target.value);
    }
    if (urlValidation && urlValidation.loading) {
      isValid = false;
    }
    setIsValidInputConfig(isValid);
    setIsFocus(false);
  };

  const clearPageError = () => {
    let pageError = wizardState.WizardState.get("PageLevelError");
    if (pageError) {
      setWizardValue("PageLevelError", null);
    }
  }

  const handleChange = async (e: any) => {
    clearPageError();
    let newValue = maxLength.value ? e.target.value.substring(0, maxLength.value) : e.target.value;
    if (type === MoneyInputText) {
      const currencyFormatOptions = {
        inputElement: e.target,
        integerLimit: +maxLength.value
      }
      newValue = formatCurrency(currencyFormatOptions);
    }
    switch (sets.state) {
      case "WizardLevelState":
        setValue(newValue);
        setWizardValue && setWizardValue(sets.name, newValue);
        break;
      default:
        break;
    }
    if (inputsHavingMasks.includes(name)) {
      checkMask(newValue);
    }
    const validated = checkValidation(newValue);
    if (validated) {
      handleUrl(newValue);
    } else {
      setIsValidInputConfig(false);
    }
  };

  const handleOnFocus = () => {
    setIsFocus(true);
  };

  return (
    <>
      {handleConditionals.render &&
        <BaseInput
          label={label}
          autoFocus={autoFocus}
          name={name}
          placeHolder={placeHolder}
          language={language}
          handleChange={handleChange}
          handleOnFocus={handleOnFocus}
          handleBlur={handleBlur}
          errorMessage={errorMessage}
          value={value}
          isRequired={isRequired.value}
          isFocus={isFocus}
          helpInfo={helpInfo}
        />
      }
    </>
  );
};

export default Input;