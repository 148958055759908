import { useState } from 'react';
import { RenderElement, Icon } from "../";
import { handleText } from '../../../utils/helpers';

type Props = {
    reference?: string;
    container?: any;
    name?: string;
    language: string;
    collapsible?: boolean;
    collapsed?: boolean;
    text?: any;
};

const accordionStyle = (isOpen: boolean) => {
    return {
        cursor: 'pointer',
        color: 'var(--text-color)',
        background: 'var(--shadow-color)',
        padding: '5px 10px',
        marginBottom: isOpen ? '40px' : '15px',
        maxWidth: 'calc(var(--h1-max-width) - 20px)'//,
        //width: 'calc(var(--h1-max-width) - 20px)'
    }
};

const iconStyle = {
    float: 'right' as any
}

const renderInnerContainers = (containers: any, language: any) => {
    let innerContainers = containers.map((container: any) => (
        <div>
            {container.elements.map((element: any) => {
                return (
                    <RenderElement
                        key={element.name}
                        element={element}
                        language={language}
                        container={container}
                    />
                );
            })}
        </div>
    ))
    return innerContainers;
};

const Accordion = ({ reference, container, name, language, collapsible, collapsed, text }: Props) => {
    const [isOpen, setIsOpen] = useState(!collapsed);
    const [refContainer] = useState(container.containers.find((c: any) => c.name === reference) || null);

    const handleClick = () => {
        if (collapsible) {
            setIsOpen(!isOpen);
        }
    }
    return (
        <>
            {
                refContainer && (
                    <div id={name} className={'Accordion'}>
                        <h2 onClick={handleClick} style={accordionStyle(isOpen)}>{handleText(text, language)} <span style={iconStyle}><Icon
                            name={isOpen ? 'chevron-up' : 'chevron-down'}
                            type={'regular'}
                            size={'sm'}
                        /></span></h2>
                        {isOpen && renderInnerContainers(refContainer.containers, language)}
                    </div>
                )}
        </>
    );
};

export default Accordion;