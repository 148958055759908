import { useState, useEffect, useContext } from "react";
import BaseFooter from "./BaseFooter";
import BrandingFooter from "./BrandingFooter";
import GlobalContext from "../../utils/wrappers/ContextEngine";

type Props = {
  footerConfig:
    | undefined
    | {
        base: object | null;
        branding?: object | null;
      }
    | null;
  language: string;
};

const footerStyle = {
  backgroundColor: "var(--background-color1)",
  width: "100%",
  paddingTop: "40px",
  paddingBottom: "40px",
};

const Footer = ({ footerConfig, language }: Props) => {
  const [baseConfig, setBaseConfig] = useState({});
  const [brandingConfig, setBrandingConfig] = useState({});
  const [loading, setLoading] = useState(true);
  const globalContextState = useContext(GlobalContext.Context);
  const [metaFooter, setMetaFooter] = useState<any>({ base: true });

  useEffect(() => {
    if (footerConfig) {
      setBrandingConfig(footerConfig.branding || {});
      setBaseConfig(footerConfig.base || {});
      setLoading(false);
    }
  }, [footerConfig]);

  useEffect(() => {
    let footerMeta =
      globalContextState.ContextState &&
      globalContextState.ContextState.get("metaFooter");
    if (footerMeta) setMetaFooter(footerMeta);
  }, [globalContextState]);

  return (
    <footer id="footer" role="contentinfo" style={footerStyle}>
      {!loading && (
        <>
          {metaFooter && metaFooter.branding && (
            <BrandingFooter
              brandingConfig={brandingConfig}
              language={language}
            />
          )}
          {metaFooter && metaFooter.base && (
            <BaseFooter baseConfig={baseConfig} language={language} />
          )}
        </>
      )}
    </footer>
  );
};

export default Footer;
