import { useState, useContext } from "react";
import { WizardStateContext } from "../";
import FieldLabel from './FieldLabel';
import { useConditionals, handleText } from "../../../utils/helpers";

type Props = {
    name?: string,
    label: any,
    language: string,
    conditionals?: any,
    required?: any,
    requiredLabel?: any,
    passedStyle?: any,
};

const checkState = (text: any, state: any) => {
    let newText = Object.assign({}, text);
    let matches = [];
    for (let i = 0; i < text.sets.name.length; i++) {
        let indexA = text.sets.name.indexOf("<", i) + 1;
        let indexB = text.sets.name.indexOf(">", i);
        let match = text.sets.name.slice(indexA, indexB);
        matches.push(match);
        i = indexB;
    }
    for (let match of matches) {
        let value = state.WizardState.get(match) || localStorage.getItem(match);
        let replace = "<" + match + ">";
        newText.content[0].text = newText.content[0].text.replace(replace, value ? value : "");
    }
    return newText;
}

const WizardLabel = ({ name, label, language, required, requiredLabel, passedStyle, conditionals, ...props }: Props) => {
    const handleConditionals = useConditionals(conditionals);
    const wizardState = useContext(WizardStateContext.Context);
    const sets = label.sets || null;
    const [value] = useState(sets ? checkState(label, wizardState) : null);

    return (
        <>
            {
                handleConditionals.render &&
                <FieldLabel
                    name={name}
                    label={sets ? handleText(value, language) : handleText(label, language)}
                    required={required}
                    requiredLabel={requiredLabel}
                    style={passedStyle}
                    {...props}
                />
            }
        </>
    )
}

export default WizardLabel;