import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { handleText } from "../../../utils/helpers";
import classes from "../Footer.module.scss";

type Props = {
  id?: string;
  logo: any;
  language: string;
  styles?: undefined | object;
};

const Logo: React.FC<Props> = ({ id, logo, language, styles }) => {
  const [altText, setAltText] = useState("");

  useEffect(() => {
    if (logo && logo.text && Boolean(language)) {
      setAltText(handleText(logo.text, language));
    }
  }, [logo, language]);

  return (
    <>
      {logo && (
        <a
          id={`${id}Footerlogo`}
          className={classes.footerlogo}
          href={logo.link?.url}
          target={logo.link?.openTo || "_blank"}
          aria-label={altText + " " + id}
        >
          <img
            id={id || "footerLogo"}
            src={logo.image}
            alt={altText}
            className={classes.logo}
            title={altText}
            style={styles}
          ></img>
        </a>
      )}
    </>
  );
};

Logo.propTypes = {
  logo: PropTypes.object,
  language: PropTypes.string.isRequired,
};

export default Logo;
