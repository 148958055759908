import { useState, useEffect, useContext } from "react";
import { useParams, useLocation } from "react-router";
import Header from "../Header/Header";
import Main from "../Main/Main";
import Footer from "../Footer/Footer";
import { Loader } from "../Library";
import { handleText } from "../../utils/helpers";
import { Helmet } from 'react-helmet';
import GlobalContext from '../../utils/wrappers/ContextEngine';

const SolutionConfg = () => {
  const params: any = useParams();
  const location = useLocation(); // Use useLocation hook to access current URL
// Initialize language state based on URL parameter or default to "en-us"
  const langParam = new URLSearchParams(location.search).get("language");
  const initialLanguage = langParam ? langParam : "en-us";

  const globalContextState = useContext<any>(GlobalContext.Context);
  const [globalActions] = useState<any>(globalContextState.ContextActions);
  const setSolutionId = globalActions?.get("SetSolutionID");
  const [headerConfig, setHeaderConfig] = useState();
  const [mainConfig, setMainConfig] = useState();
  const [defaultContainer, setDefaultContainer] = useState();
  const [footerConfig, setFooterConfig] = useState();
  const [metaConfig, setMetaConfig] = useState<any>();
  const [loading, setLoading] = useState(true);
  const [language, setLanguage] = useState(initialLanguage);

  useEffect(() => {
    setSolutionId(params.solution);
  }, [params.solution, setSolutionId]);


  const changeLanguage = (lang: string) => {
    setLanguage(lang);
  };

  useEffect(() => {
    const solutionConfig = globalContextState.Configuration;
    if (solutionConfig && solutionConfig) {
      setHeaderConfig(solutionConfig.header);
      setMainConfig(solutionConfig.containers);
      setDefaultContainer(solutionConfig.defaultContainer);
      setFooterConfig(solutionConfig.footer);
      setMetaConfig(solutionConfig.meta);
      setLanguage(solutionConfig.header.languageSettings.default);
      setLoading(false);
    }
    changeLanguage(initialLanguage);
  }, [globalContextState.Configuration, initialLanguage]);


  return (
    <>
      {!loading && Boolean(metaConfig) ? (
        <Helmet>
          <title>{handleText(metaConfig?.title, language)}</title>
          <meta name="description" content={handleText(metaConfig?.description, language)} />
          <meta property="og:type" content="website" />
          <meta property="og:title" content={handleText(metaConfig?.title, language)} />
          <meta property="og:description" content={handleText(metaConfig?.description, language)} />
          <meta property="og:url" content={metaConfig?.url} />
          <meta property="og:image" content={metaConfig?.image} />
          <meta property="twitter:image" content={metaConfig?.twitterImage} />
          <meta property="twitter:card" content="summary_large_image" />
          <meta property="twitter:image:alt" content={handleText(metaConfig?.description, language)} />
        </Helmet>
      ) : (
        <Helmet>
          <title>{"Tennessee Screener"}</title>
          <meta name="description" content="A quick way for students to learn about benefits they may qualify for." />
          <meta property="og:type" content="website" />
          <meta property="og:title" content="Tennessee Screener" />
          <meta property="og:description" content="A quick way for students to learn about benefits they may qualify for." />
          <meta property="og:url" content="https://onexconnection.org/tnquickscreener" />
          <meta property="og:image" content="https://stcxdevcdnuw01.blob.core.windows.net/images/Wires-QSSocialMedia-Facebook.png" />
          <meta property="twitter:image" content="https://stcxdevcdnuw01.blob.core.windows.net/images/Wires-QSSocialMedia-Twitter.png" />
          <meta property="twitter:card" content="summary_large_image" />
          <meta property="twitter:image:alt" content="Tennessee Screener" />
        </Helmet>
      )}
      {!loading && headerConfig && (
        <Header
          headerConfig={headerConfig}
          changeLanguage={changeLanguage}
          language={language}
        />
      )}
      {(!loading && mainConfig) ? (
        <Main
          mainConfig={mainConfig}
          defaultContainer={defaultContainer}
          language={language}
        />
      ) : <Loader />}
      {!loading && footerConfig && (
        <Footer footerConfig={footerConfig} language={language} />
      )}
    </>
  );
};

export default SolutionConfg;
