import { useState } from 'react';
import { RenderElement } from "../";

type Props = {
    reference?: string;
    container?: any;
    name?: string;
    language: string;
};

const scrollPanelStyle = {
    border: '1px solid var(--line-color)',
    display: 'inline-block',
    textAlign: 'start' as any,
    appearance: 'textarea' as any,
    backgroundColor: 'var(--background-color1)',
    maxWidth: '680px',
    height: '380px',
    overflowY: 'scroll' as any,
    padding: '2px',
    overflowWrap: 'break-word' as any
};

const renderInnerContainers = (containers: any, language: any) => {
    let innerContainers = containers.map((container: any) => (
        <div>
            {container.elements.map((element: any) => {
                return (
                    <RenderElement
                        key={element.name}
                        element={element}
                        language={language}
                    />
                );
            })}
        </div>
    ))
    return innerContainers;
};

const ScrollPanel = ({ reference, container, name, language }: Props) => {
    const [refContainer] = useState(container.containers.find((c: any) => c.name === reference) || null);

    return (
        <>
            {
                refContainer && (
                    <div id={name} className={'ScrollPanel'} style={scrollPanelStyle}>
                        {renderInnerContainers(refContainer.containers, language)}
                    </div>
                )}
        </>
    );
};

export default ScrollPanel;