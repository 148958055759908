import React from 'react';
import PropTypes from 'prop-types';
import './menu.css';

type Props = {
    passedStyle?: object,
    children: any
}

const pullDownMenuStyle = {
    height: '65px',
    width: '100%'
};

const pullDownMenuOptionsStyle = {
    height: '65px',
    width: '100%',
    textAlign: 'right'
};

const setStyles = (style: object, passedStyle: object | undefined) => {
    return Object.assign({}, style, passedStyle)
};

const PullDownMenu = ({ passedStyle, children }: Props) => {
    return (
        <React.Fragment>
            <div style={setStyles(pullDownMenuStyle, passedStyle)}>
                <div style={setStyles(pullDownMenuOptionsStyle, passedStyle)} className='pullDownMenuOptions'>{children}</div>
            </div>
        </React.Fragment>
    );
};

PullDownMenu.propTypes = {
    style: PropTypes.object,
    children: PropTypes.any
};

export default PullDownMenu;
