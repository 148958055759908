import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import classes from "./Link.module.scss";
import { Icon } from "../";

type Props = {
  link: any;
  language: string;
};

interface LogoLink {
  type?: string;
  url?: string;
  openTo?: string;
}

const socialLinkIcon = {
  padding: "2px",
  fontSize: "20px",
};

const SocialLink: React.FC<Props> = ({ link, language }) => {
  const [logoLink, setLogoLink] = useState<LogoLink>({});
  const [logo, setLogo] = useState(String);

  useEffect(() => {
    if (link && link.link && link.logo && Boolean(language)) {
      setLogoLink(link.link);
      setLogo(link.logo);
    }
  }, [link, language]);

  return (
    <span>
      {logo && (
        <a
          id={logo + "Link"}
          href={logoLink.url}
          className={classes.socialLink + " socialLink"}
          target={link.link.openTo || "_blank"}
          rel="noreferrer"
          title={logo}
        >
          <Icon name={logo} size="1x" type="brand" style={socialLinkIcon} />
        </a>
      )}
    </span>
  );
};

SocialLink.propTypes = {
  link: PropTypes.object,
  language: PropTypes.string.isRequired,
};

export default SocialLink;
