import { useContext, useEffect, useState } from 'react';
import { FormattedText, RenderElement } from "../";
import classes from "./DataTable.module.scss";
import { api } from '../../../utils/helpers';
import Icon from '../Icon/Icon';
import { WizardStateContext } from "../";

type Props = {
    element?: any;
    reference?: string;
    container?: any;
    name?: string;
    language?: string;
    payload?: any;
};

let blankValue = <></>;
const checkStyle = { color: 'var(--accent-color2)' };
const fileStyle = { color: 'var(--primary-color)' };
const exclamationStyle = { color: 'var(--accent-color4)' };

const getStyle = (column: any, header: boolean) => {
    const style = {
        textAlign: column.align,
        minWidth: header ? '100%' : column.width,
        breakWord: 'break-all',
        color: header ? 'var(' + column.color + ')' : 'var(--text-color)',
        fontSize: header ? column.fontSize + column.fontSizeUnit : '12pt',
        display: 'inline-block',
        borderBottom: header ? '1px solid var(--line-color)' : '0',
        minHeight: header ? '50px' : '150px',
        paddingRight: '10px',
        OverflowWrap: 'anywhere'
    };
    return style;
};

const dataTableStyle = {
    display: 'inline-flex'
};

// Lower the first character of the string
const lowerize = (str: string) => {
    return str.charAt(0).toLocaleLowerCase() + str.slice(1);
};

const toLocalDate = (utcDate: string) => {
    return utcDate ? new Date(utcDate).toLocaleDateString('en-US') : utcDate;
};

const getMatchNoMatchField = (fieldValue: string) => {
    return ['true', true].includes(fieldValue) ? <p><Icon name={'check'} type={"solid"} style={checkStyle} /></p> :
        (['false', false].includes(fieldValue) ? <p><Icon name={'times'} type={"solid"} style={exclamationStyle} /></p> : blankValue);
};

const openDocument = (url: string) => {
    alert(`Open url for ${url} is in progress`);
};

const removeDocument = (documentId: string) => {
    alert(`Remove document for ${documentId} is in progress`);
};

const getDocumentField = (document: any) => {
    return document.name ? <p><span className={classes.fileLink} onClick={() => openDocument(document.url)}><Icon name={'file-alt'} type={"solid"} style={fileStyle} /></span></p> : blankValue;
};

const getDocumentRemoveField = (document: any) => {
    return document.name ? <p><span className={classes.fileLink} onClick={() => removeDocument(document.name)}><Icon name={'times-circle'} type={"solid"} style={fileStyle} /></span></p> : blankValue;
};

const getFormattedData = (data: any, column: any, element: any, language: string) => {
    let dataValue = data[`${column.accessor}`] || data[`${lowerize(column.accessor)}`];
    switch (column.accessor) {
        case 'phoneNumbers':
            dataValue = data.phoneNumbers.find((e: any) => e.isCell);
            dataValue = dataValue ? dataValue.number : '';
            break;
        case 'Street':
            dataValue = data.addressess.find((e: any) => e.isCurrent);
            dataValue = dataValue ? dataValue.street1 : '';
            break;
        case 'dob':
            dataValue = toLocalDate(data.dob);
            break;
    }

    switch (column.format) {
        case 'Last4Digits':
            dataValue = dataValue.slice(dataValue.length - 4);
            break;
    };

    let elementData: any;
    switch (column.format) {
        case 'CheckMatch':
            elementData = getMatchNoMatchField(data.ssnMatch || data.verified);
            break;
        case 'Document':
            elementData = getDocumentField(data.document);
            break;
        case 'DocumentRemove':
            elementData = getDocumentRemoveField(data.document);
            break;
        case 'Checkbox':
            // Considering here a single option in checkbox in datta field
            element.name = 'selectOption-' + data.id;
            const elementObj = JSON.parse(JSON.stringify(element));
            elementObj.options.forEach((option: any, index: number) => {
                option.value = data[`${element.options[index].value}`] || option.value;
            });
            elementData = <RenderElement element={elementObj} language={language} payload={`${dataValue}`} />;
            break;
        default:
            elementData = dataValue ? <RenderElement element={element} language={language} payload={dataValue} /> : blankValue;
    };

    return elementData;
};

const getStorageKeyValuesForRequest = (keys: any) => {
    let params = '';
    keys.forEach((key: any) => {
        // TODO enhancement: replace 'primary' with user type.
        let localStorageValue: any = localStorage.getItem(`applicants['primary'].${key}`) || '';
        if (key === 'SSN') {
            localStorageValue = localStorageValue.split('-').join('');
        }
        key = key.split('.');
        key = key[key.length - 1];
        if (localStorageValue && localStorageValue !== 'null') {
            params += (params ? '&' : '') + key + '=' + localStorageValue;
        }
    });
    return params;
};

const prepareUrlRequestParams = (request: string) => {
    let params = '';
    switch (request) {
        case 'personsUrlRequests':
            blankValue = <p>---</p>;
            // TODO: add all required request params to generate the URL request
            params = getStorageKeyValuesForRequest(['firstName', 'lastName', 'SSN']);
            break;
    };
    return params;
};

const renderTable = (element: any, container: any, language: any, payload?: any) => {
    const renderColumns = element.columns.map((column: any) => {
        return (
            <div id={'dataTableColumn' + column.accessor} style={getStyle(column, false)}>
                <FormattedText language={language} text={column.text} passedStyle={getStyle(column, true)} />
                <br />
                {
                    container.containers.map((cont: any) => {
                        return (
                            cont.elements.map((ele: any) => {
                                if (lowerize(ele.accessor) !== lowerize(column.accessor)) {
                                    return false;
                                }
                                if (payload) {
                                    let payloadArr: any = [];
                                    payload = payload.length > 0 ? payload[0] : payload;
                                    payload && payload[element.accessor] && payload[element.accessor].forEach((data: any) => {
                                        payloadArr.push(getFormattedData(data, column, ele, language));
                                        return true;
                                    });
                                    return payloadArr;
                                }
                                return (
                                    <RenderElement element={ele} language={language} />
                                );
                            })
                        );
                    })
                }
            </div>
        );
    });
    return (
        <div className={classes.dataTable} style={dataTableStyle}>{renderColumns}</div>
    );
};

const DataTable = ({ element, reference, container, language, payload }: Props) => {
    const [refContainer] = useState(container?.containers.find((c: any) => c.name === reference) || null);
    const [table, setTable] = useState<any>(null);
    const [processedApis, setProcessedApis] = useState<any>([]);
    const [apiOutput, setApiOutput] = useState<any>(null);
    const wizardState = useContext(WizardStateContext.Context);
    const setWizardValue = wizardState.WizardActions && wizardState.WizardActions.get("setWizardStateValue");

    useEffect(() => {
        if (refContainer.apis?.length > 0 && !apiOutput) {
            let apis = refContainer.apis;
            let tempApis = [];
            for (let tempApi of apis) {
                let rxp = /{(.*)}/;
                const matchUrl = tempApi.url.match(rxp);
                let tempProcessedUrlKey = matchUrl && matchUrl.length ? matchUrl[1] : '';
                const requestParams = prepareUrlRequestParams(tempProcessedUrlKey);
                let fetchFromStorage = requestParams || localStorage.getItem(tempProcessedUrlKey) || '';
                let tempProcessedApi = {
                    url: tempApi.url.replace(rxp, fetchFromStorage),
                    base: {
                        headers: () => ({
                            "Content-Type": "application/json",
                            "Ocp-Apim-Subscription-Key": tempApi.key,
                        })
                    },
                    method: tempApi.method
                };
                tempApis.push(tempProcessedApi);
            }
            setProcessedApis(tempApis);
        }
    }, [refContainer, apiOutput, language, payload]);

    useEffect(() => {
        const handleApis = async () => {
            for (let processedApi of processedApis) {
                if (!apiOutput) {
                    let request = { url: processedApi.url, base: processedApi.base };
                    let postApi = await api.get(request);
                    let output = [];
                    output.push(postApi);
                    setWizardValue('tableResultCount', postApi[`${element.accessor}`].length);
                    setApiOutput(output);
                }
            }
        }

        if (!apiOutput) {
            handleApis();
        }
    }, [apiOutput, language, refContainer, processedApis, setWizardValue, element.accessor]);


    useEffect(() => {
        if (!table) {
            if (apiOutput) {
                setTable(renderTable(element, refContainer, language, apiOutput));
            }
        }
    }, [table, language, refContainer, element, apiOutput]);

    return (
        <>
            {
                refContainer && table
            }
        </>
    );
};

export default DataTable;