import { useState, useContext, useEffect } from "react";
import { Card, Navigation, RenderElement, WizardStateContext, useStepConfigUpdater, Loader } from "../";
import { isRequiredField, handleAnalytics, handleText } from '../../../utils/helpers';
import GlobalContext from "../../../utils/wrappers/ContextEngine";
import { Helmet } from 'react-helmet';

type Props = {
    wizardResultsConfig: any | undefined;
    language: string;
};

const WizardResults = ({ wizardResultsConfig, language }: Props) => {
    const wizardState = useContext(WizardStateContext.Context);
    const [wizardActions] = useState<any>(wizardState.WizardActions);
    const setWizardValue = wizardActions && wizardActions.get("setWizardStateValue");
    const fetchResults = wizardActions && wizardActions.get("fetchResults");
    const [results, setResults] = useState(wizardState.WizardState.get("results") || "");
    const setStepConfig = useStepConfigUpdater();
    const containers = wizardResultsConfig.containers;
    const analytics = handleAnalytics;
    const globalContextState = useContext(GlobalContext.Context);
    const loading = globalContextState.loading;
    const analyticsSecret = globalContextState?.Configuration?.analyticsTools?.find((at: any) => at.name === "Mixpanel")?.secret;

    useEffect(() => {
        analytics(wizardResultsConfig.analytics && wizardResultsConfig.analytics, null, analyticsSecret);
    }, [wizardResultsConfig, analytics, analyticsSecret]);

    useEffect(() => {
        setWizardValue && setWizardValue("currentStep", wizardResultsConfig.name);
    }, [setWizardValue, wizardResultsConfig]);

    useEffect(() => {
        const stepElements: any = {};

        const getStepElements = () => {
            containers.map((container: any) => {
                return container.containers.map((innerContainer: any) => {
                    return innerContainer.elements.map((element: any) => {
                        element.isRequiredOnSubmit = isRequiredField(element.validations || []);
                        if (innerContainer.type === "Section") {
                            let elementName = element.name;
                            const HINT_ELEMENT = "Hint";
                            if (element.type === HINT_ELEMENT) {
                                elementName = HINT_ELEMENT;
                            }
                            return stepElements[elementName] = element
                        } else {
                            return null;
                        }
                    });
                });
            });
            return stepElements;
        };

        setStepConfig(wizardResultsConfig.name, getStepElements());
    }, [setStepConfig, wizardResultsConfig, containers]);

    useEffect(() => {
        if (results === "") {
            fetchResults();
            let tempResults = wizardState.WizardState.get("results");
            Boolean(tempResults) && setResults(tempResults);
        }
    }, [results, fetchResults, wizardState]);

    const renderInnerContainers = (container: any) => {
        return container.containers.map((innerContainer: any) => {
            return innerContainer.type === "Section" ? (
                <Card
                    id={innerContainer.name}
                    key={innerContainer.name}
                    card={innerContainer.card}
                >
                    {innerContainer.elements.map((element: any) => {
                        return (
                            <RenderElement
                                key={element.name + innerContainer.name}
                                element={element}
                                language={language}
                                container={innerContainer}
                                payload={results}
                            />
                        );
                    })}
                </Card>
            ) : innerContainer.type === "NavSection" ? (
                <Navigation
                    id={innerContainer.name}
                    key={innerContainer.name}
                    elements={innerContainer.elements}
                    language={language}
                ></Navigation>
            ) : null;
        });
    };

    return containers.map((container: any) => {
        return (
            <div id={wizardResultsConfig.name + 'results'} key={wizardResultsConfig.name + 'results'}>
                <Helmet>
                    <title>{handleText(wizardResultsConfig.metaTitle, language)}</title>
                </Helmet>
                {loading ? <Loader /> : renderInnerContainers(container)}
            </div>
        );
    });
};

export default WizardResults;
