import { useEffect, useState, useContext } from 'react';
import { Text, Button, Icon, Line, ItemList } from "../";
import { api, handleText, handleAnalytics } from '../../../utils/helpers';
import { FormattedText } from '../Text';
import queryString from 'query-string';
import GlobalContext from '../../../utils/wrappers/ContextEngine';

type Props = {
    reference?: string;
    container?: any;
    name?: string;
    language?: string;
    payload?: any;
};

type IconProps = {
    name: string;
    type?: string;
    color?: string;
    size?: string;
};

const getIcon = (icon: IconProps) => {
    const iconObj = icon ? Object.assign({}, icon, { size: 'xs' }) : undefined;
    return iconObj;
};

const headingStyle = { marginBottom: 0 };
const checkStyle = { color: 'var(--accent-color2)', marginRight: '10px' };
const timesStyle = { color: 'var(--icon-color)', marginRight: '10px' };
const exclamationStyle = { color: 'var(--control-color)', marginRight: '10px' };
const linkStyle = { color: 'var(--primary-color)', textDecoration: 'none' };
const referralTextStyle = { width: "100%", height: '40px' };
let analyticsSecret: string = '';

// useEffect(() => {
// }, [analyticsSecret]);

const renderProgram = (container: any, language: any, program: any, analytics: any, analyticsSecret: string) => {
    let elements = JSON.parse(JSON.stringify(container.elements));
    let innerElements = [];
    let eligibilityText = program.isEligible ? (
        <>
            <p><Icon name={'check'} type={'solid'} size={'lg'} style={checkStyle} /><FormattedText name={handleText(program.eligibilityMessage.text, language)} language={language} text={program.eligibilityMessage.text} display={'inline-block'} /></p>
            {/*!!(program.amountDescription.text) &&
                <p><Icon name={'usd'} type={'solid'} size={'lg'} style={checkStyle} /> <strong>{handleText(program.amountDescription.text, language)}</strong></p>*/}
        </>
    ) : program.eligibilityMessage ? (
        <p><Icon name={'times'} type={'solid'} size={'lg'} style={timesStyle} /><FormattedText name={handleText(program.eligibilityMessage.text, language)} language={language} text={program.eligibilityMessage.text} display={'inline-block'} /></p>
    ) : null;
    let goodToKnow = program.goodToKnow ? (
        <p><Icon name={'exclamation-circle'} type={'solid'} size={'lg'} style={exclamationStyle} />{program.goodToKnow}</p>
    ) : null;
    for (let element of elements) {
        if (element.name === "ProgramTitle") element.text = program.title.text;
        if (element.name === "ProgramDescription") element.text = program.description.text;
        if (element.name === "LearnMore") {
            element.action.link.url = program.learnMorePageUrl + "?language=" + language;
            element.name = element.name + handleText(program.abbreviation.text, language);
            element.analytics.eventName = element.analytics.eventName + ' ' + handleText(program.abbreviation.text, language) + ' button clicked';
        };
        if (element.name === "ApplyNow") {
            element.action.link.url = program.applyNowUrl;
            element.name = element.name + handleText(program.abbreviation.text, language);
            element.analytics.eventName = element.analytics.eventName + ' ' + handleText(program.abbreviation.text, language) + ' button clicked';
        }
        if (element.name === "ReferralTitle") {
            element.items[0].icon.name = program.icon;
            element.items[0].text.content[0].text = program.referralArea;
        }
        if (element.name === "ReferralResultsName") {
            element.text.content[0].text = "## " + handleText(program.organizationName, language);
        }
        if (element.name === "ReferralResultsDescription") {
            element.text = program.organizationDescription;
        }
        if (element.name === "ReferralOtherURL") {
            element.text.content[0].text = `One Degree: Find other [${program.referralArea.toLowerCase()} resources](${program.otherResouceURL})`;
        }
        if (element.name === "ReferralLearnMore") {
            element.action.link.url = program.leanMoreURL;
        }
        innerElements.push(
            <>
                {element.name === "ProgramTitle" && (
                    <h2 id={handleText(program.abbreviation.text, language).replace(/\s+/g, '') + element.name} style={headingStyle}>{handleText(element.text, language)}</h2>
                )}
                {element.name === "ProgramDescription" && (
                    <>
                        <Text
                            name={handleText(program.abbreviation.text, language).replace(/\s+/g, '') + element.name}
                            key={handleText(program.abbreviation.text, language) + element.name}
                            text={element.text}
                            language={language}
                        />
                    </>
                )}
                {element.name === "ProgramEligibility" && (
                    <>
                        {eligibilityText}
                        {goodToKnow}
                    </>
                )}
                {element.type === "Button" && (
                    <a href={element.action.link.url} target={element.action.link.type === 'Absolute' ? "_blank" : "_self"} rel="noreferrer">
                        <Button
                            primary={(element.subType === 'Primary')}
                            icon={getIcon(element.icon)}
                            label={handleText(element.text, language)}
                            name={element.name.replace(/\s+/g, '')}
                            onClick={() => analytics(element.analytics, { "url": element.action.link.url }, analyticsSecret)}
                        /></a>
                )}
                {
                    element.name === "ReferralTitle" && (
                        <>
                            <ItemList
                                name={element.name}
                                items={element.items}
                                columnCount={element.columnCount}
                                language={language}
                                style={{ height: '30px' }}
                                {...element}
                            />
                        </>
                    )
                }
                {
                    element.name === "ReferralResultsName" && (
                        <>
                            <FormattedText
                                name={element.name}
                                text={element.text}
                                language={language}
                                passedStyle={referralTextStyle}
                            />
                        </>
                    )
                }
                {
                    element.name === "ReferralResultsDescription" && (
                        <>
                            <FormattedText
                                name={element.name}
                                text={element.text}
                                language={language}
                                passedStyle={referralTextStyle}
                            />
                        </>
                    )
                }
                {
                    element.name === "ReferralOtherURL" && (
                        <>
                            <br />
                            <span>One Degree: Find other <a href={program.otherResouceURL} target="_blank" rel="noreferrer" style={linkStyle}>{program.referralArea.toLowerCase()} resources <Icon name={'external-link-alt'} type={'regular'} size={'sm'} style={linkStyle} /></a></span>
                        </>
                    )
                }
                {element.type === "Line" && <Line />}
            </>
        );
    }
    let programElements = (
        <div key={program.abbreviation ? handleText(program.abbreviation.text, language) : program.referralArea} id={program.abbreviation ? handleText(program.abbreviation.text, language).replace(/\s+/g, '') : program.referralArea} className={"program"}>{innerElements}</div>
    )
    return programElements;
};

const spinnerStyle = {
    display: 'block',
    width: '720px'
};

const ProgramTemplate = ({ reference, container, payload, language }: Props) => {
    const [refContainer] = useState(container?.containers.find((c: any) => c.name === reference) || null);
    const [programs, setPrograms] = useState<any>(null);
    const [processedApis, setProcessedApis] = useState<any>([]);
    const [apiOutput, setApiOutput] = useState<any>(null);
    const [loading, setLoading] = useState(false);
    const analytics = handleAnalytics;
    const parsed = queryString.parse(window.location.search);

    const globalContextState = useContext<any>(GlobalContext.Context);
    analyticsSecret = globalContextState?.Configuration?.analyticsTools?.find((at: any) => at.name === "Mixpanel")?.secret;

    useEffect(() => {
        let tempPrograms: any = [];
        if (Boolean(payload)) {
            for (let program of payload.eligibilityProgramResults) {
                tempPrograms.push(renderProgram(refContainer, language, program, analytics, analyticsSecret));
            }
        } else {
            if (refContainer.apis?.length > 0) {
                let apis = refContainer.apis;
                let tempApis = [];
                for (let tempApi of apis) {
                    let rxp = /{(.*)}/;
                    let tempProcessedUrlKey = tempApi.url.match(rxp)[1];
                    let fetchFromStorage = localStorage.getItem(tempProcessedUrlKey);
                    let tempProcessedApi = {
                        url: tempApi.url.replace(rxp, fetchFromStorage),
                        base: {
                            headers: () => ({
                                "Content-Type": "application/json",
                                "Ocp-Apim-Subscription-Key": tempApi.key,
                            })
                        },
                        method: tempApi.method
                    };
                    tempApis.push(tempProcessedApi);
                }
                setProcessedApis(tempApis);
            }
        }
        setPrograms(tempPrograms);
    }, [refContainer, language, payload, analytics]);

    useEffect(() => {
        const handleApis = async () => {
            for (let processedApi of processedApis) {
                let variables = parsed?.formsResponseId ? parsed : null;
                let request = { url: processedApi.url, variables: variables, base: processedApi.base };
                setLoading(true);
                let getApi = await api.get(request);
                setLoading(false);
                let output = [];
                output.push(getApi);
                setApiOutput(output);
            }
        }
        if (!apiOutput) {
            handleApis();
        } else {
            if (programs?.length === 0) {
                let tempPrograms: any = [];
                for (let output of apiOutput) {
                    for (let program of output) {
                        tempPrograms.push(renderProgram(refContainer, language, program, analytics, analyticsSecret));
                    }
                }
                setPrograms(tempPrograms);
            }
        }
    }, [processedApis, parsed, apiOutput, programs, analytics, language, refContainer]);

    return (
        <>
            {
                refContainer && loading ? <Icon name='spinner' type="regular" size='2x' style={spinnerStyle} /> : programs
            }
        </>
    );
};

export default ProgramTemplate;
