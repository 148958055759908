import { useContext, useEffect, useState } from "react";
import { Icon, Line } from "..";
import { handleText } from "../../../utils/helpers";
import WizardStateContext from "../../Library/Wizard/WizardStateProvider";

type Props = {
  name: string;
  error?: any;
  language: string;
  borders: any;
};

const errorElementStyle = {
  marginTop: "15px",
  marginBottom: "20px",
  maxWidth: "550px",
  textIndent: "-36px",
  paddingLeft: "36px",
};

const iconStyle = {
  fontSize: "16pt",
  marginRight: "10px",
  color: "var(--alert-color)",
};

const pageErrorStyle = {
  fontSize: "14pt",
  fontColor: "var(--text-color)",
  lineHeight: "28px",
  fontFamily: "var(--font-family-regular)",
};

const PageError = ({ error, language, borders, name }: Props) => {
  const wizardState = useContext(WizardStateContext.Context);
  const [errorMessage, setErrorMessage] = useState(error);

  useEffect(() => {
    wizardState && wizardState.WizardState && setErrorMessage(wizardState.WizardState.get(name));
  }, [wizardState, name]);

  return (
    <>
      {errorMessage && (
        <>
          { borders && borders.top && <Line /> }
          <p className="pageError" style={errorElementStyle}>
            <span>
              <Icon
                name="exclamation-triangle"
                type={"solid"}
                style={iconStyle}
              />
            </span>
            <span className="errorMessage" style={pageErrorStyle}>{handleText(errorMessage, language)}</span>
          </p>
          { borders && borders.bottom && <Line /> }
        </>
      )}
    </>
  );
};

export default PageError;
