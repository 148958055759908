import React, { useState, useEffect, useCallback, useContext } from "react";
import { useParams, useHistory } from "react-router-dom";
import { api, handleAnalytics } from "../../../utils/helpers";
import GlobalContext from "../../../utils/wrappers/ContextEngine";
import queryString from 'query-string';

const WizardContext = React.createContext<any>(new Map());

const WizardStateProvider = ({ state, children }: any) => {
    const [WizardState, setWizardState] = useState<any>(new Map());
    const [updatedAt, setUpdatedAt] = useState<any>(new Date());
    const [formData, setFormData] = useState<any>(new Map());
    const [sessionId, setSessionId] = useState<any>(null);
    const [notificationApi, setNotificationApi] = useState<any>(null);
    const [stepApi, setStepApi] = useState<any>(null);
    const [resultsApi, setResultsApi] = useState<any>(null);
    const [results, setResults] = useState<any>(null);
    const [currentStep, setCurrentStep] = useState<any>(null);
    const [resendOnce, setResendOnce] = useState(false);
    const [applicants, setApplicants] = useState<any>(new Map());
    const params: any = useParams();
    const solution = params && params.solution;
    const history = useHistory();
    const parsed = queryString.parse(window.location.search);
    const analytics = handleAnalytics;
    const globalContext = useContext(GlobalContext.Context);
    const [globalActions] = useState<any>(globalContext.ContextActions);
    const setLoadingState = globalActions?.get("SetLoadingState");
    const clearAnalyticsId = globalActions?.get("ClearId");
    const analyticsSecret = globalContext?.Configuration?.analyticsTools?.find((at: any) => at.name === "Mixpanel")?.secret;

    useEffect(() => {
        let tempWizardObject = WizardState.entries();
        for (let val of tempWizardObject) {
            localStorage.setItem(val[0], val[1]);
        }
    }, [WizardState, updatedAt]);

    useEffect(() => {
        if (!Boolean(sessionId)) {
            let tempId = localStorage.getItem("id");
            if (tempId) {
                setSessionId(tempId);
            }
            if (parsed?.formsResponseId) {
                setSessionId(parsed.formsResponseId);
            }
        } else {
            WizardState.set("id", sessionId);
            localStorage.setItem("id", sessionId);
            localStorage.setItem("formResponseId", sessionId);
        }
    }, [sessionId, WizardState, parsed]);

    useEffect(() => {
        WizardState.set("results", results);
    }, [results, WizardState]);

    useEffect(() => {
        const config = WizardState.get("wizardConfig");
        if (config) {
            if (config.notificationApi && !notificationApi) {
                let tempNotificationApi = {
                    url: config.notificationApi.url,
                    base: {
                        headers: () => ({
                            "Content-Type": "application/json",
                            "Ocp-Apim-Subscription-Key": config.notificationApi.key,
                        })
                    },
                    method: config.notificationApi.method
                };
                setNotificationApi(tempNotificationApi);
            }
            if (config.stepResponseApi && !stepApi) {
                let tempStepApi = {
                    url: config.stepResponseApi.url,
                    base: {
                        headers: () => ({
                            "Content-Type": "application/json",
                            "Ocp-Apim-Subscription-Key": config.stepResponseApi.key,
                        })
                    },
                    method: config.stepResponseApi.method
                };
                setStepApi(tempStepApi);
            }
            if (config.resultsApi && !resultsApi) {
                let tempResultsApi = {
                    url: config.resultsApi.url + '?solutionId=' + solution,
                    base: {
                        headers: () => ({
                            "Content-Type": "application/json",
                            "Ocp-Apim-Subscription-Key": config.resultsApi.key,
                        })
                    },
                    method: config.resultsApi.method
                };
                setResultsApi(tempResultsApi);
            }
        }
    }, [WizardState, stepApi, resultsApi, notificationApi, solution]);

    useEffect(() => {
        const checkConfig = () => {
            const currentStep = WizardState.get("currentStep");
            const config = WizardState.get("wizardConfig");
            let newFormData = formData;
            if (config) {
                const currentConfig = config.containers[currentStep - 1];
                const checkElements = currentConfig && currentConfig.containers[0].containers[0].elements.filter((e: any) => e.sets);
                checkElements && checkElements.forEach((element: any) => {
                    const data = WizardState.get(element.sets.name);
                    newFormData.set(element.name, data);
                })
            }
            if (newFormData !== formData) {
                setFormData(newFormData);
            }
        }
        checkConfig();
    }, [WizardState, formData, updatedAt]);

    useEffect(() => {
        state && state !== WizardState && setWizardState(state);
    }, [state, WizardState]);

    const setWizardStateValue = useCallback((key: string, val: any) => {
        let newState = WizardState;
        newState.set(key, val);
        setUpdatedAt(new Date());
    }, [WizardState]);

    const deleteWizardStateValue = (key: string) => {
        let tempWizardState = WizardState;
        tempWizardState.delete(key);
        localStorage.removeItem(key);
        setUpdatedAt(new Date());
    };

    const createCleanData = () => {
        let cleanMap = new Map(JSON.parse(JSON.stringify(Array.from(WizardState))));
        cleanMap.delete("wizardConfig");
        cleanMap.delete("totalWizardSteps");
        cleanMap.delete("stepRoutePaths");
        cleanMap.delete("results");
        cleanMap.delete("PageLevelError");
        cleanMap.delete("currentStep");
        let cleanData = Object.fromEntries(cleanMap);
        return cleanData;
    };

    const checkState = (key: string) => {
        let value = WizardState.get(key) || localStorage.getItem(key) || null;
        return value;
    };

    const createEnEData = () => {
        let tempApplicants = new Map(JSON.parse(JSON.stringify(Array.from(applicants))));
        let primaryApplicant = {
            "isPrimary": true,
            "firstName": checkState("applicants['primary'].firstName"),
            "lastName": checkState("applicants['primary'].lastName"),
            "middleName": checkState("applicants['primary'].middleName"),
            "otherName": checkState("applicants['primary'].otherName"),
            "suffix": checkState("applicants['primary'].suffix"),
            "dob": new Date(checkState("applicants['primary'].dob")),
            "addressess": [
                {
                    "type": "home",
                    "street1": checkState("applicants['primary'].addressess['home'].street1"),
                    "street2": checkState("applicants['primary'].addressess['home'].street2"),
                    "city": checkState("applicants['primary'].addressess['home'].city"),
                    "county": checkState("applicants['primary'].addressess['home'].county"),
                    "state": checkState("applicants['primary'].addressess['home'].state"),
                    "zip": checkState("applicants['primary'].addressess['home'].zip"),
                    "isCurrent": true
                },
                {
                    "type": "mailing",
                    "street1": checkState("applicants['primary'].addressess['mailing'].street1") || checkState("applicants['primary'].addressess['home'].street1"),
                    "street2": checkState("applicants['primary'].addressess['mailing'].street2") || checkState("applicants['primary'].addressess['home'].street2"),
                    "city": checkState("applicants['primary'].addressess['mailing'].city") || checkState("applicants['primary'].addressess['home'].city"),
                    "county": checkState("applicants['primary'].addressess['mailing'].county") || checkState("applicants['primary'].addressess['home'].county"),
                    "state": checkState("applicants['primary'].addressess['mailing'].state") || checkState("applicants['primary'].addressess['home'].state"),
                    "zip": checkState("applicants['primary'].addressess['mailing'].zip") || checkState("applicants['primary'].addressess['home'].zip"),
                    "isCurrent": true
                }
            ],
            "gender": checkState("applicants['primary'].gender"),
            "maritalStatus": checkState("applicants['primary'].maritalStatus"),
            "haveSSN": checkState("applicants['primary'].haveSsn"),
            "ssn": checkState("applicants['primary'].ssn"),
            "ethnicities": WizardState.get("applicants['primary'].ethnicities"),
            "isPregnant": checkState("applicants['primary'].isPregnant"),
            "haveHealthCoverage": checkState("applicants['primary'].haveHealthCoverage"),
            "healthCoverage": checkState("applicants['primary'].healthCoverage"),
            "howDidYouLearn": checkState("applicants['primary'].howDidYouLearn"),
            "currentHealthState": checkState("applicants['primary'].currentHealthState"),
            "medicalNotAfford": checkState("applicants['primary'].medicalNotAfford"),
            "emergencyRoom": checkState("applicants['primary'].emergencyRoom"),
            "hospitalizedOvernight": checkState("applicants['primary'].hospitalizedOvernight"),
            "medicalOfficeVisits": checkState("applicants['primary'].medicalOfficeVisits"),
            "health": checkState("applicants['primary'].health"),
            "hospitalER": checkState("applicants['primary'].hospitalER"),
            "hospitalOverNight": checkState("applicants['primary'].hospitalOverNight"),
            "needCare6Months": checkState("applicants['primary'].needCare6Months"),
            "medicalClinic": checkState("applicants['primary'].medicalClinic"),
            "chronicIllness": checkState("applicants['primary'].chronicIllness"),
            "preferredWrittenLanguage": checkState("applicants['primary'].languageWritten"),
            "preferredSpokenLanguage": checkState("applicants['primary'].languageSpoken"),
            "emailAddress": checkState("applicants['primary'].emailAddress"),
            "phoneNumbers": [
                Boolean(checkState("applicants['primary'].phoneNumbers['primary'].number")) ? {
                    "number": checkState("applicants['primary'].phoneNumbers['primary'].number"),
                    "type": checkState("applicants['primary'].phoneNumbers['primary'].type"),
                    "isCell": Boolean(checkState("applicants['primary'].phoneNumbers['primary'].number"))
                } : {},
                Boolean(checkState("applicants['primary'].phoneNumbers['additional'].number")) ? {
                    "number": checkState("applicants['primary'].phoneNumbers['additional'].number"),
                    "type": checkState("applicants['primary'].phoneNumbers['additional'].type"),
                    "isCell": Boolean(checkState("applicants['primary'].phoneNumbers['additional'].number"))
                } : {}
            ],
            "income": [
                Boolean(checkState("applicants['primary'].income['job'].employerName")) ? {
                    "type": "job",
                    "employerName": checkState("applicants['primary'].income['job'].employerName"),
                    "howIsPaid": checkState("applicants['primary'].income['job'].jobIncomeHowIsPaid"),
                    "hourlyRate": parseFloat(checkState("applicants['primary'].income['job'].jobIncomeHourlyRate").replace("$", "") || "0"),
                    "howOftenPaid": checkState("applicants['primary'].income['job'].jobIncomeHowOftenPaid"),
                    "amountPaid": parseFloat(checkState("applicants['primary'].income['job'].jobIncomeAmountPaid") || "0"),
                    "avgHoursPerWeek": checkState("applicants['primary'].income['job'].jobIncomeAvgHoursPerWeek")
                } : {},
                Boolean(checkState("applicants['primary'].selfIncomeWorkType")) ? {
                    "type": "self",
                    "monthlyGrossAmount": parseFloat(checkState("applicants['primary'].selfIncomeMonthlyGrossAmount").replace("$", "")),
                    "workType": checkState("applicants['primary'].selfIncomeWorkType")
                } : {},
                Boolean(checkState("applicants['primary'].otherIncomeSource")) ? {
                    "type": "other",
                    "source": checkState("applicants['primary'].otherIncomeSource"),
                    "howOftenPaid": checkState("applicants['primary'].otherIncomeHowOften"),
                    "howMuchAmount": parseFloat(checkState("applicants['primary'].otherIncomeHowMuchAmount").replace("$", ""))
                } : {},
            ],
            "assetsTotalAmount": checkState("applicants['current'].assetsTotalAmount"),
        };
        let currentApplicant = checkState("applicants['current'].firstName") ? {
            "isPrimary": false,
            "firstName": checkState("applicants['current'].firstName"),
            "lastName": checkState("applicants['current'].lastName"),
            "middleName": checkState("applicants['current'].middleName"),
            "otherName": checkState("applicants['current'].otherName"),
            "suffix": checkState("applicants['current'].suffix"),
            "dob": new Date(checkState("applicants['current'].dob")),
            "addressess": [
                {
                    "type": "home",
                    "street1": checkState("applicants['current'].addressess['home'].street1"),
                    "street2": checkState("applicants['current'].addressess['home'].street2"),
                    "city": checkState("applicants['current'].addressess['home'].city"),
                    "county": checkState("applicants['current'].addressess['home'].county"),
                    "state": checkState("applicants['current'].addressess['home'].state"),
                    "zip": checkState("applicants['current'].addressess['home'].zip"),
                    "isCurrent": true
                },
                {
                    "type": "mailing",
                    "street1": checkState("applicants['current'].addressess['mailing'].street1") || checkState("applicants['current'].addressess['home'].street1"),
                    "street2": checkState("applicants['current'].addressess['mailing'].street2") || checkState("applicants['current'].addressess['home'].street2"),
                    "city": checkState("applicants['current'].addressess['mailing'].city") || checkState("applicants['current'].addressess['home'].city"),
                    "county": checkState("applicants['current'].addressess['mailing'].county") || checkState("applicants['current'].addressess['home'].county"),
                    "state": checkState("applicants['current'].addressess['mailing'].state") || checkState("applicants['current'].addressess['home'].state"),
                    "zip": checkState("applicants['current'].addressess['mailing'].zip") || checkState("applicants['current'].addressess['home'].zip"),
                    "isCurrent": true
                }
            ],
            "gender": checkState("applicants['current'].gender"),
            "maritalStatus": checkState("applicants['current'].maritalStatus"),
            "haveSSN": checkState("applicants['current'].haveSsn"),
            "ssn": checkState("applicants['current'].ssn"),
            "ethnicities": WizardState.get("applicants['current'].ethnicities"),
            "isPregnant": checkState("applicants['current'].isPregnant"),
            "haveHealthCoverage": checkState("applicants['current'].haveHealthCoverage"),
            "healthCoverage": checkState("applicants['current'].healthCoverage"),
            "howDidYouLearn": checkState("applicants['current'].howDidYouLearn"),
            "currentHealthState": checkState("applicants['current'].currentHealthState"),
            "medicalNotAfford": checkState("applicants['current'].medicalNotAfford"),
            "emergencyRoom": checkState("applicants['current'].emergencyRoom"),
            "hospitalizedOvernight": checkState("applicants['current'].hospitalizedOvernight"),
            "medicalOfficeVisits": checkState("applicants['current'].medicalOfficeVisits"),
            "health": checkState("applicants['current'].health"),
            "hospitalER": checkState("applicants['current'].hospitalER"),
            "hospitalOverNight": checkState("applicants['current'].hospitalOverNight"),
            "needCare6Months": checkState("applicants['current'].needCare6Months"),
            "medicalClinic": checkState("applicants['current'].medicalClinic"),
            "chronicIllness": checkState("applicants['current'].chronicIllness"),
            "preferredWrittenLanguage": checkState("applicants['current'].preferredWrittenLanguage"),
            "preferredSpokenLanguage": checkState("applicants['current'].languageSpoken"),
            "emailAddress": checkState("applicants['current'].emailAddress"),
            "phoneNumbers": [
                Boolean(checkState("applicants['current'].phoneNumbers['current'].number")) ? {
                    "number": checkState("applicants['current'].phoneNumbers['current'].number"),
                    "type": checkState("applicants['current'].phoneNumbers['current'].type"),
                    "isCell": Boolean(checkState("applicants['current'].phoneNumbers['current'].number"))
                } : {},
                Boolean(checkState("applicants['current'].phoneNumbers['additional'].number")) ? {
                    "number": checkState("applicants['current'].phoneNumbers['additional'].number"),
                    "type": checkState("applicants['current'].phoneNumbers['additional'].type"),
                    "isCell": Boolean(checkState("applicants['current'].phoneNumbers['additional'].number"))
                } : {}
            ],
            "income": [
                Boolean(checkState("applicants['current'].income['job'].employerName")) ? {
                    "type": "job",
                    "employerName": checkState("applicants['current'].income['job'].employerName"),
                    "howIsPaid": checkState("applicants['current'].income['job'].jobIncomeHowIsPaid"),
                    "hourlyRate": parseFloat(checkState("applicants['current'].income['job'].jobIncomeHourlyRate").replace("$", "") || "0"),
                    "howOftenPaid": checkState("applicants['current'].income['job'].jobIncomeHowOftenPaid"),
                    "amountPaid": parseFloat(checkState("applicants['current'].income['job'].jobIncomeAmountPaid") || "0"),
                    "avgHoursPerWeek": checkState("applicants['current'].income['job'].jobIncomeAvgHoursPerWeek")
                } : {},
                Boolean(checkState("applicants['current'].selfIncomeWorkType")) ? {
                    "type": "self",
                    "monthlyGrossAmount": parseFloat(checkState("applicants['current'].selfIncomeMonthlyGrossAmount").replace("$", "")),
                    "workType": checkState("applicants['current'].selfIncomeWorkType")
                } : {},
                Boolean(checkState("applicants['current'].otherIncomeSource")) ? {
                    "type": "other",
                    "source": checkState("applicants['current'].otherIncomeSource"),
                    "howOftenPaid": checkState("applicants['current'].otherIncomeHowOften"),
                    "howMuchAmount": parseFloat(checkState("applicants['current'].otherIncomeHowMuchAmount").replace("$", ""))
                } : {},
            ],
            "assetsTotalAmount": checkState("applicants['current'].assetsTotalAmount"),
        } : {};
        tempApplicants.set(`primaryApplicant`, primaryApplicant);
        checkState("applicants['current'].firstName") && tempApplicants.set(`${currentApplicant.firstName} ${currentApplicant.middleName} ${currentApplicant.lastName}`, currentApplicant);
        setApplicants(tempApplicants);
        setUpdatedAt(new Date());
        return Array.from(applicants.values());
    };

    const createNewCurrentApplicant = async () => {
        if (checkState("applicants['current'].firstName")) {
            deleteWizardStateValue("applicants['current'].firstName");
            deleteWizardStateValue("applicants['current'].middleName");
            deleteWizardStateValue("applicants['current'].lastName");
            deleteWizardStateValue("applicants['current'].otherName");
            deleteWizardStateValue("applicants['current'].suffix");
            deleteWizardStateValue("applicants['current'].dob");
            deleteWizardStateValue("applicants['current'].addressess['home'].street1");
            deleteWizardStateValue("applicants['current'].addressess['home'].street2");
            deleteWizardStateValue("applicants['current'].addressess['home'].city");
            deleteWizardStateValue("applicants['current'].addressess['home'].county");
            deleteWizardStateValue("applicants['current'].addressess['home'].state");
            deleteWizardStateValue("applicants['current'].addressess['home'].zip");
            deleteWizardStateValue("applicants['current'].addressess['mailing'].street1");
            deleteWizardStateValue("applicants['current'].addressess['mailing'].street2");
            deleteWizardStateValue("applicants['current'].addressess['mailing'].city");
            deleteWizardStateValue("applicants['current'].addressess['mailing'].county");
            deleteWizardStateValue("applicants['current'].addressess['mailing'].state");
            deleteWizardStateValue("applicants['current'].addressess['mailing'].zip");
            deleteWizardStateValue("applicants['current'].gender");
            deleteWizardStateValue("applicants['current'].maritalStatus");
            deleteWizardStateValue("applicants['current'].haveSsn");
            deleteWizardStateValue("applicants['current'].ssn");
            deleteWizardStateValue("applicants['current'].ethnicities");
            deleteWizardStateValue("applicants['current'].isPregnant");
            deleteWizardStateValue("applicants['current'].haveHealthCoverage");
            deleteWizardStateValue("applicants['current'].healthCoverage");
            deleteWizardStateValue("applicants['current'].howDidYouLearn");
            deleteWizardStateValue("applicants['current'].currentHealthState");
            deleteWizardStateValue("applicants['current'].medicalNotAfford");
            deleteWizardStateValue("applicants['current'].emergencyRoom");
            deleteWizardStateValue("applicants['current'].hospitalizedOvernight");
            deleteWizardStateValue("applicants['current'].medicalOfficeVisits");
            deleteWizardStateValue("applicants['current'].health");
            deleteWizardStateValue("applicants['current'].hospitalER");
            deleteWizardStateValue("applicants['current'].hospitalOverNight");
            deleteWizardStateValue("applicants['current'].needCare6Months");
            deleteWizardStateValue("applicants['current'].medicalClinic");
            deleteWizardStateValue("applicants['current'].chronicIllness");
            deleteWizardStateValue("applicants['current'].preferredWrittenLanguage");
            deleteWizardStateValue("applicants['current'].languageSpoken");
            deleteWizardStateValue("applicants['current'].emailAddress");
            deleteWizardStateValue("applicants['current'].phoneNumbers['current'].number");
            deleteWizardStateValue("applicants['current'].phoneNumbers['current'].type");
            deleteWizardStateValue("applicants['current'].phoneNumbers['current'].number");
            deleteWizardStateValue("applicants['current'].phoneNumbers['additional'].number");
            deleteWizardStateValue("applicants['current'].phoneNumbers['additional'].type");
            deleteWizardStateValue("applicants['current'].phoneNumbers['additional'].number");
            deleteWizardStateValue("applicants['current'].income['job'].employerName");
            deleteWizardStateValue("applicants['current'].income['job'].jobIncomeHowIsPaid");
            deleteWizardStateValue("applicants['current'].income['job'].jobIncomeHourlyRate");
            deleteWizardStateValue("applicants['current'].income['job'].jobIncomeHowOftenPaid");
            deleteWizardStateValue("applicants['current'].income['job'].jobIncomeAmountPaid");
            deleteWizardStateValue("applicants['current'].income['job'].jobIncomeAvgHoursPerWeek");
            deleteWizardStateValue("applicants['current'].selfIncomeMonthlyGrossAmount");
            deleteWizardStateValue("applicants['current'].selfIncomeWorkType");
            deleteWizardStateValue("applicants['current'].otherIncomeSource");
            deleteWizardStateValue("applicants['current'].otherIncomeHowOften");
            deleteWizardStateValue("applicants['current'].otherIncomeHowMuchAmount");
            deleteWizardStateValue("applicants['current'].homeAddressSameAsPrimary");
            deleteWizardStateValue("applicants['current'].mailingAddressSameAsHome");
            deleteWizardStateValue("applicants['current'].addPhoneOrEmail");
        }
    };

    const fetchResults = async () => {
        if (resultsApi && sessionId) {
            setLoadingState(true);
            let variables = parsed?.formsResponseId ? parsed : null;
            let url = resultsApi.url.replace('{formResponseId}', sessionId);
            let request = { url: url, variables: variables, base: resultsApi.base };
            let getResults = await api.get(request);
            setResults(getResults);
            setLoadingState(false);
            let sendAnalytics = { "eventName": "Get Results", "type": "onSubmit" };
            analytics(sendAnalytics, getResults, analyticsSecret);
            return getResults;
        }
    };

    const eneStepResponse = async (definedApi: any) => {
        setLoadingState(true);
        let variables: any = {};
        variables.applicants = createEnEData();
        variables.data = createCleanData();
        variables.solutionId = solution;
        variables.id = sessionId ? sessionId : null;
        let request = { url: definedApi.url || '', variables: variables, base: definedApi.base };
        let post = await api.post(request);
        if (!sessionId) {
            setSessionId(post.id);
        }
        setLoadingState(false);
        setWizardStateValue('programApplicationId', post.programApplicationId);
        localStorage.setItem('programApplicationId', post.programApplicationId);
    };

    const sendData = async () => {
        if (stepApi) {
            setLoadingState(true);
            let variables = createCleanData();
            variables.solutionId = solution;
            let request = { url: stepApi.url, variables: variables, base: stepApi.base };
            let post = await api.post(request);
            if (!sessionId) {
                setSessionId(post.id);
            }
            for (const p in post) {
                //If State being set by the API, store that value
                if (post[p] && WizardState.keys().toArray().indexOf(p) > 0) {
                    setWizardStateValue(p, post[p]);
                }
            }
            setLoadingState(false);
            let sendAnalytics = { "eventName": "Send Data", "type": "onSubmit" };
            analytics(sendAnalytics, variables, analyticsSecret);
        }
    };

    const continueAction = async () => {
        const nextStep = WizardState.get("currentStep") + 1;
        const nextStepUrl = WizardState.get("stepRoutePaths")[nextStep];
        await sendData();
        if (nextStepUrl) {
            setWizardStateValue("currentStep", nextStep);
            history.push(`${nextStepUrl}`);
        }
    };

    const cancelAction = () => {
        setSessionId(null);
        clearAnalyticsId();
        WizardState.clear();
        formData.clear();
        localStorage.clear();
        sessionStorage.clear();
        history.push(`/${solution}`);
    };

    const clearAction = () => {
        setSessionId(null);
        clearAnalyticsId();
        localStorage.clear();
        sessionStorage.clear();
    };

    const submitAction = async () => {
        await sendData();
        await fetchResults();
        history.push('result');
    };

    const saveResultsAction = () => {
        history.push("SaveResults");
    };

    const sendResultsAction = useCallback(async (resend?: boolean) => {
        if (notificationApi) {
            const config = WizardState.get("wizardConfig");
            const variables = {
                deliveryType: WizardState.get("saveResultsOptions") || localStorage.getItem("saveResultsOptions"),
                destination: WizardState.get("destinationAddress") || localStorage.getItem("destinationAddress"),
                formsResponseId: sessionId,
                solutionId: solution,
                resultsPageUrl: 'https://' + window.location.hostname + '/' + solution + '/' + config.name + '/ViewResults'
            };
            let request = { url: notificationApi.url, variables: variables, base: notificationApi.base };
            setWizardStateValue("PageLevelError", null);
            try {
                setLoadingState(true);
                let result = await api.post(request);
                if (result && !resend) history.push("SendConfirmation");
                setLoadingState(false);
            } catch (error) {
                let newError = { "type": "Universal", "content": [{ "language": "en-us", "text": error }] };
                setWizardStateValue("PageLevelError", newError);
            }
        }
    }, [WizardState, history, notificationApi, sessionId, solution, setWizardStateValue, setLoadingState]);

    useEffect(() => {
        let tempStep = WizardState.get("currentStep");
        if (currentStep !== tempStep) setCurrentStep(tempStep);
        if (tempStep === 'ResendConfirmation' && !resendOnce) {
            sendResultsAction(true);
            setResendOnce(true);
        }
    }, [currentStep, WizardState, sendResultsAction, resendOnce]);

    const getFormValuesAtInstance = () => {
        const wizardConfig = WizardState.get("wizardConfig");
        const formElement: any = document.getElementById('wizardForm' + wizardConfig.name);
        const newFormData: any = new FormData(formElement);
        const formValues: any = {};
        for (const pair of newFormData.entries()) {
            formValues[pair[0]] = pair[1];
        }
        return formValues;
    };

    const WizardActions = new Map();
    WizardActions.set("Continue", continueAction);
    WizardActions.set("Cancel", cancelAction);
    WizardActions.set("Submit", submitAction);
    WizardActions.set("SaveResults", saveResultsAction);
    WizardActions.set("SendResults", sendResultsAction);
    WizardActions.set("setWizardStateValue", setWizardStateValue);
    WizardActions.set("FormValuesAtInstance", getFormValuesAtInstance);
    WizardActions.set("FormData", formData);
    WizardActions.set("fetchResults", fetchResults);
    WizardActions.set("CreateCleanData", createCleanData);
    WizardActions.set("EnEStepResponse", eneStepResponse);
    WizardActions.set("Clear", clearAction);
    WizardActions.set("NewCurrentApplicant", createNewCurrentApplicant);

    const value = {
        WizardState: WizardState,
        WizardActions: WizardActions,
        FormData: formData,
        updatedAt: updatedAt,
    };


    return (
        <WizardContext.Provider value={value}>{children}</WizardContext.Provider>
    );
};

const WizardStateContext = {
    Provider: WizardStateProvider,
    Consumer: WizardContext.Consumer,
    Context: WizardContext
}

export default WizardStateContext;
