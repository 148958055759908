import { useEffect, useState } from "react";
import classes from "./Card.module.scss";

type Props = {
  id?: string;
  children?: any;
  card?: undefined
  |
  { type?: string, position?: string };
};

const Card = ({ id, children, card }: Props) => {
  const [cardType, setCardType] = useState("");
  const [cardPosition, setCardPosition] = useState({});

  useEffect(() => {
    if (card && card.type) {
      setCardType(card.type);
    }

    if (card && card.position) {
      setCardPosition({
        textAlign: card.position
      });
    }
  }, [card]);

  return <div id={"cardPositionContainer"} style={cardPosition}>
    <div id={`${id}Card`} className={classes[cardType] + ' card ' + cardType + ' ' + classes.card}>{children}</div>
  </div>;
};

export default Card;
