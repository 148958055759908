interface TextInterface {
    type: string,
    content: [{
        language: string,
        text: string
    }]
}

const handleText = (text: TextInterface, lang: string) => {
    switch (text.type) {
        case ('Constant'):
            let textContent: any = text.content.find(text => text.language.toLowerCase() === lang.toLowerCase());
            return textContent.text;
        case ('Universal'):
            return text.content[0].text;
        default:
            return null;
    }
};

export default handleText;