import { useCallback, useContext } from "react";
import {
  useStepConfigState,
  useStepConfigUpdater,
  WizardStateContext
} from "../../components/Library/";
import { handleConditionals } from './';

const isRequiredField = (validations: any) => {
  let requiredValidation = validations.find(
    (validation: any) =>
      validation.type === "Required" &&
      validation.value.toLowerCase() === "true"
  );
  return {
    value: !!requiredValidation,
    message: requiredValidation?.onError || null,
  };
}

function useFormValidation() {
  const stepConfigState = useStepConfigState();
  const setStepConfig = useStepConfigUpdater();
  const wizardState = useContext(WizardStateContext.Context);

  return useCallback(() => {

    const currentStep = wizardState.WizardState.get("currentStep");
    const currentStepConfigElements = stepConfigState[currentStep];
    let isValid = true;
    for (const elementName in currentStepConfigElements) {
      const element = currentStepConfigElements[elementName];
      const conditionalRender = handleConditionals(element.conditionals, wizardState);
      if (element.isRequiredOnSubmit && element.isRequiredOnSubmit.value && conditionalRender.conditionalRender) {
        const formValues: any = wizardState.WizardActions.get("FormValuesAtInstance")();
        const altFormValues: any = wizardState.FormData;
        const altElementValue = altFormValues.get(element.name);
        const elementValue = formValues[element.name] || altElementValue;
        switch (element.type) {
          case "OptionList":
          case "Radio":
            if (element.options.length > 1 && (elementValue === "" || elementValue === undefined || elementValue === null)) {
              element.error = element.isRequiredOnSubmit.message;
              isValid = false;
            }
            break;

          case "InputText":
          case "MoneyInputText":
            if (
              element.isValidInput === false ||
              elementValue === undefined ||
              elementValue === null ||
              elementValue === ""
            ) {
              element.error = element.isRequiredOnSubmit.message;
              isValid = false;
            }
            break;
          case "Multiselect":
            if (element.options.length > 1 && (altElementValue?.length === 0 || altElementValue === undefined || altElementValue === null)) {
              element.error = element.isRequiredOnSubmit.message;
              isValid = false;
            }
            break;
          default:
            break;
        }
      }
      if (element.type === "PageError") {
        const pageError = wizardState.WizardState.get(element.name);
        if (pageError) {
          isValid = false;
        }
      }
    }
    setStepConfig(currentStep, currentStepConfigElements);
    return isValid;
  }, [stepConfigState, wizardState, setStepConfig]);
}

export { useFormValidation, isRequiredField };
