import { useState } from 'react';
import Autosuggest from 'react-autosuggest';
import { handleText, staticContent } from '../../../utils/helpers';
import { WizardLabel, FieldMessage, Help } from '..';
import './AutoComplete.css';

type Props = {
    options: any[];
    language: string;
    name: any;
    label: any;
    isRequired?: boolean;
    regex?: any;
    helpInfo?: any;
    errorMessage?: any;
    placeholder?: any;
    value: any;
    handleOnChange: any;
};

const suggestionStyle = {
    width: '366px',
    padding: '7px'
}

const BaseComplete = ({
    options,
    language,
    name,
    label,
    isRequired,
    regex,
    helpInfo,
    errorMessage,
    placeholder,
    value,
    handleOnChange
}: Props) => {
    const [suggestions, setSuggestions] = useState<any>([]);

    const getSuggestions = (value: any) => {
        const inputValue = value.trim().toLowerCase();
        const inputLength = inputValue.length;

        return inputLength === 0 ? [] : options.filter(option =>
            handleText(option.label, language).toLowerCase().slice(0, inputLength) === inputValue
        );
    };

    const getSuggestionValue = (suggestion: any) => handleText(suggestion.label, language);

    const renderSuggestion = (suggestion: any) => (
        <div style={suggestionStyle}>
            {handleText(suggestion.label, language)}
        </div>
    );

    const onChange = (event: any, { newValue }: any) => {
        if (event?.target.innerHtml === newValue) {
            newValue = event.target.innerHtml;
        }
        if (regex) {
            const regEx = new RegExp(regex);
            newValue = newValue.replace(regEx, '');
        }
        handleOnChange(newValue);
    }

    const onSuggestionsFetchRequested = ({ value }: any) => {
        setSuggestions(getSuggestions(value));
    };

    const onSuggestionsClearRequested = () => {
        setSuggestions([]);
    };

    const inputProps = {
        placeholder: placeholder,
        value,
        onChange: onChange
    };

    const renderInputComponent = (inputProps: any) => (
        <div>
            <input {...inputProps} aria-label={'Auto suggests options for ' + name} />
        </div>
    )

    return (
        <div className='autocomplete'>
            <WizardLabel
                name={name}
                required={isRequired}
                requiredLabel={handleText(staticContent.required, language)}
                label={label}
                language={language}
            />
            <Autosuggest
                id={name}
                suggestions={suggestions}
                onSuggestionsFetchRequested={onSuggestionsFetchRequested}
                onSuggestionsClearRequested={onSuggestionsClearRequested}
                getSuggestionValue={getSuggestionValue}
                renderSuggestion={renderSuggestion}
                inputProps={inputProps}
                renderInputComponent={renderInputComponent}
            />
            {helpInfo && <Help helpInfo={helpInfo} language={language} id={name} />}
            {errorMessage && (
                <FieldMessage
                    messageType="error"
                    messageText={errorMessage}
                    language={language}
                />
            )}
        </div>
    );
};

export default BaseComplete;