import { isFunction } from 'lodash';
import PropTypes from 'prop-types';

const defaultStyle = {
    paddingTop: '10px',
    paddingBottom: '10px',
}

type Props = {
    onSelect?: VoidFunction,
    style?: object,
    actionMenu?: boolean,
    children: any
}

const MenuItem = ({ onSelect, style, actionMenu = false, children }: Props) => {
    const handleClick = () => {
        if (isFunction(onSelect)) {
            onSelect();
        }
    }

    const handleKeyPress = (e: any) => {
        if (e.key === 'Enter') {
            if (isFunction(onSelect)) {
                onSelect();
            }
        }
    }

    return (
        <div
            tabIndex={0}
            style={{ ...defaultStyle, ...style }}
            className={actionMenu ? 'menuActionItems' : 'menuItems'}
            onKeyPress={handleKeyPress}
            onClick={handleClick}>
            {children}
        </div>
    );
};

MenuItem.propTypes = {
    onSelect: PropTypes.any,
    style: PropTypes.object,
    actionMenu: PropTypes.bool,
    children: PropTypes.any
};

export default MenuItem;