import { useState, useEffect, useContext } from "react";
import RenderElement from "../RenderElement/RenderElement";
import { Card, Navigation, Loader } from "../../Library";
import classes from "./Page.module.scss";
import { useWindowWidth, handleAnalytics, handleText } from "../../../utils/helpers";
import GlobalContext from "../../../utils/wrappers/ContextEngine";
import { Helmet } from 'react-helmet';

type Props = {
  pageConfig: any;
  language: string;
};

const Page = ({ pageConfig, language }: Props) => {
  const containers = pageConfig.containers;

  const globalContextState = useContext(GlobalContext.Context);
  const [globalActions] = useState<any>(globalContextState.ContextActions);
  const setGlobalValue =
    globalActions && globalActions.get("setContextStateValue");
  const isMobile = useWindowWidth() <= 575;
  const analytics = handleAnalytics;
  const loading = globalContextState.loading;
  const analyticsSecret = globalContextState?.Configuration?.analyticsTools?.find((at: any) => at.name === "Mixpanel")?.secret;

  useEffect(() => {
    analytics(pageConfig.analytics && pageConfig.analytics, null, analyticsSecret);
  }, [pageConfig, analytics, analyticsSecret]);

  useEffect(() => {
    let metaFooter = pageConfig.metaFooter || { base: true };
    setGlobalValue && setGlobalValue("metaFooter", metaFooter);
  }, [pageConfig, setGlobalValue]);

  const renderInnerContainers = (container: any) => {
    return container.containers.map((innerContainer: any) => {
      return innerContainer.type === "Section" ? (
        <Card
          id={innerContainer.name}
          key={innerContainer.name}
          card={innerContainer.card}
        >
          {innerContainer.elements.map((element: any) => {
            return (
              <RenderElement
                key={element.name}
                element={element}
                language={language}
                container={innerContainer}
              />
            );
          })}
        </Card>
      ) : innerContainer.type === "NavSection" ? (
        <Navigation
          id={innerContainer.name}
          key={innerContainer.name}
          elements={innerContainer.elements}
          language={language}
        ></Navigation>
      ) : null;
    });
  };

  return containers.map((container: any) => {
    return (
      <main
        id={container.name}
        role="main"
        className={container.backgroundImage ? classes.mainContainer : ""}
        key={container.name}
        style={{
          backgroundImage: isMobile
            ? "unset"
            : `url(${container.backgroundImage})`,
        }}
      >
        <Helmet>
          <title>{handleText(pageConfig.metaTitle, language)}</title>
        </Helmet>
        {loading ? <Loader /> : renderInnerContainers(container)}
      </main>
    );
  });
};

export default Page;
