// insert new static terms into below terms object with it's respective language translation

const terms: any = {
  required: {
    "en-us": "required",
    es: "Requerido contestar",
  },
  step: {
    "en-us": "Step",
    es: "Paso",
  },
  of: {
    "en-us": "of",
    es: "de",
  },
  hints: {
    "en-us": "Don't forget",
    es: "Sugerencias",
  },
  close: {
    "en-us": "Close",
    es: "Cerca",
  },
  selectoneormore: {
    "en-us": "Select one or more",
    es: "Seleccione uno o más",
  }
};

const staticContent: any = {};

const formatContent = (content: any) => {
  const data: any = [];
  Object.keys(content).forEach((key) => {
    data.push({
      language: key,
      text: content[key],
    });
  });
  return data;
};

Object.keys(terms).forEach((key) => {
  staticContent[key] = {
    type: "Constant",
    content: formatContent(terms[key]),
  };
});

export default staticContent;
