import { useContext, useCallback } from "react";
import WizardStateContext from "../../components/Library/Wizard/WizardStateProvider";
import {
  useStepConfigState,
  useStepElementConfigUpdater,
} from "../../components/Library/Wizard/StepConfigContext";

const ELEMENT_NAME = "Hint";

const useSetHint = () => {
  const stepConfigState = useStepConfigState();
  const setStepElementConfig = useStepElementConfigUpdater();
  const wizardState = useContext(WizardStateContext.Context);
  const currentStep = wizardState.WizardState.get("currentStep");
  const hintElement = stepConfigState[currentStep] && stepConfigState[currentStep][ELEMENT_NAME];

  return useCallback(
    (hint: any) => {
      if (!hintElement["items"].find( (item: any) => item.hintFor && item.hintFor === hint.hintFor )) {
        hintElement["items"].push(hint);
      }
      setStepElementConfig(currentStep, ELEMENT_NAME, hintElement);
    },
    [hintElement, currentStep, setStepElementConfig]
  );
}

const useRemoveHint = () => {
  const stepConfigState = useStepConfigState();
  const setStepElementConfig = useStepElementConfigUpdater();
  const wizardState = useContext(WizardStateContext.Context);
  const currentStep = wizardState.WizardState.get("currentStep");
  const hintElement = stepConfigState[currentStep] && stepConfigState[currentStep][ELEMENT_NAME];

  return useCallback(
    (elementName: string) => {
      if (hintElement && hintElement["items"]) {
        hintElement["items"] = hintElement["items"].filter(
          (hint: any) => hint.hintFor !== elementName
        );
        setStepElementConfig(currentStep, ELEMENT_NAME, hintElement);
      }
    },
    [hintElement, currentStep, setStepElementConfig]
  );
}

export { useSetHint, useRemoveHint };
