import {
  Line,
  Text,
  Input,
  PageError,
  RadioList,
  Hints,
  OptionList,
  Image,
  Navigation,
  WizardItemList,
  MultiSelect,
  ColumnLayout,
  ProgramTemplate,
  AddressTemplate,
  Accordion,
  ScrollPanel,
  WizardTitle,
  WizardText,
  DataTable
} from "../";

type Props = {
  element: any;
  language: string;
  container?: any;
  payload?: any;
};

const RenderElements = ({ element, language, container, payload }: Props) => {
  return (
    <>
      {element.type === "OptionList" && (
        <OptionList
          elementConfig={element}
          language={language}
        />
      )}
      {element.type === "Title" && (
        <WizardTitle name={element.name} title={element.text} language={language} {...element}></WizardTitle>
      )}
      {element.type === "Line" && <Line name={element.name} />}
      {element.type === "Text" && (
        <Text name={element.name} text={element.text} language={language} {...element}></Text>
      )}
      {element.type === "FormattedText" && (
        <WizardText name={element.name} text={element.text} language={language} payload={payload} givenClass={element.class} {...element}></WizardText>
      )}
      {element.type === "PageError" && (
        <PageError
          name={element.name}
          error={null}
          language={language}
          borders={element.borders}
        ></PageError>
      )}
      {["InputText", "MoneyInputText"].includes(element.type) && (
        <Input
          change={element.sets}
          label={element.label}
          name={element.name}
          placeHolder={element.placeHolder}
          language={language}
          error={element.error}
          {...element}
        />
      )}
      {
        element.type === 'Multiselect' && (
          <MultiSelect
            language={language}
            elementConfig={element}
          />
        )
      }
      {element.type === "Radio" && (
        <RadioList
          sets={element.sets}
          label={element.label}
          name={element.name}
          language={language}
          optionMeta={element.options}
          error={element.error}
          {...element}
        />
      )}
      {element.type === "Image" && (
        <Image
          name={element.name}
          src={element.image}
          text={element.text}
          language={language}
          props={element}
        />
      )}
      {element.type === "Hint" && (
        <Hints name={element.name} language={language} {...element} />
      )}
      {element.type === 'Column' && (
        <ColumnLayout
          name={element.name}
          reference={element.ref}
          language={language}
          container={container}
        />
      )}
      {element.type === 'Accordion' && (
        <Accordion
          name={element.name}
          reference={element.ref}
          language={language}
          container={container}
          collapsed={element.collapsed}
          collapsible={element.collapsible}
          text={element.text}
        />
      )}
      {element.type === 'ScrollPanel' && (
        <ScrollPanel
          name={element.name}
          reference={element.ref}
          language={language}
          container={container}
        />
      )}
      {
        element.type === 'ProgramTemplate' && (
          <ProgramTemplate
            name={element.name}
            reference={element.ref}
            language={language}
            container={container}
            payload={payload}
          />
        )
      }
      {
        element.type === 'AddressTemplate' && (
          <AddressTemplate
            name={element.name}
            reference={element.ref}
            language={language}
            container={container}
            payload={payload}
          />
        )
      }
      {element.type === "Button" && (
        <Navigation id={element.name} key={"IntroNavButton"} elements={[element]} language={language} type={"inline"}></Navigation>
      )}
      {element.type === "ItemList" && (
        <WizardItemList
          name={element.name}
          items={element.items}
          columnCount={element.columnCount}
          style={element.style}
          language={language}
          {...element}
        />
      )}
      {
        element.type === "Table" && (
          <DataTable
            element={element}
            reference={element.ref}
            language={language}
            container={container}
            payload={payload}
          />
        )
      }
    </>
  );
};

export default RenderElements;
