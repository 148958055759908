import { useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";
import { Route, Switch, Redirect } from "react-router";
import classes from "./Main.module.scss";
import { Page, Wizard, WizardStateContext, StepsConfigProvider } from "../Library";
import { useParams } from "react-router-dom";
import GlobalContext from "../../utils/wrappers/ContextEngine";

type Props = {
  mainConfig: Array<any> | undefined;
  defaultContainer: string | undefined;
  language: string;
};

const Main = ({ mainConfig, defaultContainer, language }: Props) => {
  const { solution }: any = useParams();
  const [routes, setRoutes] = useState<any>([]);
  const globalContextState = useContext(GlobalContext.Context);
  const [globalActions] = useState<any>(globalContextState.ContextActions);
  const setGlobalValue =
    globalActions && globalActions.get("setContextStateValue");

  useEffect(() => {
    const mapContainers = (containers: any) => {
      let newRoutes = [];
      for (let container of containers) {
        let route = (
          <Route path={`/:solution/${container.name}`} key={container.name}>
            <div className={classes.cardContainer}>
              {container.type === "Page" ? (
                <Page pageConfig={container} language={language} />
              ) : null}
              {container.type === "Wizard" ? (
                <WizardStateContext.Provider>
                  <StepsConfigProvider>
                    <Wizard wizardConfig={container} language={language} />
                  </StepsConfigProvider>
                </WizardStateContext.Provider>
              ) : null}
            </div>
          </Route>
        );
        newRoutes.push(route);
      }
      setRoutes(newRoutes);
    };
    mapContainers(mainConfig);
  }, [mainConfig, language]);

  useEffect(() => {
    setGlobalValue && setGlobalValue("solution", solution);
  }, [solution, setGlobalValue]);

  return (
    <Switch>
      {routes}
      <Route
        exact
        path="/:solution/"
        render={() => <Redirect to={`/${solution}/${defaultContainer}`} />}
      />
    </Switch>
  );
};

Main.propTypes = {
  containers: PropTypes.array,
  defaultContainer: PropTypes.string,
  language: PropTypes.string,
};

export default Main;
