import { useState, useContext } from "react";
import { WizardStateContext } from "../";
import FormattedText from './FormattedText';
import { useConditionals } from "../../../utils/helpers";

interface CardTitleInterface {
    type: string,
    content: [{
        language: string,
        text: string
    }],
    sets?: any
};

type Props = {
    name?: string,
    text: CardTitleInterface,
    language: string,
    passedStyle?: any,
    conditionals?: any,
    payload?: any
};

const checkState = (text: any, state: any) => {
    let newText = Object.assign({}, text);
    let matches = [];
    for (let i = 0; i < text.sets.name.length; i++) {
        let indexA = text.sets.name.indexOf("<", i) + 1;
        let indexB = text.sets.name.indexOf(">", i);
        let match = text.sets.name.slice(indexA, indexB);
        matches.push(match);
        i = indexB;
    }
    for (let match of matches) {
        let value = state.WizardState.get(match) || localStorage.getItem(match);
        let replace = "<" + match + ">";
        newText.content[0].text = newText.content[0].text.replace(replace, value ? value : "");
    }
    return newText;
};

const WizardText = ({ name, text, language, passedStyle, conditionals, payload, ...props }: Props) => {
    const handleConditionals = useConditionals(conditionals);
    const wizardState = useContext(WizardStateContext.Context);
    const sets = text.sets || null;
    const [value] = useState(sets ? checkState(text, wizardState) : null);

    return (
        <>
            {
                handleConditionals.render &&
                <FormattedText
                    name={name}
                    text={sets ? value : text}
                    language={language}
                    passedStyle={passedStyle}
                    payload={payload}
                    {...props}
                />
            }
        </>
    )
}

export default WizardText;