import { CheckList, ComboBox } from "..";

type Props = {
    elementConfig: any;
    language: string;
};

const MultiSelect = ({ elementConfig, language }: Props) => {
    let renderElement = null;
    const [checkbox, combobox] = ["Checkbox", "Combobox"];
    if (
        elementConfig.typeSuggestion &&
        [checkbox, combobox].includes(elementConfig.typeSuggestion)
    ) {
        renderElement = elementConfig.typeSuggestion;
    } else {
        renderElement = elementConfig.options?.length > 6 ? checkbox : combobox;
    }

    return (
        <>
            {renderElement === checkbox && (
                <CheckList
                    error={elementConfig.error}
                    label={elementConfig.label}
                    language={language}
                    name={elementConfig.name}
                    optionMeta={elementConfig.options}
                    sets={elementConfig.sets}
                    {...elementConfig}
                />
            )}
            {renderElement === combobox && (
                <ComboBox
                    error={elementConfig.error}
                    label={elementConfig.label}
                    language={language}
                    name={elementConfig.name}
                    optionMeta={elementConfig.options}
                    sets={elementConfig.sets}
                    {...elementConfig}
                />
            )}
        </>
    );
};

export default MultiSelect;