import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { handleText } from "../../../utils/helpers";
import classes from "./Link.module.scss";
import { Link } from "react-router-dom";
import { useParams } from "react-router";

type Props = {
  link: {
    preText?: any;
    linkText: any;
    linkUrl: string;
    link: any;
    postText: any;
    openTo?: string;
  };
  linkId?: string
  language: string;
};

const LinkText = ({ link, linkId, language }: Props) => {
  const params: any = useParams();
  const solution: string = params.solution;
  const [linkText, setLinkText] = useState("");

  useEffect(() => {
    if (link && link.linkText && Boolean(language)) {
      setLinkText(handleText(link.linkText, language));
    }
  }, [link, language]);

  const linkElement = (link: Props["link"]) => {
    const element = <>{linkText}</>;
    if (!(link.link && link.link.type)) {
      return element;
    }
    switch (link.link.type.toLowerCase()) {
      case "absolute":
        return (
          link.link.url === "" ? <strong className={classes.plainLink}>{linkText}</strong> :
            <a id={linkId} className={classes.link} href={link.link.url} target={link.link.openTo || "_blank"} rel="noreferrer" title={linkText}>
              {linkText}
            </a>
        );

      case "relative":
        return (
          <Link
            id={linkId}
            to={"/" + solution + "/" + link.link.url}
            className={classes.link}
            title={linkText}
            target={link.link.openTo || "_blank"}
          >
            {linkText}
          </Link>
        );

      default:
        return element;
    }
  };

  return (
    <span className={classes.linkContainer}>
      {link &&
        Boolean(link.preText) &&
        handleText(link.preText, language) + " "}
      {linkElement(link)}
      {link &&
        Boolean(link.postText) &&
        " " + handleText(link.postText, language)}
    </span>
  );
};

LinkText.propTypes = {
  link: PropTypes.object,
  language: PropTypes.string,
};

export default LinkText;
