import classes from "./Input.module.scss";
import { handleText, staticContent } from "../../../utils/helpers";
import { WizardLabel, FieldMessage, Help } from "../";

type Props = {
  autoFocus?: boolean;
  name: string;
  label: any;
  placeHolder: any;
  language: string;
  isRequired?: boolean;
  errorMessage?: any;
  value: any;
  handleChange?: any;
  handleOnFocus?: any;
  handleBlur?: any;
  isFocus?: boolean;
  helpInfo?: any;
};

let inputErrorStyle = {
  padding: "8px",
  borderColor: "var(--alert-color)",
  borderWidth: "3px",
  backgroundColor: "var(--background-color2)",
};

const formElement = {
  marginBottom: "var(--formField-margin-bottom)",
};

const BaseInput = ({
  label,
  autoFocus,
  name,
  placeHolder,
  language,
  handleChange,
  handleOnFocus,
  handleBlur,
  errorMessage,
  value,
  isRequired = false,
  isFocus = false,
  helpInfo,
}: Props) => {
  return (
    <div className={"inputField " + classes.formField}>
      <WizardLabel
        name={name}
        required={isRequired}
        requiredLabel={handleText(staticContent.required, language)}
        label={label}
        language={language}
      />
      <input
        type="text"
        autoFocus={autoFocus}
        onChange={handleChange}
        onFocus={handleOnFocus}
        onBlur={handleBlur}
        name={name}
        id={name}
        value={value}
        placeholder={handleText(placeHolder, language)}
        style={errorMessage ? (!isFocus ? inputErrorStyle : {}) : formElement}
      />
      {helpInfo && <Help helpInfo={helpInfo} language={language} id={name} />}
      {errorMessage && (
        <FieldMessage
          messageType="error"
          messageText={errorMessage}
          language={language}
        />
      )}
    </div>
  );
};

export default BaseInput;
