import axios, { Method } from "axios";

const defaults = {
  baseURL: process.env.REACT_APP_API_URL,
  headers: () => ({
    "Content-Type": "application/json",
    "Ocp-Apim-Subscription-Key": process.env.REACT_APP_API_KEY,
  }),
  errorMessage:
    "Something went wrong. Please check your internet connection or contact our support.",
  httpCodes: {
    Ok: 200,
    Created: 201,
    Accepted: 202,
    NoContent: 204,
    BadRequest: 400,
    Unauthorized: 401,
    Forbidden: 403,
    NotFound: 404,
    MethodNotAllowed: 405,
    NotAcceptable: 406,
    RequestTimeout: 408,
    TooManyRequests: 429,
    InternalServerError: 500,
    ServiceUnavailable: 503,
    GatewayTimeout: 504,
  },
};

const api = async (method: Method, url: string, variables: any, base?: any) => {
  try {
    const { data } = await axios({
      url: `${base ? base.baseUrl ? base.baseUrl : '' : defaults.baseURL}${url}`,
      method,
      headers: base ? base.headers() : defaults.headers(),
      params: method === "get" ? variables : undefined,
      data: method !== "get" ? variables : undefined,
    });
    return data;
  } catch (error: any) {
    const { httpCodes, errorMessage } = defaults;
    if (!error?.response) {
      alert(errorMessage);
      throw new Error(errorMessage);
    }
    const { status, data } = error.response;
    const error5xx = [
      httpCodes.InternalServerError,
      httpCodes.ServiceUnavailable,
      httpCodes.GatewayTimeout,
    ];
    if (status === httpCodes.Unauthorized) {
      alert("Access Denied");
    }
    if (error5xx.includes(status)) {
      alert(errorMessage);
    }
    throw data;
  }
};

interface PropTypes {
  url: string;
  variables?: any;
  base?: any;
}

const apiMethods = {
  get: ({ url, variables, base }: PropTypes) => api("get", url, variables, base),
  post: ({ url, variables, base }: PropTypes) => api("post", url, variables, base),
  put: ({ url, variables, base }: PropTypes) => api("put", url, variables, base),
  patch: ({ url, variables, base }: PropTypes) => api("patch", url, variables, base),
  delete: ({ url, variables, base }: PropTypes) => api("delete", url, variables, base),
};

export default apiMethods;
