import { useMemo } from "react";
import Table from "./Table";

const TestTable = () => {
    const columns = useMemo(() => [
        {
            Header: 'Test',
            accessor: 'test'
        }, {
            Header: 'Info',
            accessor: 'info'
        }
    ], []);

    const data = useMemo(() => [
        {
            test: 'test',
            info: 'info'
        },
        {
            test: 'test two',
            info: 'info two'
        }
    ], []);

    return (
        <Table columns={columns} data={data}></Table>
    );

};

export default TestTable;