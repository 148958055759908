import { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { merge } from 'lodash';

type Props = {
    isOpen: boolean,
    closeMenu: VoidFunction,
    openMenu: VoidFunction,
    name: string,
    text: any,
    children: any,
    styles?: {
        style?: any,
        menuStyle?: any,
        triggerChildVisibleStyle?: any,
        triggerChildHiddenStyle?: any,
    }
}

const MenuTrigger = ({ isOpen, closeMenu, openMenu, name, text, children, styles }: Props) => {
    const wrapperRef = useRef<HTMLDivElement>();
    const contentRef = useRef<HTMLDivElement>();

    const setWrapperRef = (node: any) => {
        wrapperRef.current = node;
    };

    const setContentRef = (node: any) => {
        contentRef.current = node;
    };

    const handleClick = () => {
        if (isOpen) {
            closeMenu();
        } else {
            openMenu();
        }
    };

    const handleKeyPress = (e: any) => {
        if (e.key === 'Enter') {
            if (isOpen) {
                closeMenu();
            } else {
                openMenu();
            }
        }
    };

    const handleClickOutside = (e: any) => {
        if (wrapperRef.current && !wrapperRef.current.contains(e.target)) {
            if (contentRef.current && !contentRef.current.contains(e.target)) {
                closeMenu();
            }
        }
    };

    useEffect(() => {
        document.addEventListener('click', handleClickOutside, true);
        document.addEventListener('blur', handleClickOutside, true);
        return () => {
            document.removeEventListener('click', handleClickOutside, true);
            document.removeEventListener('blur', handleClickOutside, true);
        };
    });

    const style = (childVisible: boolean) => {
        if (childVisible) {
            return {
                marginBottom: '0',
                marginTop: '0',
                backgroundColor: 'var(--shadow-color)',
                outline: 'none',
                border: 'none',
                width: '100%',
                ...(styles ? styles.triggerChildVisibleStyle : {})
            };
        }

        return {
            marginBottom: '0px',
            marginTop: '0px',
            width: '100%',
            ...(styles ? styles.triggerChildHiddenStyle : {})
        };
    };

    return (
        <div role="tablist" ref={setWrapperRef} className='menuTrigger' onKeyPress={handleKeyPress} onClick={handleClick} style={styles && styles.menuStyle}>
            <h4 role="tab" tabIndex={0} style={style(isOpen)} aira-labelledby="menuwrapper" >{text}</h4>
            {
                isOpen && wrapperRef.current &&
                <h4 role="menu" ref={setContentRef} className='itemContainer optionList' aira-labelledby="menu" style={merge({},
                    {
                        zIndex: 1,
                        position: 'absolute',
                        top: Math.round(wrapperRef.current.getBoundingClientRect().top + 35 + window.pageYOffset) > 65 ? Math.round(wrapperRef.current.getBoundingClientRect().top + 35 + window.pageYOffset) : '65px',
                        left: Math.round(wrapperRef.current.getBoundingClientRect().left + 1)
                    },

                    styles && styles.style)} id={name}>
                    {children}
                </h4>
            }
        </div>
    );
}

MenuTrigger.propTypes = {
    isOpen: PropTypes.bool,
    closeMenu: PropTypes.func,
    openMenu: PropTypes.func,
    name: PropTypes.string,
    text: PropTypes.any,
    children: PropTypes.any,
    styles: PropTypes.object
};

export default MenuTrigger;