import { DropDownList, RadioList, AutoComplete } from "../";

type Props = {
  elementConfig: any;
  language: string;
};

const OptionList = ({ elementConfig, language }: Props) => {
  let renderElement = null;
  const [dropdown, radio, autocomplete] = ["DropDown", "Radio", "Autocomplete"];
  if (
    elementConfig.typeSuggestion &&
    [dropdown, radio, autocomplete].includes(elementConfig.typeSuggestion)
  ) {
    renderElement = elementConfig.typeSuggestion;
  } else {
    renderElement = elementConfig.options?.length > 6 ? dropdown : radio;
  }

  return (
    <>
      {renderElement === dropdown && (
        <DropDownList
          error={elementConfig.error}
          label={elementConfig.label}
          language={language}
          name={elementConfig.name}
          options={elementConfig.options}
          {...elementConfig}
        />
      )}
      {renderElement === radio && (
        <RadioList
          error={elementConfig.error}
          label={elementConfig.label}
          language={language}
          name={elementConfig.name}
          optionMeta={elementConfig.options}
          sets={elementConfig.sets}
          {...elementConfig}
        />
      )}
      {
        renderElement === autocomplete && (
          <AutoComplete
            options={elementConfig.options}
            language={language}
            name={elementConfig.name}
            label={elementConfig.label}
            {...elementConfig}
          />
        )
      }
    </>
  );
};

export default OptionList;
