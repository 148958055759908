import { useContext, useEffect, useState } from "react";
import { WizardStateContext } from "../";
import {
  useSetHint,
  useRemoveHint,
  useConditionals,
  isRequiredField,
} from "../../../utils/helpers";
import BaseDropdown from "./BaseDropdown";

type Props = {
  name: string;
  label: any;
  sets: any;
  options: Array<object>;
  defaultValue?: string;
  onChange?: any;
  language: string;
  validations?: any;
  style?: object;
  optionStyle?: object;
  error?: any | null;
  hint?: any;
  conditionals?: any;
  helpInfo?: any;
};

const DropDownList = ({
  name,
  label,
  options,
  defaultValue,
  validations,
  language,
  hint,
  error,
  sets,
  conditionals,
  helpInfo,
}: Props) => {
  const [isRequired, setIsRequired] = useState({ value: false, message: null });
  const [errorMessage, setErrorMessage] = useState(error);
  const [display, setDisplay] = useState(false);
  const wizardState = useContext(WizardStateContext.Context);
  const setWizardValue =
    wizardState.WizardActions &&
    wizardState.WizardActions.get("setWizardStateValue");
  const formData: any = wizardState.WizardActions.get("FormData") || [];
  const formValues: any = {};
  for (const pair of formData.entries()) {
    formValues[pair[0]] = pair[1];
  }
  const elementValue = formValues[name];
  const getValue = () => elementValue && elementValue !== "undefined" ? elementValue : defaultValue;
  const [value, setValue] = useState(getValue() || localStorage.getItem(sets.name) || "");
  const addHint = useSetHint();
  const removeHint = useRemoveHint();
  const handleConditionals = useConditionals(conditionals);

  useEffect(() => {
    if (value && !handleConditionals.render) {
      setValue("");
      setWizardValue && setWizardValue(sets.name, value);
    }
  }, [handleConditionals, setWizardValue, sets.name, value]);

  useEffect(() => {
    setErrorMessage(error && value === "" ? error : null);
  }, [error, value]);

  useEffect(() => {
    if (options && options.length > 1) {
      setDisplay(true);
    } else {
      setDisplay(false);
      removeHint(name);
    }
  }, [name, options, removeHint]);

  useEffect(() => {
    if (hint && display) {
      hint["hintFor"] = name;
      addHint(hint);
    }
  }, [hint, addHint, display, name]);

  useEffect(() => {
    setIsRequired(isRequiredField(validations));
  }, [validations]);

  const clearPageError = () => {
    let pageError = wizardState.WizardState.get("PageLevelError");
    if (pageError) {
      setWizardValue("PageLevelError", null);
    }
  };

  const changeFunction = (e: any) => {
    clearPageError();
    setValue(e.target.value);
    setWizardValue && setWizardValue(sets.name, e.target.value);
  };
  return (
    <>
      {display && handleConditionals.render && (
        <BaseDropdown
          name={name}
          label={label}
          language={language}
          options={options}
          value={value}
          errorMessage={errorMessage}
          handleOnChange={changeFunction}
          isRequired={isRequired.value}
          helpInfo={helpInfo}
        />
      )}
    </>
  );
};

export default DropDownList;
