import { useState, useEffect, createContext } from "react";
import { api } from "../../utils/helpers";
import crypto from "crypto";
// import mixpanel from "mixpanel-browser";

const generateId = () => {
    let id = crypto.randomBytes(10).toString('hex');
    const initDate = new Date().toISOString();
    return initDate + id;
};

const ContextEngine = createContext<any>(new Map());

const ContextStateProvider = ({ state, children }: any) => {
    const [ContextState, setContextState] = useState<any>(new Map());
    const [updatedAt, setUpdatedAt] = useState<any>(new Date());
    const [analyticsId, setAnalyticsId] = useState<any>(null);
    const [loading, setLoading] = useState(false);
    const [configuration, setconfiguration] = useState();
    const [solutionId, setSolutionId] = useState();

    useEffect(() => {
        if (analyticsId === null) {
            const id = generateId();
            setAnalyticsId(id);
            //mixpanel.identify(id);
        }
        sessionStorage.setItem("analyticsId", analyticsId);
    }, [analyticsId]);

    useEffect(() => {
        let storedId = sessionStorage.getItem("analyticsId");
        if (!Boolean(storedId)) {
            sessionStorage.setItem("analyticsId", analyticsId);
        }
    });

    useEffect(() => {
        state && state !== ContextState && setContextState(state);
    }, [state, ContextState]);

    useEffect(() => {
        //This will try to call from the ContextEngine before the Router is initialized, so can't get the solution from the URL.
        //This prevents that from performing a double network call.
        if (solutionId === undefined) {
            return;
        }
        const fetchSolutionConfig = async () => {
            const url = `api/solutions/${solutionId}/forms`;
            try {
                const [configuration] = await api.get({ url });
                setconfiguration(configuration);
            } catch (error) {
                setLoading(false);
            }
        };
        fetchSolutionConfig();
    }, [solutionId]);

    const setContextStateValue = (key: string, value: any) => {
        let newState = ContextState;
        newState.set(key, value);
        setUpdatedAt(new Date());
        return newState;
    };

    const goBack = () => {
        window.history.back();
    };

    const clearAnalyticsId = () => {
        setAnalyticsId(null);
        // mixpanel.reset();
    };

    const setLoadingState = (state: boolean) => {
        setLoading(state);
    };

    const ContextActions = new Map();
    ContextActions.set("GoBack", goBack);
    ContextActions.set("setContextStateValue", setContextStateValue);
    ContextActions.set("ClearId", clearAnalyticsId);
    ContextActions.set("SetLoadingState", setLoadingState);
    ContextActions.set("SetSolutionID", setSolutionId)

    const value = {
        ContextState: ContextState,
        ContextActions: ContextActions,
        loading: loading,
        updatedAt: updatedAt,
        Configuration: configuration
    };


    return (
        <ContextEngine.Provider value={value}>{children}</ContextEngine.Provider>
    );
};

const GlobalContext = {
    Provider: ContextStateProvider,
    Consumer: ContextEngine.Consumer,
    Context: ContextEngine,
}

export default GlobalContext;
