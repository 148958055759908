import classes from "./CheckList.module.scss";
import { useEffect, useState } from "react";
import { handleText, staticContent } from "../../../utils/helpers";
import { WizardLabel, FieldMessage, Help, Icon } from "../";

type Props = {
    autoFocus?: boolean;
    name: string;
    label?: any;
    optionMeta: any;
    language: string;
    error?: any | null | undefined;
    hint?: any;
    isRequired?: any;
    onChange?: any;
    passedValue?: any;
    helpInfo?: any;
};

const fieldLabelStyle = {
    marginBottom: "15px"
};

const lastOption = {
    marginBottom: "25px"
};

const singleOption = {
    marginBottom: "17px"
};

const checkStyle = {
    color: "var(--control-color)"
}

const getPassedValues = (passedValue: string) => {
    let passedValueList = passedValue.split(',');
    passedValueList = passedValueList.filter((c: string, index) => {
        return passedValueList.indexOf(c) === index;
    });
    return passedValueList;
}

const BaseCheck = ({
    label,
    name,
    language,
    error,
    optionMeta,
    isRequired,
    onChange,
    passedValue,
    helpInfo,
}: Props) => {

    const [errorMessage, setErrorMessage] = useState(error);
    const [value, setValue] = useState<any>(passedValue || []);
    const [options, setOptions] = useState(optionMeta || []);
    const [display, setDisplay] = useState(false);
    const [checkDisplayStyle, setCheckDisplayStyle] = useState("");

    useEffect(() => {
        setValue(passedValue);
    }, [passedValue])

    useEffect(() => {
        setErrorMessage(value?.length === 0 ? error : null);
    }, [error, value]);

    useEffect(() => {
        setOptions(optionMeta);
        if (options && options.length > 0) {
            setDisplay(true)
        } else {
            setDisplay(false);
        }
        setCheckDisplayStyle(
            options && (options.length === 2 || options.length > 4) ? "column" : ""
        );
    }, [name, optionMeta, options]);

    const handleChange = (e: any) => {
        let tempValue = typeof (value) === 'string' ? getPassedValues(value) : value;
        if (tempValue.includes(e.target.value)) {
            tempValue = tempValue.filter((v: any) => v !== e.target.value);
        } else {
            tempValue.push(e.target.value);
        }
        setValue(tempValue);
        onChange(tempValue);
    };

    const setOptionValue = (optionId: string) => {
        const selectedOption: any = document.getElementById(optionId);
        selectedOption.focus();
        let tempValue = typeof (value) === 'string' ? getPassedValues(value) : value;
        if (tempValue.includes(selectedOption.value)) {
            tempValue = tempValue.filter((v: any) => v !== selectedOption.value);
        } else {
            tempValue.push(selectedOption.value);
        }
        setValue(tempValue);
        onChange(tempValue);
    };

    return (
        <>
            <div className={'checkField'} id={name}>
                {display &&
                    <div className={[classes.CheckList].join(' ')} role="group">
                        <div className={classes.CheckListLabelWrap}>
                            <WizardLabel
                                name={name}
                                required={isRequired.value}
                                requiredLabel={handleText(staticContent.required, language)}
                                label={label}
                                language={language}
                                passedStyle={fieldLabelStyle}
                            />
                            {helpInfo && <Help helpInfo={helpInfo} language={language} id={name} />}
                        </div>
                        {options && options.map((option: any, index: number) => {
                            const optionId = name + "_option" + index;
                            return (
                                <div
                                    className={
                                        (classes.Check + " "
                                            + (checkDisplayStyle !== "" ? classes[checkDisplayStyle + (index % 2)] + ` ${checkDisplayStyle} ` : "")
                                        )
                                    }
                                    key={name + index}>
                                    <span id={optionId + "_icon"}
                                        className={classes.optionUnSelected}
                                        onClick={() => { setOptionValue(optionId); }}>
                                        {value.includes(option.value) && <Icon name='check' size='lg' type="regular" style={checkStyle} />}
                                    </span>
                                    <input type="checkbox"
                                        onChange={handleChange}
                                        id={optionId}
                                        name={name}
                                        value={option.value}
                                        title={handleText(option.label, language)}
                                        checked={value.includes(option.value)} />
                                    <WizardLabel
                                        name={optionId}
                                        label={option.label}
                                        language={language}
                                        passedStyle={
                                            (index === options.length - 1 ? (!errorMessage ? (options.length > 1 ? lastOption : singleOption) : fieldLabelStyle) : {})
                                        } />
                                </div>
                            )
                        })}
                        {errorMessage ? (
                            <FieldMessage
                                messageType="error"
                                messageText={errorMessage}
                                language={language}
                            />
                        ) : null}
                    </div>
                }
            </div>
        </>
    );
};

export default BaseCheck;