import { useEffect, useState, useContext } from "react";
import { useSetHint, useRemoveHint, useConditionals, isRequiredField } from "../../../utils/helpers";
import { WizardStateContext } from "../";
import BaseCheck from './BaseCheck';

type Props = {
    autoFocus?: boolean;
    sets: any;
    name: string;
    label?: any;
    optionMeta: any;
    validations?: any;
    conditionals?: any;
    language: string;
    error?: any | null | undefined;
    hint?: any;
};

const CheckList = ({
    label,
    name,
    sets,
    language,
    validations,
    hint,
    error,
    conditionals,
    optionMeta,
    ...props
}: Props) => {


    const [isRequired, setIsRequired] = useState({ value: false, message: null });
    const wizardState = useContext(WizardStateContext.Context);
    const [value, setValue] = useState(wizardState.WizardState.get(sets.name) || localStorage.getItem(sets.name) || []);
    const setWizardValue = wizardState.WizardActions && wizardState.WizardActions.get("setWizardStateValue");
    const [options, setOptions] = useState(optionMeta || []);
    const [display, setDisplay] = useState(false);
    const addHint = useSetHint();
    const removeHint = useRemoveHint();
    const handleConditionals = useConditionals(conditionals);

    useEffect(() => {
        if (value && !handleConditionals.render) {
            setValue(null);
            setWizardValue && setWizardValue(sets.name, null);
        }
    }, [handleConditionals, setWizardValue, sets.name, value]);


    useEffect(() => {
        setOptions(optionMeta);
        if (options && options.length > 0) {
            setDisplay(true)
        } else {
            setDisplay(false);
            removeHint(name);
        }
    }, [name, optionMeta, options, removeHint])

    useEffect(() => {
        if (hint && display) {
            hint['hintFor'] = name;
            addHint(hint);
        }
    }, [hint, addHint, display, name])

    useEffect(() => {
        setIsRequired(isRequiredField(validations));
    }, [validations]);

    const clearPageError = () => {
        let pageError = wizardState.WizardState.get("PageLevelError");
        if (pageError) {
            setWizardValue("PageLevelError", null);
        }
    };

    const handleChange = (passedValue: any) => {
        clearPageError();
        setValue(passedValue);
        setWizardValue && setWizardValue(sets.name, passedValue);
    };

    return (
        <>
            {handleConditionals.render &&
                <BaseCheck
                    language={language}
                    label={label}
                    passedValue={value}
                    isRequired={isRequired}
                    error={error}
                    optionMeta={optionMeta}
                    onChange={handleChange}
                    name={name}
                    {...props}
                />
            }
        </>
    );
};

export default CheckList;
