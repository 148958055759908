import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { MenuItem, MenuTrigger, PullDownMenu, Icon } from "../../Library";
import classes from "./LanguageSelector.module.scss";

type Props = {
  language: string;
  languageSettings: any;
  onChange: (language: string) => void;
};

const triggerStyle = {
  verticalAlign: "top",
};

const langIconStyle = {
  color: "var(--primary-color)",
  fontSize: "22px",
  marginRight: "30px",
  marginLeft: "20px",
};

const closeIconStyle = {
  ...langIconStyle,
  color: "var(--text-color)",
  marginRight: "0px",
  marginLeft: "0px",
};

const titleStyle = {
  color: "var(--text-color)",
  fontSize: "16px",
  fontFamily: "var(--font-family-bold)",
};

const menuTitleStyle = {
  height: "65px",
  width: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
};

const globalMenuOptions = {
  fontFamily: "var(--font-family-semibold)"
}

const rightBarLangStyle = {
  ...triggerStyle,
  width: "144px",
  float: "right",
  marginRight: "0",
  cursor: "pointer",
};

const menuWithLinesStyle = {
  borderBottom: "1px solid var(--shadow-color)"
};

const nameMenuStyle = {
  width: "220px",
};

const triggerChildVisibleStyle = {
  width: "100%",
  float: "right",
};

const triggerChildHiddenStyle = {
  ...titleStyle,
  width: "100%"
};

const languageSelectorStyle = {
  height: "65px",
  width: "160px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
};

const LanguageSelector = ({ language, languageSettings, onChange }: Props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [languages, setLanguages] = useState(languageSettings.languages);

  const styles = {
    menuStyle: rightBarLangStyle,
    triggerChildVisibleStyle: triggerChildVisibleStyle,
  };

  const getLangIcon = (text: string, isOpen: boolean) => {
    return (
      <div style={menuTitleStyle}>
        <div style={titleStyle}
          className={classes.titleStyle + " titleStyle " + (isOpen ? classes.titleStyleOpen : {})}>
          <span className={classes.displayTitle + " selectedLanguage"}>
            {!isOpen && languages.find((option: any) => option.value === text) &&
              languages.find((option: any) => option.value === text).label}
          </span>
          <span className={(isOpen ? classes.closeIcon : '') + " languageIcon"}>
            <Icon
              name={isOpen ? "times" : "globe"}
              style={isOpen ? closeIconStyle : langIconStyle}
              type="regular"
            />
          </span>
        </div>
      </div>
    );
  };

  useEffect(() => {
    setLanguages(languageSettings.languages);
  }, [languageSettings]);

  return (
    <React.Fragment>
      {
        languageSettings.languages.length > 0 &&
        <div style={nameMenuStyle} id="languageSelector">
          {languageSettings.languages.length > 1 ?
            <PullDownMenu style={languageSelectorStyle}>
              <MenuTrigger
                name={"menuTrigger"}
                text={getLangIcon(language, isOpen)}
                isOpen={isOpen}
                openMenu={() => setIsOpen(true)}
                closeMenu={() => setIsOpen(false)}
                styles={styles}
              >
                {languages.map((option: any, index: any) => (
                  <span key={index}>
                    <MenuItem
                      onSelect={() => onChange(option.value)}
                      style={menuWithLinesStyle}
                    >
                      <span style={globalMenuOptions} className={"globalMenuOptions"}>{option.label}</span>
                    </MenuItem>
                  </span>
                ))}
              </MenuTrigger>
            </PullDownMenu>
            :
            <h4 style={triggerChildHiddenStyle} id="languageLabel">{languageSettings.languages[0].label}</h4>}
        </div>
      }
    </React.Fragment>
  );
};

LanguageSelector.propTypes = {
  language: PropTypes.string.isRequired,
  languageSettings: PropTypes.shape({
    default: PropTypes.string,
    languages: PropTypes.arrayOf(PropTypes.object),
  }),
  onChange: PropTypes.any,
};

export default LanguageSelector;
