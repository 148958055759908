import classes from "./RadioList.module.scss";
import { useEffect, useState } from "react";
import { handleText, staticContent } from "../../../utils/helpers";
import { WizardLabel, FieldMessage, Help } from "../";
import ReactMarkdown from "react-markdown";
import Icon from "../Icon/Icon";
import { FormattedText } from "../Text";
import Line from "../Line/Line";

type Props = {
    autoFocus?: boolean;
    name: string;
    label?: any;
    optionMeta: any;
    language: string;
    error?: any | null | undefined;
    hint?: any;
    typeSpecialCase?: string;
    isRequired?: any;
    onChange?: any;
    passedValue?: any;
    helpInfo?: any;
};

const tagIcon: any = {
    Warning: 'exclamation-circle',
    Error: 'times-circle',
    Information: 'check-circle'
};

const warningIcon = {
    color: "var(--accent-color1)"
};

const informationIcon = {
    color: "var(--accent-color2)"
};

const errorIcon = {
    color: "var(--alert-color)"
};

const lineStyles = {
    lineContainer: {
        maxWidth: "955px"
    },
    line: {
        marginBottom: "10px",
        backgroundColor: "var(--line-color)",
        marginTop: "0px"
    }
};

const lastTag = {
    marginBottom: "15px"
};

const fieldLabelStyle = {
    marginBottom: "0px"
};

const firstOption = {
    marginTop: "0px"
};

const lastOption = {
    marginBottom: "25px"
};

const singleLineOption = {
    marginTop: "0px",
    marginBottom: "25px"
};

const singleLineOptionWithError = {
    marginTop: "0px",
    marginBottom: "0px"
}

const typeSpecialCases = ["YesNo", "OptionDescription"];

const BaseRadio = ({
    label,
    name,
    language,
    error,
    optionMeta,
    typeSpecialCase,
    isRequired,
    onChange,
    passedValue,
    helpInfo
}: Props) => {

    const [errorMessage, setErrorMessage] = useState(error);
    const [value, setValue] = useState(passedValue || "");
    const [options, setOptions] = useState(optionMeta || []);
    const [display, setDisplay] = useState(false);
    const [radioDisplayStyle, setRadioDisplayStyle] = useState("");
    useEffect(() => {
        setValue(passedValue);
    }, [passedValue]);

    useEffect(() => {
        setErrorMessage(value === "" ? error : null);
    }, [error, value]);

    useEffect(() => {
        setOptions(optionMeta);
        if (options && options.length > 0) {
            setDisplay(true)
        } else {
            setDisplay(false);
        }
        setRadioDisplayStyle(
            options && (options.length === 2 || options.length > 4) ? "column" : ""
        );
    }, [name, optionMeta, options])

    const handleChange = (e: any) => {
        setValue(e.target.value);
        onChange(e.target.value);
    };

    const setOptionValue = (optionId: string) => {
        const selectedOption: any = document.getElementById(optionId);
        selectedOption.focus();
        setValue(selectedOption.value);
        onChange(selectedOption.value);
    };

    return (
        <>
            <div className={"radioField"} id={name}>
                {display &&
                    <div className={[classes.RadioList].join(' ')} role="group">
                        <div className={classes.RadioListLabelWrap}>
                            {label.type !== 'Empty' &&
                                <WizardLabel
                                    name={name}
                                    required={isRequired.value}
                                    requiredLabel={handleText(staticContent.required, language)}
                                    label={label}
                                    language={language}
                                    passedStyle={fieldLabelStyle}
                                />
                            }
                            {helpInfo && <Help helpInfo={helpInfo} language={language} id={name} />}
                        </div>
                        {options && options.map((option: any, index: number) => {
                            const optionId = name + "_option" + index;
                            return (
                                <div
                                    id={optionId + '_InfoInd'}
                                    className={
                                        (typeSpecialCase && typeSpecialCases.includes(typeSpecialCase) ? classes[typeSpecialCase] + ` ${typeSpecialCase} ` : classes.Radio + " "
                                            + (radioDisplayStyle !== "" ? classes[radioDisplayStyle + (index % 2)] + ` ${radioDisplayStyle} ` : "")
                                        )
                                    }
                                    key={name + index}>
                                    {option.optionInfo && option.optionInfo.text ?
                                        <div id={optionId + '_Info'}>{<FormattedText text={option.optionInfo.text} language={language}></FormattedText>}</div> : ''
                                    }
                                    {option.optionDescription ?
                                        <ReactMarkdown>{option.optionDescription}</ReactMarkdown> : ''
                                    }
                                    {option.warnings ?
                                        option.warnings.map((e: any, index: number) => {
                                            return (
                                                <div id={optionId + '_warn'} className={classes.tag} style={index === option.warnings.length - 1 ? lastTag : {}}>
                                                    {<Icon name={tagIcon[e.severity]} type="solid" style={e.severity === 'Warning' ? warningIcon : (e.severity === 'Error' ? errorIcon : informationIcon)} />}  {e.textCodeMessage}
                                                </div>
                                            )
                                        })
                                        : ''
                                    }
                                    <span id={optionId + "_icon"}
                                        className={classes.optionUnSelected + " " + (index === 0 || (typeSpecialCase && typeSpecialCases.includes(typeSpecialCase)) ? classes.optionUnSelectedFirstChild : "")}
                                        onClick={() => { setOptionValue(optionId); }}>
                                        <span className={classes.optionSelected + (option.value === value ? " optionSelected " + classes.optionLabelSelected : " optionUnSelected " + classes.optionLabelUnSelected)}></span>
                                    </span>
                                    <input type="radio"
                                        onChange={handleChange}
                                        id={optionId}
                                        name={name}
                                        value={option.value}
                                        title={handleText(option.label, language)}
                                        checked={option.value === value} />
                                    <WizardLabel
                                        name={optionId}
                                        label={option.label}
                                        language={language}
                                        passedStyle={
                                            typeSpecialCase && typeSpecialCases.includes(typeSpecialCase) ? (!errorMessage ? singleLineOption : singleLineOptionWithError) :
                                                index === 0 ? firstOption : (index === options.length - 1 ? (!errorMessage ? lastOption : fieldLabelStyle) : {})
                                        } />
                                    {option.optionLine ?
                                        <Line lineStyles={lineStyles} /> : ''
                                    }
                                </div>
                            )
                        })}
                        {errorMessage ? (
                            <FieldMessage
                                messageType="error"
                                messageText={errorMessage}
                                language={language}
                            />
                        ) : null}
                    </div>
                }
            </div>
        </>
    );
};

export default BaseRadio;
