import PropTypes from "prop-types";
import classes from "./FieldLabel.module.scss";

type Props = {
  name?: string;
  required?: boolean;
  requiredLabel?: string;
  label: string;
  style?: object
};

const FieldLabel = ({ name, required, requiredLabel, label, style }: Props) => {
  return (
    <label htmlFor={name} id={name + "-label"} style={style} className={classes.fieldLabel + " fieldLabel"}>
      {label}
      {required === true ? (
        <span className={classes.requiredTag}>{requiredLabel}</span>
      ) : null}
    </label>
  );
};

FieldLabel.propTypes = {
  name: PropTypes.string,
  required: PropTypes.bool,
  requiredLabel: PropTypes.string,
  label: PropTypes.string.isRequired,
};

export default FieldLabel;
