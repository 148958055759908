import classes from "./Hints.module.scss";
import { handleText, staticContent } from "../../../utils/helpers";

type Props = {
  name: string;
  language: string;
  items: any;
};

const Hints = ({ name, language, items }: Props) => {
  return (
    <>
      {items.length > 0 && (
        <div id={name} className={classes.HintSection}>
          <h2 id={`${name}Heading`} className={classes.hintHeading}>
            {handleText(staticContent.hints, language)}:
          </h2>
          <ul id={`${name}List`} className={classes.HintSectionList}>
            {items.map((hinttext: any, index: number) => {
              return (
                <li id={`${index}-LabelHint`} key={index}>
                  {handleText(hinttext, language)}
                </li>
              );
            })}
          </ul>
        </div>
      )}
    </>
  );
};

export default Hints;
