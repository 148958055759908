import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const getIcon = (name: string, type: string) => {
    const iconType =
        type === 'light'
            ? 'fas'
            : type === 'brand'
                ? 'fab'
                // : type === 'regular'
                //     ? 'far'
                : type === 'solid'
                    ? 'fas'
                    : 'fas';
    return iconType ? [iconType, name] : name;
};

const Icon = (props: any) => {
    const { name, size, type, style, transform, title, ...rest } = props;

    const icon = getIcon(name, type.toLowerCase());

    return (
        <FontAwesomeIcon
            icon={icon}
            size={size ? size : 'lg'}
            fontSize={size}
            aria-label={name}
            transform={transform}
            style={style}
            className={name === 'spinner' ? 'fa-spin' : ''}
            {...rest}
        />
    );
};

Icon.propTypes = {
    /** Icon name */
    name: PropTypes.string.isRequired,
    /** Icon size: "xs" | "lg" | "sm" | "1x" | "2x" | "3x" | "4x" | "5x" | "6x" | "7x" | "8x" | "9x" | "10x" */
    size: PropTypes.string,
    /** Icon type: 'regular', 'solid, 'brand, 'light */
    type: PropTypes.string,
    /** Icon inline style */
    style: PropTypes.object,
    /** Icon  title */
    title: PropTypes.string,
    /** Icon transform */
    transform: PropTypes.func,
    /** Click callback */
    onClick: PropTypes.func,
};

export default Icon;
