import { useContext, useEffect, useState } from "react";
import { WizardStateContext } from "..";
import {
    useSetHint,
    useRemoveHint,
    useConditionals,
    handleText
} from "../../../utils/helpers";
import BaseBox from "./BaseBox";

const baseStyle = {
    maxWidth: '540px'
};

type Props = {
    name: string;
    label: any;
    sets: any;
    options: Array<object>;
    defaultValue?: string;
    onChange?: any;
    language: string;
    validations?: any;
    style?: object;
    optionStyle?: object;
    error?: any | null;
    hint?: any;
    conditionals?: any;
    helpInfo?: any;
};

const parseOption = (option: any, language: string) => {
    if (Boolean(option.value)) {
        let parsedOption = { value: option.value, label: handleText(option.label, language) };
        return parsedOption;
    } else {
        let groupedOption = {
            label: handleText(option.label, language),
            options: option.options?.map((o: any) => ({ value: o.value, label: handleText(o.label, language) }))
        }
        return groupedOption;
    }
}

const ComboBox = ({
    name,
    label,
    options,
    validations,
    language,
    hint,
    error,
    sets,
    conditionals,
    helpInfo
}: Props) => {
    const [isRequired, setIsRequired] = useState({ value: false, message: null });
    const [errorMessage, setErrorMessage] = useState(error);
    const [display, setDisplay] = useState(false);
    const [parsedOptions, setParsedOptions] = useState<any>(options.map((o: any) => parseOption(o, language)));
    const wizardState = useContext(WizardStateContext.Context);
    const setWizardValue =
        wizardState.WizardActions &&
        wizardState.WizardActions.get("setWizardStateValue");
    const formData: any = wizardState.WizardActions.get("FormData") || [];
    const formValues: any = {};
    for (const pair of formData.entries()) {
        formValues[pair[0]] = pair[1];
    }
    const elementValue = formValues[name];
    const getValue = () => elementValue && elementValue !== "undefined" ? (parsedOptions.filter((v: any) => elementValue.includes(v.value))) : [];
    const [value, setValue] = useState(getValue() || []);
    const addHint = useSetHint();
    const removeHint = useRemoveHint();
    const handleConditionals = useConditionals(conditionals);
    const [noneValue, setNoneValue] = useState<any>(null);

    const selectOption = (newValue: any) => {
        let tempValue = newValue;
        let tempNoneValue = newValue.find((v: any) => v.value === 'none');
        if (Boolean(noneValue) && newValue.length > 0) {
            tempValue = tempValue.filter((v: any) => v.value !== 'none');
            setNoneValue(null);
        }
        if (!Boolean(noneValue) && Boolean(tempNoneValue)) {
            tempValue = [tempNoneValue];
            setNoneValue(tempNoneValue);
        }
        let tempAllValue = newValue.find((v: any) => v.value === 'all');
        if (Boolean(tempAllValue) && !Boolean(tempNoneValue)) {
            let tempAllValues = parsedOptions.filter((o: any) => o.value !== 'none');
            tempValue = tempAllValues;
        }
        let tempValues = [];
        for (let item of tempValue) {
            tempValues.push(item.value);
        }
        setValue(tempValue);
        setWizardValue && setWizardValue(sets.name, tempValues);
    }

    useEffect(() => {
        if (value?.length === 0 && error) {
            setErrorMessage(error);
        } else {
            setErrorMessage(null);
        }
    }, [error, value]);

    useEffect(() => {
        if (options && options.length > 1) {
            setDisplay(true);
        } else {
            setDisplay(false);
            removeHint(name);
        }
    }, [name, options, removeHint]);

    useEffect(() => {
        if (hint && display) {
            hint["hintFor"] = name;
            addHint(hint);
        }
    }, [hint, addHint, display, name]);

    useEffect(() => {
        const setValidations = (validations: any) => {
            for (let validation of validations) {
                switch (validation.type) {
                    case "Required":
                        setIsRequired({
                            value: Boolean(validation.value),
                            message: validation.onError,
                        });
                        break;
                }
            }
        };
        setValidations(validations);
    }, [validations]);

    useEffect(() => {
        setParsedOptions(options.map((o: any) => parseOption(o, language)))
    }, [options, language])

    const clearPageError = () => {
        let pageError = wizardState.WizardState.get("PageLevelError");
        if (pageError) {
            setWizardValue("PageLevelError", null);
        }
    };

    const changeFunction = (e: any) => {
        clearPageError();
        selectOption(e);
    };

    return (
        <>
            {display && handleConditionals.render && (
                <BaseBox
                    name={name}
                    label={label}
                    language={language}
                    options={parsedOptions}
                    value={value}
                    errorMessage={errorMessage}
                    handleOnChange={changeFunction}
                    isRequired={isRequired.value}
                    isMultiple={true}
                    style={baseStyle}
                    helpInfo={helpInfo}
                />
            )}
        </>
    );
};

export default ComboBox;
