import React from 'react';
import classes from "./Line.module.scss";

type Props = {
    name?: string,
    lineStyles?: undefined
    | {
        lineContainer: undefined | object,
        line: undefined | object
    }
};

const Line: React.FC<Props> = ({ name, lineStyles }) => {
    return (
        <div style={lineStyles ? lineStyles.lineContainer : {}} className={classes.cardTitleLine + " cardTitleLine"}>
            <hr id={name} style={lineStyles ? lineStyles.line : {}} className={classes.line + " line"} />
        </div>
    )
}

export default Line;
