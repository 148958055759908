import { WizardLabel, FieldMessage, Help } from "..";
import { handleText, staticContent } from "../../../utils/helpers";
import Select, { components } from 'react-select';
import classes from './ComboBox.module.css';

type Props = {
    name: string;
    label: any;
    options: Array<object>;
    handleOnChange?: any;
    language: string;
    style?: object;
    optionStyle?: object;
    isRequired?: boolean;
    errorMessage?: any;
    value: any;
    isMultiple?: any;
    helpInfo?: any;
};

const questionStyle = {
    marginBottom: '30px'
};

const getBackgroundColor = (state: any) => {
    if (state.isFocused || state.isSelected) return 'var(--background-color2)';
    if (state.isSelected) return 'var(--background-color2)';

    return 'var(--background-color1)';
};

const getColor = (state: any) => {
    if (state.isFocused) return 'var(--text-color)';
    if (state.isSelected) return 'var(--shadow-color2)';

    return 'var(--shadow-color2)';
};

const getBorder = (state: any, error: any) => {
    if (error) return '3px solid var(--alert-color)';
    if (state.isFocused) return '1px solid #37A1B9';

    return '1px solid var(--shadow-color2)';
};

const getStyles = (error: any) => ({
    option: (base: any, state: any) => ({
        ...base,
        backgroundColor: getBackgroundColor(state),
        color: getColor(state),
        width: '100%',
        maxWidth: '380px',
        cursor: 'pointer'
    }),
    indicatorSeparator: () => ({
    }),
    control: (base: any, state: any) => ({
        ...base,
        boxShadow: 'none',
        borderRadius: 'none!important',
        outline: '0',
        width: '100%',
        maxWidth: '380px',
        fontFamily: 'var(--semibold-font)',
        border: getBorder(state, error),
        backgroundColor: "var(--background-color1)",
        minHeight: '45px',
        '&:hover': {
            border: getBorder(state, error)
        }
    }),
    singleValue: (base: any) => ({
        ...base,
        fontFamily: 'var(--semibold-font)',
        fontSize: '19px',
        fontWeight: '600',
        backgroundColor: "var(--background-color1)"
    }),
    indicator: (base: any) => ({
        ...base,
        color: 'var(--background-color1)',
        cursor: 'pointer'

    }),
    dropdownIndicator: (base: any) => ({
        ...base,
        color: 'var(--control-color)',
        cursor: 'pointer'
    }),
    clearIndicator: (base: any) => ({
        ...base,
        cursor: 'pointer'
    }),
    multiValueLabel: (styles: any) => ({
        ...styles,
        borderLeft: '2px solid var(--primary-color)',
        borderTop: '2px solid var(--primary-color)',
        borderBottom: '2px solid var(--primary-color)',
        borderRadius: '0',
        color: 'var(--primary-color)',
        backgroundColor: 'var(--background-color1)'
    }),
    multiValueRemove: (styles: any) => ({
        ...styles,
        ':hover': {
            cursor: 'pointer',
            backgroundColor: 'var(--background-color1)'
        },
        backgroundColor: 'var(--background-color1)',
        borderRight: '2px solid var(--primary-color)',
        borderTop: '2px solid var(--primary-color)',
        borderBottom: '2px solid var(--primary-color)',
        borderRadius: '0',
        color: 'var(--primary-color)',
    }),
    menuPortal: (base: any) => ({
        ...base,
        zIndex: '3!important'
    }),
    menuList: (base: any) => ({
        ...base,
        width: '100%',
        maxWidth: '380px',
        fontSize: '19px'
    }),
    menu: (base: any) => ({
        ...base,
        width: '100%',
        maxWidth: '380px'
    }),
    placeholder: (base: any) => ({
        ...base,
        color: 'var(--line-color)',
        fontSize: '19px'
    })
});;

const ClearIndicator = (props: any) => {
    return (
        <span id={'multiSelectClearIndicator_' + props.selectProps.name}>
            <components.ClearIndicator {...props} />
        </span>
    );
};

const DropdownIndicator = (props: any) => {
    return (
        <span id={'multiSelectDropdownIndicator_' + props.selectProps.name}>
            <components.DropdownIndicator {...props} />
        </span>
    );
};

const Option = (props: any) => {
    const innerProps = { ...props.innerProps, role: 'option' };
    const val_nospaces = props.data.value.replace(/ /g, '');
    return (
        <span id={'multiSelectOption_' + val_nospaces}>
            <components.Option {...props} innerProps={innerProps} />
        </span>
    );
};

const MultiValueLabel = (props: any) => {
    const val_nospaces = props.data.value.replace(/ /g, '');
    return (
        <span id={'multiSelectPill_' + val_nospaces} style={{ display: 'contents' }}>
            <components.MultiValueLabel {...props} />
        </span>
    );
};

const MultiValueRemove = (props: any) => {
    const val_nospaces = props.data.value.replace(/ /g, '');
    return (
        <span id={'multiSelectPillRemove_' + val_nospaces} style={{ display: 'flex' }}>
            <components.MultiValueRemove {...props} />
        </span>
    );
};

const IndicatorsContainer = (props: any) => {
    return (
        <span id={'multiSelectIndicators_' + props.selectProps.name}>
            <components.IndicatorsContainer {...props} />
        </span>
    );
};

const MenuList = (props: any) => {
    return (
        <div id={'multiSelectMenu_' + props.selectProps.name} >
            <components.MenuList {...props} />
        </div>
    );
};

const Input = (props: any) => {
    return (
        <components.Input {...props} id={'select_' + props.selectProps.name} role={'combobox'} aria-label={props["aria-label"]} />
    );
};

const BaseBox = ({
    name,
    label,
    options,
    language,
    //style,
    isRequired = false,
    errorMessage,
    value,
    handleOnChange,
    isMultiple,
    helpInfo,
}: Props) => {
    return (
        <div style={questionStyle} className={classes.ComboBox} id={"combobox-" + name}>
            <WizardLabel
                name={"select_" + name}
                required={isRequired}
                requiredLabel={handleText(staticContent.required, language)}
                label={label}
                language={language}
            />
            <Select
                aria-label={handleText(label, language)}
                name={name}
                id={name}
                value={value}
                onChange={handleOnChange}
                //style={style}
                styles={getStyles(errorMessage)}
                options={options}
                isMulti={isMultiple}
                closeMenuOnSelect={false}
                placeholder={handleText(staticContent.selectoneormore, language)}
                className="multiselectwrapper"
                components={{ MultiValueLabel, MultiValueRemove, IndicatorsContainer, MenuList, Option, DropdownIndicator, ClearIndicator, Input }}
            />
            {helpInfo && <Help helpInfo={helpInfo} language={language} id={name} />}
            {errorMessage && (
                <FieldMessage
                    messageType="error"
                    messageText={errorMessage}
                    language={language}
                />
            )}
        </div>
    );
};

export default BaseBox;