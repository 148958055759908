import React, { useEffect, useState } from 'react';
import ItemList from './ItemList';
import { useConditionals } from "../../../utils/helpers";

type Props = {
    name: string;
    columnCount?: number;
    style?: object;
    language: string;
    items: any[];
    conditionals: any;
};

const WizardItemList = ({ name, items, columnCount, style, language, conditionals }: Props) => {
    const handleConditionals = useConditionals(conditionals, items);
    const [newPayload, setNewPayload] = useState<any>(null);

    useEffect(() => {
        setNewPayload(handleConditionals.newPayload)
    }, [handleConditionals.newPayload])
    return (
        <React.Fragment>
            {handleConditionals.render &&
                <ItemList
                    name={name}
                    items={newPayload ? newPayload : items}
                    columnCount={columnCount}
                    style={style}
                    language={language}
                />
            }
        </React.Fragment>
    )
};

export default WizardItemList;