import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { handleText } from '../../../utils/helpers';
import classes from "./Logo.module.scss";
import { Link } from "react-router-dom";
import { useParams } from "react-router";

type Props = {
    logo: any,
    language: string
};

type linkType = {
    type: string,
    url: string,
    openTo: string
}

let solution: string = "";

/**
 * Add link to the logo element (text | image)
 * @param link object to set url and target for the logo link
 * @param element for which link will be created
 * @param logoText for tooltip
 */
const linkElement = (link: linkType, element: any, logoText: string = "") => {
    element = <>{element}</>;
    const linkId = "sitelogoLink";
    if (!(link && link.type)) {
        return element;
    }
    switch (link.type.toLowerCase()) {
        case "absolute":
            return (
                <a id={linkId} className={classes.logoLink} href={link.url} target={link.openTo || "_blank"} rel="noreferrer" title={logoText}>
                    {element}
                </a>
            );

        case "relative":
            return (
                <Link
                    id={linkId}
                    to={"/" + solution + "/" + link.url}
                    className={classes.logoLink}
                    title={logoText}
                    target={link.openTo || "_blank"}
                >
                    {element}
                </Link>
            );

        default:
            return element;
    }
};

const Logo = ({ logo, language }: Props) => {
    const params: any = useParams();
    solution = params.solution;
    const [useLogo, setUseLogo] = useState(<></>);
    const [styles] = useState([{ /** Logo div styles */
        height: '65px',
        width: '395px',
        fontFamily: 'var(--font-family-bold)',
        display: 'flex',
        alignItems: 'center',
        marginLeft: '20px',
        color: logo.color ? 'var(' + logo.color + ')' : 'var(--text-color)'
    }, { /** Logo font styles */
        fontSize: logo.fontSize ? logo.fontSize + logo.fontSizeUnit : '12pt',
        fontWeight: logo.fontWeight ? logo.fontWeight : 'bold',
        color: logo.color ? 'var(' + logo.color + ')' : 'var(--text-color)'
    }]);

    useEffect(() => {
        if (logo && Boolean(language)) {
            let setLogo: any = null;
            const logoText = logo.text ? handleText(logo.text, language) : "";
            if (logo.image) {
                let srcUrl = 'url(' + logo.image + ')';
                setLogo = (
                    <img src={srcUrl} alt={logoText} />
                );
            } else if (logo.text) {
                setLogo = (
                    <p style={styles[1]}>{logoText}</p>
                );
            }
            if (Boolean(setLogo)) {
                let fullLogo = (
                    <div id="sitelogo" style={styles[0]}>
                        {linkElement(logo.link, setLogo, logoText)}
                    </div>
                );
                setUseLogo(fullLogo);
            }
        }
    }, [logo, language, styles]);

    return (
        <React.Fragment>
            {useLogo}
        </React.Fragment>
    );
};

Logo.propTypes = {
    logo: PropTypes.object,
    language: PropTypes.string.isRequired
};

export default Logo;
