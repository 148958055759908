import classes from "./Title.module.scss";
import { handleText } from "../../../utils/helpers";

type Props = {
    title: CardTitleInterface,
    name: string,
    language: string
};

interface CardTitleInterface {
    type: string,
    content: [{
        language: string,
        text: string
    }]
};

const Title = ({ title, name, language }: Props) => {
    return (
        <h1 id={name} className={classes.cardTitle}>{handleText(title, language)}</h1>
    )
}

export default Title;
