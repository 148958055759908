import { Icon } from "..";
import { handleText } from "../../../utils/helpers";

type Props = {
  messageText: any;
  messageType: "info" | "error";
  language: string;
};

const errorElementStyle = {
  marginTop: "10px",
  marginBottom: "25px",
  maxWidth: "350px",
  textIndent: "-28px",
  paddingLeft: "28px",
};

const iconStyle = {
  fontSize: "12pt",
  marginRight: "7px",
};

const errorIconStyle = {
  ...iconStyle,
  color: "var(--alert-color)",
};

const infoIconStyle = {
  ...iconStyle,
  color: "var(--control-color)",
};

const fieldMessageStyle = {
  fontSize: "12pt",
  fontColor: "var(--text-color)",
  lineHeight: "20px",
  fontFamily: "var(--font-family-regular)",
};

const FieldMessage = ({ messageText, messageType, language }: Props) => {
  let iconName = "";
  let iconStyles = iconStyle;
  switch (messageType) {
    case "info":
      iconName = "exclamation-circle";
      iconStyles = infoIconStyle;
      break;

    case "error":
      iconName = "exclamation-triangle";
      iconStyles = errorIconStyle;
      break;
  }
  return (
    <p style={errorElementStyle}>
      <span>
        <Icon name={iconName} type={"solid"} style={iconStyles} />
      </span>
      <span style={fieldMessageStyle}>{handleText(messageText, language)}</span>
    </p>
  );
};

export default FieldMessage;
