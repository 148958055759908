import React, { useState, useContext, useEffect } from "react";
import { handleText, staticContent, isRequiredField, handleAnalytics, api } from "../../../utils/helpers";
import GlobalContext from "../../../utils/wrappers/ContextEngine";
import RenderElement from "../RenderElement/RenderElement";
import WizardStateContext from "./WizardStateProvider";
import { useStepConfigUpdater, useStepConfigState, Card, Navigation, Loader } from "../";
import { Helmet } from 'react-helmet';

type Props = {
  wizardStepConfig: any | undefined;
  language: string;
  totalWizardSteps: number;
  stepNumber: number;
  wizardTitle: string;
};

const WizardStep = ({
  wizardStepConfig,
  language,
  totalWizardSteps,
  stepNumber,
  wizardTitle,
}: Props) => {
  const containers = wizardStepConfig.containers;
  const wizardState = useContext(WizardStateContext.Context);
  const [wizardActions] = useState<any>(wizardState.WizardActions);
  const setWizardValue =
    wizardActions && wizardActions.get("setWizardStateValue");
  const setStepConfig = useStepConfigUpdater();
  const stepConfig = useStepConfigState();
  const analytics = handleAnalytics;
  const globalContextState = useContext(GlobalContext.Context);
  const loading = globalContextState.loading;
  const [processedApis, setProcessedApis] = useState<any>([]);
  const [apiOutput, setApiOutput] = useState<any>(null);
  const analyticsSecret = globalContextState?.Configuration?.analyticsTools?.find((at: any) => at.name === "Mixpanel")?.secret;

  useEffect(() => {
    if (wizardStepConfig.apis) {
      let apis = wizardStepConfig.apis;
      let tempApis = [];
      for (let tempApi of apis) {
        let tempProcessedApi = {
          url: tempApi.url,
          base: {
            headers: () => ({
              "Content-Type": "application/json",
              "Ocp-Apim-Subscription-Key": tempApi.key,
            })
          },
          method: tempApi.method
        };
        tempApis.push(tempProcessedApi);
      }
      setProcessedApis(tempApis);
    }
  }, [wizardStepConfig]);

  useEffect(() => {
    const handleApis = async () => {
      for (let processedApi of processedApis) {
        let request = { url: processedApi.url, base: processedApi.base };
        let getApi = await api.get(request);
        let output = [];
        output.push(getApi);
        setApiOutput(output);
      }
    }
    if (!apiOutput) {
      handleApis();
    } else {
      for (let output of apiOutput) {
        for (let [key, value] of Object.entries(output)) {
          setWizardValue(key, value);
        }
      }
    }
  }, [processedApis, apiOutput, setWizardValue]);

  useEffect(() => {
    analytics(wizardStepConfig.analytics && wizardStepConfig.analytics, null, analyticsSecret);
  }, [wizardStepConfig, analytics, analyticsSecret]);

  useEffect(() => {
    setWizardValue && setWizardValue("currentStep", stepNumber);
  }, [setWizardValue, stepNumber]);

  useEffect(() => {
    const stepElements: any = {};

    const getStepElements = () => {
      containers.map((container: any) => {
        return container.containers.map((innerContainer: any) => {
          return innerContainer.elements.map((element: any) => {
            element.isRequiredOnSubmit = isRequiredField(element.validations || []);
            if (innerContainer.type === "Section") {
              let elementName = element.name;
              const HINT_ELEMENT = "Hint";
              if (element.type === HINT_ELEMENT) {
                elementName = HINT_ELEMENT;
              }
              return stepElements[elementName] = element
            } else {
              return null;
            }
          });
        });
      });
      return stepElements;
    };

    setStepConfig(stepNumber, getStepElements());
  }, [setStepConfig, stepNumber, containers]);

  const renderElements = (container: any) => {
    if (loading) return null;
    const allElements = [];
    for (const step in stepConfig) {
      if (+step === stepNumber) {
        for (const elementName in stepConfig[step]) {
          const element = stepConfig[step][elementName];
          allElements.push(
            <RenderElement
              key={element.name}
              element={element}
              language={language}
              container={container}
            />
          );
        }
      }
    }
    return allElements;
  };

  return containers.map((container: any) => {
    return container.containers.map((innerContainer: any) => {
      return (
        <React.Fragment key={innerContainer.name}>
          <Helmet>
            <title>{handleText(wizardStepConfig.metaTitle, language)}</title>
          </Helmet>
          {
            loading ? <Loader /> : (
              <>
                {innerContainer.type === "Section" ? (
                  <Card id={innerContainer.name}>
                    <div id="wizardSteps" style={{ textAlign: "left" }}>
                      {wizardTitle ? `${wizardTitle} | ` : ''}
                      {`${handleText(
                        staticContent.step,
                        language
                      )} ${stepNumber} ${handleText(
                        staticContent.of,
                        language
                      )} ${totalWizardSteps}`}
                    </div>
                    {renderElements(innerContainer)}
                  </Card>
                ) : null}
                {innerContainer.type === "NavSection" ? (
                  <Navigation
                    key={innerContainer.name}
                    elements={innerContainer.elements}
                    language={language}
                  ></Navigation>
                ) : null}
              </>
            )
          }
        </React.Fragment>
      );
    });
  });
};

export default WizardStep;
