const formatNumber = (number: string, integerLimit: number = 20) => {
  // format number 1234567 to 1,234,567
  return number
    .replace(/\D/g, "")
    .substring(0, integerLimit)
    .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

type Props = {
  inputElement: any;
  isBlur?: boolean;
  integerLimit?: number;
  currencySymbol?: string;
};

const formatCurrency = ({
  inputElement,
  isBlur = false,
  integerLimit = 20,
  currencySymbol = "$",
}: Props) => {
  // add currency symbol to input value,
  // validates decimal side
  // and set the cursor back in right position.

  // get input value
  var inputVal = inputElement.value;

  // don't validate empty input
  if (["", currencySymbol].includes(inputVal)) {
    return "";
  }

  // original length
  var originalLen = inputVal.length;

  // initial caret position
  var caretPos = inputElement.selectionStart;

  // check for decimal
  if (inputVal.indexOf(".") >= 0) {
    // get position of first decimal
    // this prevents multiple decimals from being entered
    var decimalPos = inputVal.indexOf(".");

    // split number by decimal point
    var leftSide = inputVal.substring(0, decimalPos);
    var rightSide = inputVal.substring(decimalPos);

    // add commas to left side of number
    leftSide = formatNumber(leftSide, integerLimit);

    // validate right side
    rightSide = formatNumber(rightSide, integerLimit);

    // On blur make sure 2 numbers after decimal
    if (isBlur) {
      rightSide += "00";
    }

    // Limit decimal to only 2 digits
    rightSide = rightSide.substring(0, 2);

    // join number
    inputVal = currencySymbol + leftSide + "." + rightSide;
  } else {
    // no decimal entered
    // add commas to number
    // remove all non-digits
    inputVal = formatNumber(inputVal, integerLimit);
    inputVal = currencySymbol + inputVal;

    // final formatting
    if (isBlur) {
      inputVal += ".00";
    }
  }

  // put caret back in the right position
  var updated_len = inputVal.length;
  caretPos = updated_len - originalLen + caretPos;
  // setting caret position within timeout of 0ms is required,
  // otherwise browser resets the caret position after we set it
  if (!isBlur) setTimeout(() => {
    inputElement.setSelectionRange(caretPos, caretPos);
  }, 0);

  return inputVal;
};

export default formatCurrency;
